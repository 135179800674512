import type { Ticket, MappedTicket, TicketInProgressStatus } from '@/types/ticketing'
import { inProgressStatuses } from '@/constants/ticketing'
import { formatIsoStringWithTimeZoneOffset } from '@/utils/formatDate'
import { usePermissions } from '@/composables/usePermissions'
import { onMounted } from 'vue'
const { fetchPermissions, permissableUsers } = usePermissions()

onMounted(() => {
	if (!permissableUsers.value?.length) {
		fetchPermissions('notes.create')
	}
})

const isTicketOverdue = (status: TicketInProgressStatus, dueDate: string | undefined) => {
	const rightNow = new Date()
	if (!inProgressStatuses.includes(status)) {
		return false
	}
	const due = dueDate ? new Date(dueDate) : null
	return !!due && due < rightNow
}

const mapTickets = (tickets: Ticket[]): MappedTicket[] => {
	const formatDueDate = (dueDate: string | undefined) => {
		// #TODO: this shouldn't be necessary once all tickets with old format are resolved
		if (!dueDate) { return null }
		return dueDate.includes('T') ? new Date(dueDate) : new Date(formatIsoStringWithTimeZoneOffset(dueDate))
	}

	return tickets?.map(({
		_id,
		status,
		priority,
		dueDate,
		accountId,
		accountName,
		assignee,
		assigneeId,
		category,
		content,
		title,
		tier,
		escalated,
		created,
		createdTime,
		updated,
		metaDataDates,
		author
	}) => {
		const permissableAssignee = permissableUsers.value.find(user => user.contact?.contactId?.toString() === (assignee?.id && assignee.id.toString()))

		return {
			id: _id?.$oid || '',
			status,
			priority,
			lastUpdated: updated || metaDataDates?.updated || null,
			created: createdTime || created || metaDataDates?.created || null,
			dueDate: formatDueDate(dueDate),
			accountId,
			accountName,
			assignee: permissableAssignee ? `${permissableAssignee.contact.first || ''} ${permissableAssignee.contact.last || ''}` : 'unassigned',
			assigneeId: (assigneeId || assignee?.id || '').toString(), // NOTE support both the old assignee object and just storing the id for now
			category,
			content,
			title,
			navigateTo: `/accounts/${accountId}/ticketing/${_id?.$oid}`,
			overdue: isTicketOverdue(status as TicketInProgressStatus, dueDate),
			tier,
			escalated: escalated || null,
			author: `${author.firstName} ${author.lastName}`
		}
	}) || []
}

export { isTicketOverdue, mapTickets }
