<template>
	<ClientOnly>
		<div class="mb-4 -mx-2 -mt-2">
			<button
				class="flex w-full p-2 text-sm font-semibold leading-6 rounded-md text-mx-gray-500 dark:text-mx-gray-400 hover:bg-mx-gray-100 dark:hover:bg-mx-green-600 dark:hover:text-mx-gray-300 group gap-x-3"
				aria-label="Theme"
				@click="toggleLocale"
			>
				<span class="flex-none w-6 h-6 rounded-full flex-nowrap focus:outline-none focus:ring-2 focus:ring-mx-orange">
					<LanguageIcon
						class="w-6 h-6 shrink-0"
						aria-hidden="true"
					/>
				</span>
				<span :class="['block transition-all', showText ? 'visible' : 'invisible']">
					{{ alternativeLanguage.name }}
				</span>
			</button>
		</div>
	</ClientOnly>
</template>

<script setup lang="ts">
import { LanguageIcon } from '@heroicons/vue/24/outline'
import { useI18n } from 'vue-i18n'
const { locale, setLocale } = useI18n()

defineProps({
	showText: {
		type: Boolean,
		default: true
	}
})

const alternativeLanguage = computed(() => {
	return locale.value === 'en'
		? {
			abbrev: 'es',
			name: 'Español'
		}
		: {
			abbrev: 'en',
			name: 'English'
		}
})

const toggleLocale = () => {
	setLocale(alternativeLanguage.value.abbrev)
}
</script>
