import { reactive } from 'vue'
import type { ToastParams, Toast } from '@/types/toast'

const toasts: Toast[] = reactive([])

let lastToastTime = 0
const debounceTime = 500 // 0.5 seconds

export function useToast () {
	const addToast = ({ notificationType, title, message, options, links }: ToastParams) => {
		// Debounce to ignore if too many toasts too fast (if not disabled)
		const now = Date.now()
		if (now - lastToastTime < debounceTime && options?.debounce !== false) {
			return
		}
		lastToastTime = now

		const id = Date.now()
		const toast: Toast = {
			notificationType: notificationType ?? 'error',
			id,
			title,
			message,
			options,
			links
		}
		toasts.push(toast)

		// Remove the toast after a duration (default 5 seconds)
		setTimeout(() => {
			removeToast(id)
		}, options?.duration || 5000)
	}

	const removeToast = (id: number) => {
		const index = toasts.findIndex(toast => toast.id === id)
		if (index !== -1) {
			toasts.splice(index, 1)
		}
	}

	return {
		toasts,
		addToast,
		removeToast
	}
}
