<template>
	<div :style="{ position: 'relative', height: `${componentHeight}px` }">
		<MapboxMap
			v-if="mapSources && mapLayers && legend && !loading"
			map-id="accounts-map"
			:sources="mapSources"
			:layers="mapLayers"
			:legend="legend"
			:default-center="center"
			:zoom="DEFAULT_ZOOM"
			:active-polygon-label="activeZone?.name || ''"
			:popup="popup"
			draw-control
			fullscreen-control
			navigation-control
			style-switcher-control
			layer-visibility-control
			active-polygon-control
			legend-control
			:layer-filters="layerFilters"
			:modified-text="`Mainline Updated: ${lastUpdated}`"
			@loaded="loaded = true"
			@click="handleFeatureClick($event)"
			@hover="handleFeatureHover($event)"
			@set-active-polygon-id="clearActiveZone"
			@draw-create="handleDrawCreate"
			@draw-update="handleDrawUpdate"
		/>
	</div>
</template>

<script setup lang="ts">
import { useAccountsMapbox } from '@/composables/useAccountsMapbox'
import { useAccount } from '@/composables/useAccount'
import type { MapboxSource, MapboxLayer, LayerFilter, Legend, Popup, QueryFeature } from '@/components/mapbox/types'
import type { LineString, GeoJsonProperties, FeatureCollection, Geometry } from 'geojson'
import type { GeoJSONSourceSpecification } from 'mapbox-gl'
import type { DrawCreateEvent, DrawDeleteEvent, DrawUpdateEvent } from '@mapbox/mapbox-gl-draw'
import type { AccountsMapMarker } from '@/composables/useAccountsMapbox'
import type { MappedZone } from '@/components/zones/types'
import { useMapEvents } from '@/composables/useMapEvents'
import type { TableFilter } from '@/components/table/types'
import * as turf from '@turf/turf'
import { useMacs } from '@/composables/useMacs'
import { useNotes } from '@/composables/useNotes'
import { useMainlineMap } from '@/composables/useMainlineMap'

import type { MappedMac } from '@/components/internal/account/equipment/types'

const { getMacs, macs } = useMacs()
const { getAccount, accountData, fullAddress } = useAccount()
const { stickyNote, getNotes } = useNotes()
const { getMainlines, mainlineGeoJson, loading, lastUpdated } = useMainlineMap()

const props = defineProps({
	markers: {
		type: Array as PropType<AccountsMapMarker[] | undefined>,
		required: true
	},
	polygons: {
		type: Array as PropType<MappedZone[]>,
		required: true
	},
	componentHeight: {
		type: Number,
		required: true
	},
	focusedZoneId: {
		type: String,
		default: ''
	},
	activeMarkerId: {
		type: String,
		default: ''
	},
	activeFilters: {
		type: Array as PropType<TableFilter[]>,
		default: () => []
	},
	tableMarkerIds: {
		type: Array as PropType<string[]>,
		default: () => []
	},
	showTableMarkers: {
		type: Boolean,
		default: false
	},
	ticketInbox: {
		type: Boolean,
		default: false
	}
})

const { markers, focusedZoneId, activeMarkerId, polygons, activeFilters, tableMarkerIds, showTableMarkers, ticketInbox } = toRefs(props)

watch(activeMarkerId, async () => {
	if (activeMarkerId.value) {
		const marker = markers.value?.find(marker => marker?.id === activeMarkerId.value)
		if (marker) {
			await getAccount(marker.id)
			if (marker?.type === 'Point') {
				await getNotes(marker.id)
				nextTick(() => {
					const coordinates = marker?.coordinates as [number, number]

					// Display base account popup
					setBaseAccountPopup(accountData.value, coordinates)

					// Fetch device data and update popup
					getMacs(marker.id?.toString())?.then(() => {
						if (macs.value?.length) {
							setExtendedAccountPopup(accountData.value, coordinates)
						}
					})
				})
			}
		}
	}
}, { immediate: true })

const emit = defineEmits([ 'set-focused-zone-id', 'set-active-marker-id' ])

const {
	ACCOUNT_STATUSES,
	MAINLINE_STATUSES,
	MAINLINE_STATUS_COLORS,
	COMPLETED,
	VERIFIED,
	PLANNED,
	ZONE_TYPES,
	DEFAULT_CENTER,
	DEFAULT_ZOOM,
	mapZonesToGeoJson,
	mapMarkersToGeoJson,
	getAccountStatusBgColor,
	getAccountStatusTextColor,
	getEquipmentStatusColor,
	getMainlineStatusColor,
	MARKERS_SOURCE_ID,
	ZONES_SOURCE_ID,
	ZONES_FILL_LAYER,
	ZONES_LINE_LAYER,
	MARKERS_LAYER,
	ZONES_LINE_LAYER_ID,
	ZONES_LAYER_ID,
	MARKERS_LAYER_ID,
	MAINLINE_MARKERS_LAYER,
	MAINLINE_MARKERS_LAYER_ID,
	MAINLINE_SOURCE_ID,
	MAINLINE_LINE_STRINGS_LAYER,
	MAINLINE_LINE_STRINGS_LAYER_ID,
	ACTIVE_ZONE_MARKER_FILTER_ID,
	ACTIVE_ZONE_FILL_LAYER_FILTER_ID,
	ACTIVE_ZONE_LINE_LAYER_FILTER_ID,
	TABLE_DATA_MARKER_FILTER_ID
} = useAccountsMapbox()

const mapSources = ref<MapboxSource[]>([])

type LayerVisibility = 'visible' | 'none' | mapboxgl.ExpressionSpecification

const VISIBLE_MAINLINE_LINE_STRINGS_LAYER = { ...MAINLINE_LINE_STRINGS_LAYER, layout: { ...MAINLINE_LINE_STRINGS_LAYER.layout, visibility: 'visible' as LayerVisibility } }

// @ts-ignore - TS thinks this is too complex of a type so it says instatiation is too deep and possibly infinite
const mapLayers = ref<MapboxLayer[]>([ // NOTE: ORDER MATTERS TO APPEAR ON THE MAP WITH THE CORRECT Z-INDEX
	{
		id: ZONES_LAYER_ID,
		layer: ZONES_FILL_LAYER,
		siblingLayerIds: [ ZONES_LINE_LAYER_ID ]
	},
	{
		id: ZONES_LINE_LAYER_ID,
		layer: ZONES_LINE_LAYER,
		hideInVisibilityControl: true
	},
	{
		id: MAINLINE_LINE_STRINGS_LAYER_ID,
		layer: ticketInbox.value ? VISIBLE_MAINLINE_LINE_STRINGS_LAYER : MAINLINE_LINE_STRINGS_LAYER
	},
	{
		id: MAINLINE_MARKERS_LAYER_ID,
		layer: MAINLINE_MARKERS_LAYER
	},
	{
		id: MARKERS_LAYER_ID,
		layer: MARKERS_LAYER
	}
])

const legend: Legend[] = [
	{
		title: 'Account Status',
		items: ACCOUNT_STATUSES.map(status => ({
			name: status,
			type: 'marker',
			color: getAccountStatusBgColor(status)
		}))
	},
	{
		title: 'Handhole Status',
		items: MAINLINE_STATUSES.map(status => ({
			name: status,
			type: 'marker',
			color: getMainlineStatusColor(status)
		}))
	},
	{
		title: 'Mainline Status',
		items: [
			{
				name: PLANNED,
				type: 'line',
				style: 'dashed',
				color: MAINLINE_STATUS_COLORS.PLANNED
			},
			{
				name: COMPLETED,
				type: 'line',
				style: 'solid',
				color: MAINLINE_STATUS_COLORS.COMPLETED
			},
			{
				name: VERIFIED,
				type: 'line',
				style: 'solid',
				color: MAINLINE_STATUS_COLORS.VERIFIED
			}
		]
	},
	{
		title: 'Zone Border',
		items: [
			{
				name: ZONE_TYPES.IN_SERVICE,
				type: 'line',
				style: 'solid',
				color: '#afafb0'
			},
			{
				name: ZONE_TYPES.EXTENDED,
				type: 'line',
				style: 'dashed',
				color: '#afafb0'
			}
		]
	}
]

const center = ref<[number, number]>(DEFAULT_CENTER)
const loaded = ref(false)
const activeZone = ref<any>(null)
const hoveredZone = ref<any>(null)
const markersInLassoPolygon = ref<any[]>([])
const activeVitruviMarker = ref<any>(null)
const popup = ref<Popup>()
const layerFilters = ref<LayerFilter[]>([])

const {
	isMapEvent,
	isMarkerEvent,
	isPolygonEvent,
	isLineEvent
} = useMapEvents()

const handleFeatureClick = async (features: QueryFeature[] | undefined) => {
	if (isMarkerEvent(features, mapLayers.value)) {
		const marker = features?.[0]?.properties
		if (marker) {
			activeVitruviMarker.value = marker.vitruvi_id ? marker : null
			if (activeVitruviMarker.value) {
				if (features?.[0]?.geometry?.type === 'Point') {
					popup.value = { coordinates: features?.[0]?.geometry?.coordinates as [number, number], html: setVitruviPopup(marker), offset: { left: [ 0, 0 ], right: [ -30, 0 ], top: [ 0, 0 ], bottom: [ 0, 0 ] } }
				}

				if (features?.[0]?.geometry.type === 'LineString') {
					const lineString = features?.[0]?.geometry as LineString
					const coordinates = turf?.centerOfMass(turf?.lineString(lineString?.coordinates))?.geometry?.coordinates as [number, number]
					popup.value = { coordinates, html: setVitruviPopup(marker), offset: { left: [ 0, 0 ], right: [ -30, 0 ], top: [ 0, 0 ], bottom: [ 0, 0 ] } }
				}
			} else {
				await getAccount(marker.id)
				await getNotes(marker.id)
				nextTick(() => {
					if (features?.[0]?.geometry?.type === 'Point') {
						const coordinates = features?.[0]?.geometry?.coordinates as [number, number]

						// Display base account popup
						setBaseAccountPopup(accountData.value, coordinates)

						// Fetch device data and update popup
						getMacs(marker.id.toString()).then(() => {
							setExtendedAccountPopup(accountData.value, coordinates)
						})
					}
				})
			}
			emit('set-active-marker-id', marker.id || marker.vitruvi_id.toString())
		}
	} else if (isPolygonEvent(features, mapLayers.value)) {
		const zoneId = features?.[0]?.properties?.id
		if (activeZone.value?.id === zoneId) {
			clearActiveZone()
		}

		// set active zone to the clicked zone if the zone-fill-layer or zone-line-layer is visible
		const zoneLayerClicked = features?.some(feature => feature?.layer?.id === ZONES_LAYER_ID || feature?.layer?.id === ZONES_LINE_LAYER_ID) && features?.some(feature => feature?.layer?.type === 'fill' && feature?.layer?.layout?.visibility === 'visible')
		if (zoneLayerClicked && activeZone.value?.id !== zoneId) {
			activeZone.value = polygons.value?.find(zone => zone.id === zoneId)
		}

		// emit event to set focused zone id which will pan and to the zone
		emit('set-focused-zone-id', zoneId)
	} else if (isLineEvent(features, mapLayers.value)) {
		if (features?.[0]?.properties?.vitruvi_id && features?.[0]?.geometry.type === 'LineString') {
			const lineString = features?.[0]?.geometry as LineString
			const coordinates = turf.centerOfMass(turf.lineString(lineString.coordinates)).geometry.coordinates as [number, number]
			popup.value = { coordinates, html: setVitruviPopup(features?.[0]?.properties), offset: { left: [ 0, 0 ], right: [ -30, 0 ], top: [ 0, 0 ], bottom: [ 0, 0 ] } }
		}
	} else if (isMapEvent(features, mapLayers.value)) {
		// TODO: Handle map click if needed
	}
}

const handleFeatureHover = (features: QueryFeature[] | undefined) => {
	if (isPolygonEvent(features, mapLayers.value)) {
		hoveredZone.value = features?.[0]?.properties
	} else {
		hoveredZone.value = null
	}
}

const handleDrawCreate = (_polygon: any, _event: DrawCreateEvent) => {
	// TODO: Handle Draw Create event if needed
}

const handleDrawUpdate = (polygon: any, _area: number, _features: QueryFeature[], _event: DrawDeleteEvent | DrawUpdateEvent) => {
	if (markers.value?.length) {
		markersInLassoPolygon.value = markers.value?.filter((marker: AccountsMapMarker) => {
			const point = turf?.point(marker?.coordinates)
			return turf?.booleanPointInPolygon(point, polygon)
		})
	}
}

const clearActiveZone = () => {
	emit('set-focused-zone-id', '')
}

const getAccountEquipmentHTML = (equipment: MappedMac) => {
	return `
		<div class="p-4 mx-2 mt-4 rounded-lg neumorphic-card">
			<div class="flex items-center justify-between gap-2 mb-2 -mt-1 text-sm font-semibold text-mx-gray-700 dark:text-mx-gray-300">
				<div class="">${equipment?.deviceName || 'Unnamed Device'}</div>
				<div class="">MAC ID: ${equipment?.macId || 'Unknown'}</div>
			</div>

			<div class="h-0.5 bg-gradient-to-r from-mx-gray-300 via-mx-gray-400/20 to-mx-gray-300 dark:from-mx-green-600 dark:via-mx-gray-300/20 dark:to-mx-green-600"></div>
			<div class="grid grid-cols-2 gap-2 pt-2 mt-2 text-sm text-left capitalize">
				<div class="text-mx-gray-700 dark:text-mx-gray-300">
					<span class="font-bold">MAC Address:</span> ${equipment?.macAddress || 'unknown'}
				</div>
				<div class="text-mx-gray-700 dark:text-mx-gray-300">
					<span class="font-bold">Status:</span> <span class="font-semibold" style="color: ${getEquipmentStatusColor(equipment?.status || '')}">${equipment?.status || 'unknown'}</span>
				</div>
				<div class="text-mx-gray-700 dark:text-mx-gray-300">
					<span class="font-bold">Uptime:</span> ${equipment?.uptime || 'unknown'}
				</div>
				<div class="text-mx-gray-700 dark:text-mx-gray-300">
					<span class="font-bold">Last Seen:</span> ${equipment?.lastSeen || 'unknown'}
				</div>
			</div>
		</div>
	`
}

const getStickyNoteHTML = (stickyNote: string) => {
	return stickyNote?.length
		? `
		<div class="h-0.5 mt-2.5 mx-2 bg-gradient-to-r to-mx-gray-300/80 via-mx-gray-400/20 from-mx-gray-300/80 dark:from-mx-green-600 dark:via-mx-gray-300/20 dark:to-mx-green-600"></div>
		<div class="flex flex-col gap-1 px-2 mt-0.5 text-xs text-left">
			<div class="pt-2 pb-0 mt-1 text-mx-gray-500 dark:text-mx-gray-400"><span class="text-sm font-bold text-black dark:text-mx-gray-300">Sticky Note:</span> ${stickyNote}</div>
		</div>
	`
		: ''
}

const getStickyNoteLastModifiedHTML = (lastModified: string) => {
	return lastModified
		? `
		<div class="mx-2 mt-1 text-sm font-bold dark:text-mx-gray-300">Last Modified: <span class="text-xs font-normal text-mx-gray-500 dark:text-mx-gray-400">${lastModified}</span></div>
	`
		: ''
}

const setBaseAccountPopup = (account: any, coordinates: [number, number]) => {
	const baseHTML = `
    <div class="flex flex-col gap-1 px-2 text-left">
      <div class="flex flex-wrap items-center">
        <a href="accounts/${account?.accountId}" target="_blank" class="pr-1 text-lg font-semibold link-text dark:text-white">
          ${account?.firstName} ${account?.lastName}
        </a>
        <p class="self-start pr-2 mt-1 text-xs whitespace-nowrap dark:text-mx-gray-200">
          (Account ID: ${account?.accountId})
        </p>
        <p class="flex items-center px-2 py-0 ml-auto text-sm font-semibold rounded-full dark:text-mx-gray-900 whitespace-nowrap" style="background-color: ${getAccountStatusBgColor(account?.accountStatus)};">
          <span class="m-auto" style="color: ${getAccountStatusTextColor(account?.accountStatus)};">${account?.accountStatus}</span>
        </p>
      </div>
      <div class="text-base dark:text-mx-gray-300">${formatPhone(account?.phone)}</div>
      <div class="text-base dark:text-mx-gray-300">${account?.email}</div>
      <div class="text-base dark:text-mx-gray-300">${fullAddress?.value}</div>
    </div>
  `

	const stickyNoteHTML = getStickyNoteHTML(stickyNote.value?.body || '')

	const stickyNoteLastModifiedHTML = getStickyNoteLastModifiedHTML(formatDate(stickyNote.value?.lastModified, MDY2))

	// Set the popup with initial account data
	popup.value = {
		coordinates,
		html: baseHTML + stickyNoteHTML + stickyNoteLastModifiedHTML
	}
}

const setExtendedAccountPopup = (account: any, coordinates: [number, number]) => {
	// if the macs array doesn't have an object with the accountId, return
	if (!macs.value?.length) {
		return
	}
	if (!macs.value?.find((mac: MappedMac) => mac?.accountId?.toString() === account?.accountId?.toString())) {
		return
	}

	const baseHTML = `
    <div class="flex flex-col gap-1 px-2 text-left">
      <div class="flex flex-wrap items-center">
        <a href="accounts/${account?.accountId}" target="_blank" class="pr-1 text-lg font-semibold link-text dark:text-white">
          ${account?.firstName} ${account?.lastName}
        </a>
        <p class="self-start pr-2 mt-1 text-xs whitespace-nowrap dark:text-mx-gray-200">
          (Account ID: ${account?.accountId})
        </p>
        <p class="flex items-center px-2 py-0 ml-auto text-sm font-semibold rounded-full dark:text-mx-gray-900 whitespace-nowrap" style="background-color: ${getAccountStatusBgColor(account?.accountStatus)};">
          <span class="m-auto" style="color: ${getAccountStatusTextColor(account?.accountStatus)};">${account?.accountStatus}</span>
        </p>
      </div>
      <div class="text-base dark:text-mx-gray-300">${formatPhone(account?.phone)}</div>
      <div class="text-base dark:text-mx-gray-300">${account?.email}</div>
      <div class="text-base dark:text-mx-gray-300">${fullAddress?.value}</div>
    </div>
  `

	const stickyNoteHTML = getStickyNoteHTML(stickyNote.value?.body || '')
	const stickyNoteLastModifiedHTML = getStickyNoteLastModifiedHTML(formatDate(stickyNote.value?.lastModified, MDY2))

	const equipmentHTML = (macs.value as MappedMac[])
		?.map((equipment: MappedMac) => getAccountEquipmentHTML(equipment))
		.join('')

	// Set the popup with extended account data
	popup.value = {
		coordinates,
		html: baseHTML + stickyNoteHTML + stickyNoteLastModifiedHTML + equipmentHTML
	}
}

const replaceUnderscoresWithSpaces = (str: string): string => {
	return str.replace(/_/g, ' ')
}

const replaceTrueFalseWithYesNo = (value: any): string => {
	if (value === true) { return 'Yes' }
	if (value === false) { return 'No' }
	return value.toString()
}

const setVitruviPopup = (properties: Record<string, any>): string => {
	let content = '<div class="dark:text-mx-gray-300">'
	for (const [ key, value ] of Object.entries(properties)) {
		content += `<div class=""><strong class="capitalize">${replaceUnderscoresWithSpaces(key)}:</strong> ${replaceTrueFalseWithYesNo(String(value))}</div>`
	}
	content += '</div>'
	return content
}

/* #TODO: Implement this if needed. I keep it here for reference.
const uniqueMainlineCategories = computed(() => {
	const categories = new Set<string>()
	const data = geojson.features
	data.forEach((feature: Feature<Geometry, GeoJsonProperties>) => {
		if (feature.properties?.category_name) {
			categories.add(feature.properties.category_name)
		}
	})
	return Array.from(categories)
})
*/

watch(polygons, () => {
	if (mapSources.value && polygons.value?.length) {
		const zonesSource = mapSources.value?.find(source => source.id === ZONES_SOURCE_ID)?.source as GeoJSONSourceSpecification
		if (zonesSource) {
			zonesSource.data = mapZonesToGeoJson(polygons.value)
		}
	}
}, { immediate: true, deep: true })

watch(markers, () => {
	if (mapSources.value && markers.value?.length) {
		const markersSource = mapSources.value?.find(source => source.id === MARKERS_SOURCE_ID)?.source as GeoJSONSourceSpecification
		if (markersSource) {
			markersSource.data = mapMarkersToGeoJson(markers.value)
		}
	}
}, { immediate: true, deep: true })

watch(focusedZoneId, () => { // this is set from table controls
	if (focusedZoneId.value) {
		activeZone.value = polygons.value?.find(zone => zone.id === focusedZoneId.value)
	} else {
		activeZone.value = null
	}
}, { immediate: true })

const removeFilter = (filterId: string) => {
	layerFilters.value = layerFilters.value?.filter(filter => filter.filterId !== filterId)
}

const activeZoneMarkerFilter = computed((): LayerFilter => {
	return {
		layerId: MARKERS_LAYER_ID,
		filterId: ACTIVE_ZONE_MARKER_FILTER_ID,
		expression: [ '==', [ 'get', 'zoneId' ], activeZone.value?.id ],
		visibleLayerIds: [ MARKERS_LAYER_ID, ZONES_LAYER_ID, ZONES_LINE_LAYER_ID ]
	}
})

const activeZoneLineFilter = computed(() => {
	return {
		layerId: ZONES_LINE_LAYER_ID,
		filterId: ACTIVE_ZONE_LINE_LAYER_FILTER_ID,
		expression: [ '==', [ 'get', 'id' ], activeZone.value?.id ],
		visibleLayerIds: [ ZONES_LAYER_ID, ZONES_LINE_LAYER_ID ]
	}
})

const activeZoneFillFilter = computed(() => {
	return {
		layerId: ZONES_LAYER_ID,
		filterId: ACTIVE_ZONE_FILL_LAYER_FILTER_ID,
		expression: [ '==', [ 'get', 'id' ], activeZone.value?.id ],
		visibleLayerIds: [ ZONES_LAYER_ID, ZONES_LINE_LAYER_ID ]
	}
})

const tableDataMarkerFilter = computed(() => {
	if (!tableMarkerIds.value) {
		return null
	}
	// when combining multiple filters, we need to use "literal" to pass an array of values
	// as a single argument to the expression
	return {
		layerId: MARKERS_LAYER_ID,
		filterId: TABLE_DATA_MARKER_FILTER_ID,
		expression: [ 'in', [ 'get', 'id' ], [ 'literal', tableMarkerIds.value ] ],
		visibleLayerIds: [ MARKERS_LAYER_ID ]
	}
})

watch(tableMarkerIds, () => {
	if (mapSources.value && tableMarkerIds.value && tableDataMarkerFilter.value !== null && showTableMarkers.value) {
		removeFilter(TABLE_DATA_MARKER_FILTER_ID)
		layerFilters.value = [ ...layerFilters.value, tableDataMarkerFilter.value ]
	} else {
		removeFilter(TABLE_DATA_MARKER_FILTER_ID)
	}
}, { immediate: true, deep: true })

const removeActiveZoneFilters = () => {
	removeFilter(ACTIVE_ZONE_MARKER_FILTER_ID)
	removeFilter(ACTIVE_ZONE_LINE_LAYER_FILTER_ID)
	removeFilter(ACTIVE_ZONE_FILL_LAYER_FILTER_ID)
}

const setActiveZoneMarkerFilter = () => {
	// first remove any duplicate filters by filterId
	removeActiveZoneFilters()

	// then add the new filters
	layerFilters.value = [
		...layerFilters.value,
		activeZoneMarkerFilter.value,
		activeZoneFillFilter.value,
		activeZoneLineFilter.value
	]
}

watch(activeZone, () => {
	if (mapSources.value) {
		if (!activeZone.value) {
			removeActiveZoneFilters()
		} else {
			setActiveZoneMarkerFilter()
		}
	}
}, { immediate: true, deep: true })

const markerFilters = computed((): LayerFilter[] => {
	return activeFilters.value?.map((filter) => {
		return {
			layerId: MARKERS_LAYER_ID,
			filterId: filter?.columnKey,
			expression: [ '==', [ 'get', filter?.columnKey ], filter?.matches ],
			visibleLayerIds: [ MARKERS_LAYER_ID ]
		}
	})
})

const setMarkerFilters = () => {
	// clear any existing marker filters
	markerFilters.value?.forEach((filter) => {
		removeFilter(filter?.filterId)
	})

	// add the new marker filters
	layerFilters.value = [ ...layerFilters.value, ...markerFilters.value ]
}

watch(activeFilters, (newVal, oldVal) => {
	if (mapSources.value) {
		oldVal?.forEach((filter) => {
			if (!newVal.includes(filter)) {
				removeFilter(filter?.columnKey)
			}
		})
		if (activeZone.value) {
			setActiveZoneMarkerFilter()
		}
		// filter markers based on active table filters
		setMarkerFilters()
	}
}, { immediate: true })

onMounted(async () => {
	await getMainlines()
	mapSources.value = [
		{
			id: MARKERS_SOURCE_ID,
			source: {
				type: 'geojson',
				data: mapMarkersToGeoJson(markers.value)
			}
		},
		{
			id: ZONES_SOURCE_ID,
			source: {
				type: 'geojson',
				data: mapZonesToGeoJson(polygons.value)
			}
		},
		{
			id: MAINLINE_SOURCE_ID,
			source: {
				type: 'geojson',
				data: {
					type: 'FeatureCollection',
					features: (mainlineGeoJson.value as FeatureCollection<Geometry, GeoJsonProperties>)?.features
				}
			}
		}
	]
})
</script>
