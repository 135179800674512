import { accountDirectory, portalDirectory } from '@/constants/portalNames.js'

// TODO ts up in dis

const determineRouteDirectory = (subdomain) => {
	if (subdomain.includes(accountDirectory)) {
		return accountDirectory
	}
	return portalDirectory
}

export {
	determineRouteDirectory
}
