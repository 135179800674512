<template>
	<svg width="160" height="128" viewBox="0 0 160 128" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path class="st0" fill-rule="evenodd" clip-rule="evenodd" d="M120.4 120.744L125.7 127.144C126.1 127.644 126.8 127.744 127.3 127.344C147.4 112.344 159.2 88.8439 159.2 63.7439C159.2 38.8439 147.6 15.5439 127.8 0.543873C126.9 -0.156127 126 0.043873 125.6 0.643873L120.4 6.84387C119.7 7.64387 120.1 8.64387 120.6 9.04387C137.9 21.9439 148.1 42.1439 148.1 63.8439C148.1 85.4439 137.9 105.644 120.6 118.544C119.8 119.044 119.8 120.044 120.4 120.744Z" />
		<path class="st1" fill-rule="evenodd" clip-rule="evenodd" d="M105.8 103.344L111.2 109.744C111.6 110.244 112.3 110.344 112.8 109.944C127.8 99.2439 136.7 82.1439 136.7 63.6439C136.7 45.3439 128 28.3439 113.2 17.6439C112.4 17.1439 111.6 17.2439 111.1 17.7439L105.9 23.9439C105.1 24.9439 105.7 25.9439 106.3 26.3439C118.4 34.9439 125.6 48.7439 125.6 63.6439C125.6 78.5439 118.4 92.4439 106.2 101.044C105.3 101.644 105.4 102.844 105.8 103.344Z" />
		<path class="st2" fill-rule="evenodd" clip-rule="evenodd" d="M77.5002 69.5439L62.7002 51.8439C61.9002 50.8439 60.6002 50.7439 59.9002 52.0439C57.8002 55.5439 56.8002 59.5439 56.8002 63.6439C56.8002 71.7439 61.1002 79.2439 68.0002 83.4439C69.0002 84.0439 69.0002 85.3439 68.4002 85.9439L63.1002 92.2439C62.7002 92.7439 62.1002 92.8439 61.6002 92.4439C51.7002 86.1439 45.7002 75.2439 45.7002 63.5439C45.7002 53.0439 50.5002 43.2439 58.6002 36.7439C60.9002 34.8439 63.3002 35.0439 64.7002 36.7439L79.4002 54.2439C79.5002 54.4439 79.7002 54.5439 79.9002 54.5439C80.1002 54.5439 80.3002 54.4439 80.4002 54.2439L95.1002 36.7439C96.5002 35.0439 98.8002 34.8439 101.2 36.7439C109.3 43.2439 114.1 53.0439 114.1 63.5439C114.1 75.2439 108.1 86.1439 98.2002 92.4439C97.7002 92.7439 97.1002 92.6439 96.7002 92.2439L91.3002 86.0439C90.7002 85.4439 90.7002 84.0439 91.7002 83.5439C98.6002 79.3439 102.9 71.8439 102.9 63.7439C102.9 59.6439 101.8 55.6439 99.8002 52.1439C99.0002 50.8439 97.7002 50.9439 97.0002 51.9439L82.2002 69.5439C81.6002 70.2439 80.7002 70.6439 79.9002 70.6439C79.0002 70.6439 78.1002 70.2439 77.5002 69.5439Z" />
		<path class="st0" fill-rule="evenodd" clip-rule="evenodd" d="M39.3 120.744L34 127.144C33.6 127.644 32.9 127.744 32.4 127.344C12.3 112.244 0.5 88.7439 0.5 63.6439C0.5 38.7439 12.1 15.5439 31.9 0.443897C32.8 -0.256103 33.7 -0.0561026 34.1 0.543897L39.3 6.7439C40 7.5439 39.6 8.5439 39.1 8.9439C21.8 21.8439 11.6 42.0439 11.6 63.6439C11.6 85.2439 21.8 105.444 39.1 118.344C39.9 119.044 39.9 120.044 39.3 120.744Z" />
		<path class="st1" fill-rule="evenodd" clip-rule="evenodd" d="M53.8 103.344L48.4 109.744C48 110.244 47.3 110.344 46.8 109.944C31.8 99.2439 23 82.1439 23 63.6439C23 45.3439 31.7 28.3439 46.5 17.6439C47.3 17.1439 48.1 17.2439 48.6 17.7439L53.8 23.9439C54.6 24.9439 54 25.9439 53.4 26.3439C41.3 34.9439 34.1 48.7439 34.1 63.6439C34.1 78.5439 41.3 92.4439 53.5 101.044C54.4 101.644 54.3 102.844 53.8 103.344Z" />
	</svg>
</template>
<style scoped type="text/css">
			.st0 {
			fill-rule: evenodd;
			clip-rule: evenodd;
			fill: #900C3F;
			}

			.st1 {
			fill-rule: evenodd;
			clip-rule: evenodd;
			fill: #C70039;
			}

			.st2 {
			fill-rule: evenodd;
			clip-rule: evenodd;
			fill: #FF5733;
			}

			.st3 {
			fill-rule: evenodd;
			clip-rule: evenodd;
			}

			.st4 {
			fill-rule: evenodd;
			clip-rule: evenodd;
			fill: #1A1A1A;
			}

			.dark .st0, .dark .st1, .dark .st2, .dark .st3, .dark .st4 {
			fill: #fff;
			}

		</style>
