import { BASE_URL, API_BASE } from '@/constants/index.js'
import { useErrorReporter } from '@/composables/useErrorReporter'

export function useLogout () {
	const { bugsnagReport } = useErrorReporter()
	const handleLogout = async () => {
		await $fetch(`${API_BASE}/auth/logout`, {
			method: 'POST',
			credentials: 'include',
			async onResponse ({ response }) {
				if (!response?._data?.success) {
					throw new Error(response._data?.error || response._data?.errors[0])
				}
				await navigateTo(`${BASE_URL}/${response?._data?.redirect}`, {
					external: true
				})
			}
		}).catch((e) => {
			bugsnagReport({
				error: e instanceof Error ? e : new Error('handleLogout - catch: ' + (e as Error).toString()),
				showToast: true,
				toast: {
					title: 'Error logging out',
					message: e?.message
				}
			})
		})
	}

	return {
		handleLogout
	}
}
