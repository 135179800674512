import { ref, computed } from 'vue'

// TODO: move to types file
interface Tab {
  id: string
  name: string
  href: string
  route?: string
	current?: boolean
  isDefault?: boolean
}

const activeTabId = ref('overview') // 'overview' | 'ticketing' | 'billing' | 'legacy'
const activeBillingTabId = ref('invoices') // 'invoices' | 'transactions' | 'services' | 'payment-methods'
const activeAccountId = ref('')
const activeTicketId = ref('')

export function useStaticAccountProfile () {
	const activeTicketUrl = computed(() => {
		return `/accounts/${activeAccountId.value}/ticketing/${activeTicketId.value}`
	})
	const activeAccountUrl = computed(() => {
		return `/accounts/${activeAccountId.value}`
	})
	const noActiveTicket = computed(() => {
		return !activeTicketId.value
	})

	const setActiveTab = (tab: Tab) => {
		activeTabId.value = tab.id
	}

	const setActiveBillingTab = (tab: Tab) => {
		activeBillingTabId.value = tab.id
	}

	const setActiveTabId = (id: string) => {
		activeTabId.value = id
	}

	const isTabActive = (tab: Tab | string) => {
		if (typeof tab === 'string') {
			return activeTabId.value === tab
		}
		return activeTabId.value === tab.id
	}

	const isBillingTabActive = (tab: Tab | string) => {
		if (typeof tab === 'string') {
			return activeBillingTabId.value === tab
		}
		return activeBillingTabId.value === tab.id
	}

	const setActiveAccountId = (id: string | number) => {
		if (typeof id === 'number') {
			id = id.toString()
		}
		activeAccountId.value = id
	}

	const clearActiveAccountId = () => {
		activeAccountId.value = ''
	}

	const setActiveTicketId = (id: string) => {
		activeTicketId.value = id
	}

	const clearActiveTicketId = () => {
		activeTicketId.value = ''
	}

	return {
		activeTabId,
		activeBillingTabId,
		activeTicketUrl,
		activeAccountUrl,
		activeAccountId,
		activeTicketId,
		noActiveTicket,

		setActiveTab,
		setActiveTabId,
		setActiveBillingTab,
		isTabActive,
		isBillingTabActive,
		setActiveAccountId,
		clearActiveAccountId,
		setActiveTicketId,
		clearActiveTicketId
	}
}
