<template>
	<div>
		<ClientOnly>
			<Disclosure
				v-slot="{ close }"
				as="nav"
				class="fixed top-0 z-30 w-full bg-white shadow lg:hidden dark:bg-mx-green-800"
			>
				<div class="px-4">
					<div class="flex justify-between h-[6vh]">
						<div class="flex items-center justify-center flex-1 sm:items-stretch">
							<NavMobileHamburgerMenu color="text-black dark:text-white" />
							<div class="flex items-center w-full px-6 py-4 md:mx-auto lg:mx-0 xl:px-0">
								<div class="w-full">
									<!-- TODO: search bar -->
									<!-- <label for="search" class="sr-only">Search</label>
              <div class="relative">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <MagnifyingGlassIcon class="w-5 h-5 text-mx-gray-400" aria-hidden="true" />
                </div>
                <input id="search" name="search" class="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-mx-green-800 focus:ring-1 focus:ring-inset focus:ring-mx-gray-300 placeholder:text-mx-gray-400" placeholder="Search" type="search" />
              </div> -->
								</div>
							</div>
						</div>
						<div class="absolute -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
							<BrandingLogo class="h-8" />

							<!--
					<Avatar v-if="showAvatar" />
					-->
						</div>
					</div>
				</div>
				<NavDrawer @nav-link-clicked="handleNavigationClick(close)" />
			</Disclosure>
		</ClientOnly>
	</div>
</template>

<script setup lang="ts">
import { Disclosure } from '@headlessui/vue'

// const showAvatar = !isAccountPortal.value

const handleNavigationClick = (close: any) => {
	close()
}

</script>
