<template>
	<div
		ref="mapWrapper"
		class="relative w-full h-full"
	>
		<div
			:id="mapId"
			:class="[
				'absolute top-0 bottom-0 w-full h-full',
				mapClasses
			]"
		/>
		<div class="absolute text-xs text-mx-orange bottom-8 left-2">
			{{ modifiedText }}
		</div>
	</div>
</template>

<script setup lang="ts">
import mapboxgl from 'mapbox-gl'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import type { DrawCreateEvent, DrawDeleteEvent, DrawUpdateEvent } from '@mapbox/mapbox-gl-draw'
import type { Feature, Position, Polygon, Point, Geometry, GeoJsonProperties } from 'geojson'
import type { LngLatLike, GeoJSONSourceSpecification, GeoJSONSource, FillLayerSpecification, LineLayerSpecification, CircleLayerSpecification, SymbolLayerSpecification, MapOptions } from 'mapbox-gl'
import { StyleSwitcherControl, LayerVisibilityControl, ActivePolygonControl, LegendControl, LassoActionControl } from '@/utils/classes/MapboxCustomControls'
import { useResizeObserver } from '@vueuse/core'
import { useColorMode } from '@vueuse/core'
import { useErrorReporter } from '@/composables/useErrorReporter'
import {
	MAPBOX_API_KEY,
	MAPBOX_STYLE_SATELLITE_URL,
	MAPBOX_STYLE_DARK_URL,
	MAPBOX_STYLE_LIGHT_URL
} from '@/constants/index.js'
import type { MapboxLayer } from '@/components/mapbox/types'
import { useMapbox } from '@/composables/useMapbox'
import type { MapboxMapProps } from '@/composables/useMapbox'
import * as turf from '@turf/turf'

const props = defineProps({
	mapId: {
		type: String as PropType<MapboxMapProps['mapId']>,
		required: true
	},
	mapClasses: {
		type: String as PropType<MapboxMapProps['mapClasses']>,
		default: ''
	},
	sources: {
		type: Array as PropType<MapboxMapProps['sources']>,
		required: true
	},
	layers: {
		type: Array as PropType<MapboxMapProps['layers']>,
		required: true
	},
	legend: {
		type: Array as PropType<MapboxMapProps['legend']>,
		default: () => []
	},
	icons: {
		type: Array as PropType<MapboxMapProps['icons']>,
		default: () => []
	},
	defaultCenter: {
		type: Array as unknown as PropType<MapboxMapProps['defaultCenter']>,
		default: () => [ 0, 0 ]
	},
	pitch: {
		type: Number,
		default: 0
	},
	clusterConfig: {
		type: Object as PropType<MapboxMapProps['clusterConfig']>,
		default: () => ({
			clusters: [
				{
					maxLeaves: 5,
					zoom: 18
				},
				{
					maxLeaves: 10,
					zoom: 17
				},
				{
					maxLeaves: 20,
					zoom: 16
				},
				{
					maxLeaves: 50,
					zoom: 15
				},
				{
					maxLeaves: 250,
					zoom: 14
				},
				{
					maxLeaves: 500,
					zoom: 13
				},
				{
					maxLeaves: null,
					zoom: 12
				}
			]
		})
	},
	drawControl: {
		type: Boolean,
		default: false
	},
	styleSwitcherControl: {
		type: Boolean,
		default: false
	},
	fullscreenControl: {
		type: Boolean,
		default: false
	},
	navigationControl: {
		type: Boolean,
		default: false
	},
	layerVisibilityControl: {
		type: Boolean,
		default: false
	},
	legendControl: {
		type: Boolean,
		default: false
	},
	activePolygonControl: {
		type: Boolean,
		default: false
	},
	activePolygonLabel: {
		type: String,
		default: ''
	},
	activePoint: {
		type: Object as PropType<Point>,
		default: () => null
	},
	defaultStyle: {
		type: String,
		default: '',
		validator: (value: string) => [ '', 'satellite', 'dark', 'light' ].includes(value)
	},
	zoom: {
		type: Number,
		default: 0
	},
	reload: {
		type: Boolean,
		default: false
	},
	popup: {
		type: Object as PropType<MapboxMapProps['popup']>,
		default: null
	},
	layerFilters: {
		type: Object as PropType<MapboxMapProps['layerFilters']>,
		default: () => ([] as MapboxMapProps['layerFilters'])
	},
	modifiedText: {
		type: String,
		default: ''
	}
})

const { mapId, mapClasses, sources, layers, layerFilters, popup, activePoint, legend, icons, defaultCenter, pitch, zoom, clusterConfig, defaultStyle, drawControl, fullscreenControl, navigationControl, styleSwitcherControl, layerVisibilityControl, activePolygonControl, legendControl, activePolygonLabel, reload } = toRefs(props)

const emit = defineEmits([ 'loaded', 'click', 'hover', 'set-active-polygon-id', 'draw-create', 'draw-update', 'draw-delete' ])

const mapRef = ref<mapboxgl.Map>()
const { updateLayerFilters, updateLayerVisibility } = useMapbox(layers.value, mapRef)

const styleLoaded = ref(false)
const drawRef = ref<MapboxDraw>()
const roundedArea = ref<number | null>(null)
const lassoPolygon = ref<Feature<Polygon> | null>(null)
const featuresInLassoPolygon = ref<Feature<Geometry, GeoJsonProperties>[]>([])
const lassoPolygonIds = computed(() => featuresInLassoPolygon.value.map(feature => feature.properties?.id).filter(id => id) as string[] || [])
const zoomLevel = ref(10)
const mapWrapper = ref<HTMLDivElement | null>(null)
const activeMarkerPopup = ref<any>(null)
const { bugsnagReport } = useErrorReporter()

const layerVisibility = ref<{ [key: string]: boolean }>({})
const layerDefinitions = computed(() => {
	return layers.value.map(layer => ({
		id: layer.id,
		name: layer.id,
		visibility: layerVisibility.value[layer.id] ?? false,
		hideInVisibilityControl: layer.hideInVisibilityControl ?? false,
		siblingLayerIds: layer.siblingLayerIds ?? []
	})) as any
})

watch(layerVisibility, (newVal) => {
	// NOTE: Layers need to have the layout.visibility property set to 'visible' to be visible on first load
	updateLayerVisibility(newVal)
}, { deep: true })

const clearActivePolygon = (): void => {
	emit('set-active-polygon-id', '')
}

const clearLassoPolygon = (): void => {
	lassoPolygon.value = null
	featuresInLassoPolygon.value = []
	roundedArea.value = null
}

watch(reload, () => {
	if (mapRef.value) {
		mapRef.value.remove()
		initializeMap()
	}
})

const loadIcons = () => {
	if (mapRef.value && styleLoaded.value && icons.value?.length) {
		icons.value.forEach(({ id, url, sdf }) => {
			if (mapRef.value && !mapRef.value?.hasImage(id)) {
				mapRef.value.loadImage(url, (error, image) => {
					if (error) {
						bugsnagReport({ error: error ?? new Error(`${id} Failed to Load`) })
					}
					if (mapRef.value && image) {
						mapRef.value.addImage(id, image, { sdf: !!sdf })
					}
				})
			}
		})
	}
}

// update the data for the markers, lines, polygons, etc.
const updateGeoJsonData = () => {
	if (mapRef.value && styleLoaded.value) {
		sources.value.forEach(({ id, source }) => {
			// Check if the source already exists, if not, add it
			if (!mapRef.value?.getSource(id)) {
				mapRef.value?.addSource(id, source)
			}

			// If the source exists, update the data
			const existingSource = mapRef.value?.getSource(id) as GeoJSONSource

			// Ensure the source is a valid GeoJSON source
			if (existingSource && existingSource.type === 'geojson') {
				const geoJsonSource = source as GeoJSONSourceSpecification

				// ensure the source data is an object (GeoJSON)
				if (geoJsonSource?.data && typeof geoJsonSource.data === 'object') {
					const sourceData = geoJsonSource.data
					// update the source data
					existingSource.setData(sourceData)
				}
			}
		})
		// add source for draw
		if (drawControl.value) {
			// check if the draw source already exists, if not, add it
			if (!mapRef.value?.getSource('draw-polygon')) {
				mapRef.value?.addSource('draw-polygon', {
					type: 'geojson',
					data: {
						type: 'FeatureCollection',
						features: []
					}
				})
			}
			// if the draw source exists, update the data
			const existingDrawSource = mapRef.value?.getSource('draw-polygon') as GeoJSONSource

			// ensure the source is a valid GeoJSON source
			if (existingDrawSource && existingDrawSource.type === 'geojson') {
			// update the source data
				existingDrawSource.setData({
					type: 'FeatureCollection',
					features: lassoPolygon.value ? [ lassoPolygon.value ] : []
				})
			}
		}
	}
}

// load layers
const addLayers = () => {
	if (mapRef.value && styleLoaded.value) {
		layers.value.forEach(({ id, layer }) => {
			if (mapRef.value && !mapRef.value?.getLayer(id)) {
				mapRef.value.addLayer(layer)
			}
			layerVisibility.value[id] = (layer as FillLayerSpecification | LineLayerSpecification | SymbolLayerSpecification | CircleLayerSpecification)?.layout?.visibility === 'visible'
		})
		// add layers for draw
		if (drawControl.value) {
			// if layer doesn't exist, add it
			if (!mapRef.value?.getLayer('draw-polygon-layer')) {
				mapRef.value?.addLayer({
					id: 'draw-polygon-layer',
					type: 'fill',
					source: 'draw-polygon',
					paint: {
						'fill-color': '#FF5733',
						'fill-opacity': 0.4
					}
				})
			}

			if (!mapRef.value?.getLayer('draw-polygon-outline')) {
				mapRef.value?.addLayer({
					id: 'draw-polygon-outline',
					type: 'line',
					source: 'draw-polygon',
					paint: {
						'line-color': '#FF5733',
						'line-width': 2
					}
				})
			}
		}
	}
}

const setViewport = () => {
	if (mapRef.value) {
		// update the map's center and zoom based on the new markers
		mapRef.value?.setPitch(pitch.value)
		const center = defaultCenter.value
		mapRef.value?.jumpTo({ center, zoom: zoom.value || zoomLevel.value })
	}
}

const updateMapFeatures = () => {
	if (mapRef.value) {
		loadIcons()
		updateGeoJsonData()
	}
}

const getInitialStyle = () => {
	if (defaultStyle.value === '') {
		return colorMode.value === 'dark' ? MAPBOX_STYLE_DARK_URL : MAPBOX_STYLE_LIGHT_URL
	} else {
		switch (defaultStyle.value) {
		case 'satellite':
			return MAPBOX_STYLE_SATELLITE_URL
		case 'dark':
			return MAPBOX_STYLE_DARK_URL
		case 'light':
			return MAPBOX_STYLE_LIGHT_URL
		}
	}
}

const setActiveMarkerPopup = (popup: { coordinates: [ number, number ], html: string, offset?: { left: [number, number], right: [number, number], top: [number, number], bottom: [number, number] } }) => {
	const offset = popup.offset ?? { left: [ 0, 0 ], right: [ 0, 0 ], top: [ 0, 0 ], bottom: [ 0, 0 ] }
	if (mapRef.value) {
		const { coordinates, html } = popup
		activeMarkerPopup.value = new mapboxgl.Popup({ offset, className: 'popup', closeButton: false })
			.setLngLat(coordinates)
			.setHTML(html)
			.addTo(mapRef.value)
	}
}

watch(activePoint, () => {
	// ease to the active point
	if (mapRef.value && activePoint.value) {
		const { coordinates } = activePoint.value
		mapRef.value.easeTo({ center: coordinates as LngLatLike, zoom: 17 })
	}
})

watch(() => popup.value, (newVal) => {
	if (activeMarkerPopup.value) {
		activeMarkerPopup.value.remove()
	}
	if (newVal) {
		setActiveMarkerPopup(newVal)
	}
}, { deep: true })

// watch changes to the sources and update the map
watch(sources, () => {
	addLayers()
	updateMapFeatures()
}, { deep: true })

// Update layer filters and visibility when layerFilters change
watch(layerFilters, (newVal, oldVal) => {
	updateLayerFilters(newVal, oldVal)
}, { deep: true, immediate: true })

const initializeMap = () => {
	mapboxgl.accessToken = MAPBOX_API_KEY

	mapRef.value = new mapboxgl.Map({
		container: mapId.value,
		style: getInitialStyle(),
		zoom: zoom.value,
		attributionControl: false,
		dragRotate: false, // Disable rotation with right click + drag
		touchZoomRotate: true, // Allow touch zoom but disable two-finger rotation
		touchPitch: false, // Disable pitch with two-finger drag
		pitchWithRotate: false, // Disable pitch with right click + drag
		keyboard: false, // Disable rotation with the keyboard,
		center: defaultCenter.value
	})

	const registerControlPosition = (map: mapboxgl.Map, positionName: string) => {
		if (map._controlPositions[positionName]) {
			return
		}
		const positionContainer = document.createElement('div')
		positionContainer.className = `mapboxgl-ctrl-${positionName}`
		map._controlContainer.appendChild(positionContainer)
		map._controlPositions[positionName] = positionContainer
	}

	registerControlPosition(mapRef.value, 'top-center')

	mapRef.value.on('load', () => {
		addLayers()
		updateMapFeatures()
		setViewport()
		mapRef.value?.touchZoomRotate.disableRotation() // Disable two-finger rotation
		emit('loaded')
	})

	// Add style controls to the map
	mapRef.value.addControl(new mapboxgl.AttributionControl({
		customAttribution: 'Maverix Broadband'
	}))
	if (styleSwitcherControl.value) {
		mapRef.value.addControl(styleSwitcherOptions, 'top-left')
	}
	if (fullscreenControl.value) {
		mapRef.value.addControl(new mapboxgl.FullscreenControl(), 'top-right')
	}
	if (navigationControl.value) {
		mapRef.value.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'top-right')
	}
	if (activePolygonControl.value) {
		mapRef.value.addControl(new ActivePolygonControl(activePolygonLabel.value, clearActivePolygon), 'top-center' as MapOptions['logoPosition'])
	}
	if (legendControl.value) {
		mapRef.value.addControl(new LegendControl(legend.value, mapRef.value), 'top-left')
	}

	const mapControls = ref<string[]>([])

	const mapLayerVisibilityControl = ref<LayerVisibilityControl | null>(null)
	const mapActivePolygonControl = ref<ActivePolygonControl | null>(null)

	const addControlIfNotExists = (
		map: mapboxgl.Map,
		control: mapboxgl.IControl,
		controlName: string,
		controlPosition: MapOptions['logoPosition']
	) => {
		if (!mapControls.value.includes(controlName)) {
			map.addControl(control, controlPosition)
			mapControls.value.push(controlName)
		}
	}

	// Ensure the mapLayerVisibilityControl is created and added to the map on component mount
	if (mapRef.value && layerVisibilityControl.value) {
		mapLayerVisibilityControl.value = new LayerVisibilityControl(mapRef.value, layerDefinitions.value)
		mapActivePolygonControl.value = new ActivePolygonControl(activePolygonLabel.value, clearActivePolygon)
		addControlIfNotExists(mapRef.value, mapLayerVisibilityControl.value, 'layer-visibility-control', 'top-right')
	}

	watch(layerVisibility, () => {
		// Ensure mapLayerVisibilityControl is initialized before trying to update it
		if (mapRef.value && layerVisibilityControl.value && mapLayerVisibilityControl.value) {
			mapLayerVisibilityControl.value.updateLayers(layerDefinitions.value)
		} else if (mapRef.value && layerVisibilityControl.value) {
		// If for some reason it's not initialized, create and add it now
			mapLayerVisibilityControl.value = new LayerVisibilityControl(mapRef.value, layerDefinitions.value)
			addControlIfNotExists(mapRef.value, mapLayerVisibilityControl.value, 'layer-visibility-control', 'top-right')
		}
	}, { deep: true })

	// Watch activePolygonLabel and clearActivePolygon to update or remove the activePolygonControl
	watch(activePolygonLabel, (newLabel, oldLabel) => {
		if (mapRef.value && mapActivePolygonControl.value) {
			if (newLabel === '') {
			// Remove the control if the label is empty
				mapRef.value.removeControl(mapActivePolygonControl.value)
				const index = mapControls.value.indexOf('active-polygon-control')
				if (index > -1) {
					mapControls.value.splice(index, 1)
				}
			} else if (!mapControls.value.includes('active-polygon-control')) {
				// Add the control if it wasn't already added
				mapActivePolygonControl.value = new ActivePolygonControl(newLabel, clearActivePolygon)
				addControlIfNotExists(mapRef.value, mapActivePolygonControl.value, 'active-polygon-control', 'top-center' as MapOptions['logoPosition'])
			} else if (newLabel !== oldLabel) {
				// Update the label if it has changed
				mapActivePolygonControl.value.updateLabel(newLabel)
			}
		}
	})

	if (drawControl.value) {
		drawRef.value = new MapboxDraw({
			displayControlsDefault: false,
			controls: {
				polygon: true,
				trash: true
			},
			defaultMode: 'simple_select' // Change this to 'draw_polygon' to enable polygon drawing mode by default
		})

		const mapLassoActionControl = ref<LassoActionControl | null>(null)

		mapRef.value.addControl(drawRef.value as unknown as mapboxgl.IControl, 'top-right')
		mapRef.value.addControl(new LassoActionControl(lassoPolygonIds.value, clearLassoPolygon), 'top-center' as MapOptions['logoPosition'])

		mapRef.value.on('draw.modechange', () => { // REVIEW: I'm not sure how to fix this type error
			const mode = drawRef.value?.getMode()
			if (mapRef.value) {
				if (mode === 'draw_polygon') {
					mapRef.value.getCanvas().style.cursor = 'crosshair'
				} else {
					mapRef.value.getCanvas().style.cursor = ''
				}
			}
		})

		// on trash click, clear the lasso polygon
		mapRef.value.on('draw.delete', () => { // REVIEW: I'm not sure how to fix this type error
			lassoPolygon.value = null
			roundedArea.value = null
		})

		const updateBounds = () => {
			// get all that are visible on the map
			const data = drawRef.value?.getAll()
			const newPolygon = data?.features?.[data.features.length - 1] as Feature<Polygon>

			lassoPolygon.value = newPolygon
		}

		const handleDrawCreate = (e: DrawCreateEvent) => {
			updateBounds()
			updateArea()
			drawRef.value?.deleteAll()
			drawRef.value?.add(lassoPolygon.value as Feature<Polygon>)
			emit('draw-create', lassoPolygon.value, e)
		}

		const updateArea = (e?: DrawUpdateEvent) => {
			if (mapRef.value && drawRef.value && lassoPolygon.value) {
				// Calculate area of the polygon
				const area = turf.area(lassoPolygon.value)
				roundedArea.value = Math.round(area * 100) / 100

				// Update the polygon source
				const source = mapRef.value?.getSource('polygon') as mapboxgl.GeoJSONSource
				source?.setData(lassoPolygon.value)

				updateBounds()

				// Get visible layers
				const visibleLayers: MapboxLayer[] = layers.value.filter((layer) => {
					const tempLayer = layer.layer
					if (tempLayer && 'layout' in tempLayer) {
						const visibility = (tempLayer as FillLayerSpecification | LineLayerSpecification | SymbolLayerSpecification | CircleLayerSpecification).layout?.visibility
						return visibility === 'visible'
					}
					return false
				})

				const visibleLayersIds = visibleLayers.map(layer => layer.layer.id)

				if (visibleLayersIds.length > 0) {
					const allVisibleFeatures: Feature[] = []

					// Loop through all visible layers and collect features from sources
					sources.value.forEach((source) => {
						// Ensure the map reference and source are valid
						if (mapRef.value && mapRef.value.getSource(source.id)) {
							// Get all layers that are connected to this source
							const layersInSource = layers.value.filter(layer => layer.layer.source === source.id)

							// Filter layers to include only those that are visible
							const visibleLayersInSource = layersInSource.filter((layer) => {
								const visibility = mapRef.value?.getLayoutProperty(layer.layer.id, 'visibility')
								return visibility === 'visible'
							})

							// Query and collect features from all visible layers in the source
							visibleLayersInSource.forEach((layer) => {
								const features = mapRef.value?.querySourceFeatures(source.id, {
									sourceLayer: layer.layer.id, // Required for vector tiles
									filter: [
										'all',
										[ '==', [ 'geometry-type' ], 'Point' ] // Ensure the feature is a Point
									]
								})

								// Add the retrieved features to the allVisibleFeatures array
								if (features) {
									allVisibleFeatures.push(...features)
								}
							})
						}
					})

					const uniqueArray = allVisibleFeatures.filter((item, index, self) =>
						index === self.findIndex(t => t.properties?.id === item.properties?.id)
					)

					// Query features within the bounding box
					featuresInLassoPolygon.value = uniqueArray.filter((feature: Feature) => {
						const newFeature = feature.geometry as Feature<Point>['geometry']
						const polygon = turf.polygon(lassoPolygon.value?.geometry.coordinates as Position[][] || [ [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ] ])
						const point = turf.point(newFeature.coordinates)
						return turf.booleanPointInPolygon(point, polygon)
					})

					// Emit the updated event with filtered features
					emit('draw-update', lassoPolygon.value, roundedArea.value, featuresInLassoPolygon.value, e)
				}
			} else {
				roundedArea.value = null
			}
		}

		const deleteDrawFeature = (e: DrawDeleteEvent) => {
			if (mapRef.value && drawRef.value && drawControl.value) {
				updateArea()
				const currentFeatures = Array.from(drawRef.value?.getAll()?.features || [])
				if (!currentFeatures.length) {
					lassoPolygon.value = null
					roundedArea.value = null
					mapRef.value.removeLayer('draw-polygon-layer')
					mapRef.value.removeLayer('draw-polygon-outline')
					clearLassoPolygon()
					mapLassoActionControl.value?.updateLabel(lassoPolygonIds.value)
				}
				emit('draw-delete', lassoPolygon.value, e)
			}
		}

		mapRef.value.on('draw.create', handleDrawCreate) // REVIEW: I'm not sure how to fix this type error
		mapRef.value.on('draw.delete', deleteDrawFeature) // REVIEW: I'm not sure how to fix this type error
		mapRef.value.on('draw.update', updateArea) // REVIEW: I'm not sure how to fix this type error

		// watch lasso polygon and clearLassoPolygon to update the lasso polygon and rounded area
		watch(lassoPolygonIds, (newVal, oldVal) => {
			if (mapRef.value && drawControl.value) {
				if (mapControls.value.includes('lasso-polygon-control') && (!lassoPolygon.value || !lassoPolygonIds.value)) {
					roundedArea.value = null
					mapRef.value.removeLayer('draw-polygon-layer')
					mapRef.value.removeLayer('draw-polygon-outline')
					// Remove the control if the lasso polygon is empty
					mapRef.value.removeControl(mapLassoActionControl.value as unknown as mapboxgl.IControl)
					clearLassoPolygon()
					// clear the polygon points from drawRef
					drawRef.value?.deleteAll()
					const index = mapControls.value.indexOf('lasso-polygon-control')
					if (index > -1) {
						mapControls.value.splice(index, 1)
					}
				} else if (!mapControls.value.includes('lasso-polygon-control')) {
					mapLassoActionControl.value = new LassoActionControl(lassoPolygonIds.value, clearLassoPolygon)
					addControlIfNotExists(mapRef.value, mapLassoActionControl.value, 'lasso-polygon-control', 'top-center' as MapOptions['logoPosition'])
				} else if (newVal !== oldVal) {
					mapLassoActionControl.value?.updateLabel(lassoPolygonIds.value)
				}
			}
		}, { deep: true })
	}

	// Add listener for style loaded
	mapRef.value?.on('style.load', () => {
		// set the styleId to the current style for the style switcher
		styleId.value = mapRef.value?.getStyle()?.name || ''
		styleLoaded.value = true
		updateMapFeatures()
		addLayers()
	})

	// Add listener for map errors
	mapRef.value?.on('error', (error) => {
		bugsnagReport({ error: error ?? new Error('Mapbox failed to load') })
	})

	// Add listener for layer clicks and emit the event
	mapRef.value?.on('click', (e) => {
		e.originalEvent.preventDefault()
		if (drawRef.value && mapRef.value) {
			const mode = drawRef.value?.getMode()

			// disable other clicks when drawing
			const isDrawing = mode === 'draw_rectangle' ||
				mode === 'draw_polygon' ||
				(mode === 'simple_select' && drawRef.value && drawRef.value?.getSelected()?.features?.length > 0)
			if (isDrawing) { return }
		}

		// handle cluster click zooming
		const features = mapRef.value?.queryRenderedFeatures(e.point)

		if (features) {
			emit('click', features)

			const feature = features[0]
			const clusterId = feature?.properties?.cluster_id
			if (clusterId && mapRef.value) {
				const source = mapRef.value.getSource(feature.source) as mapboxgl.GeoJSONSource
				// Get the number of points in the cluster
				source.getClusterLeaves(clusterId, Infinity, 0, (err, leaves) => {
					if (err) {
						bugsnagReport({
							error: err instanceof Error ? err : new Error('Error getting cluster leaves: ' + (err as Error).toString()),
							showToast: true,
							toast: {
								title: 'Error',
								message: e instanceof Error ? err.message : 'Error getting cluster leaves',
								notificationType: 'error'
							}
						})
						return
					}
					// Get the zoom level based on the number of points in the cluster
					let zoomLevel = 16 // Default zoom level
					for (const cluster of clusterConfig.value.clusters) {
						if (leaves && cluster.maxLeaves && leaves.length <= cluster.maxLeaves) {
							const currentZoom = mapRef.value?.getZoom() || 0
							if (currentZoom === cluster.zoom) {
								zoomLevel = cluster.zoom + 1 // Increase the zoom by 1
							} else if (currentZoom > cluster.zoom) {
								zoomLevel = currentZoom + 1 // If current zoom is greater, increase by 1
							} else {
								zoomLevel = cluster.zoom // Set the zoom level to the cluster's zoom level
							}
							break
						} else if (leaves && leaves.length && !cluster.maxLeaves) {
							const currentZoom = mapRef.value?.getZoom() || 0
							if (currentZoom === cluster.zoom) {
								zoomLevel = cluster.zoom + 1 // Increase the zoom by 1
							} else if (currentZoom > cluster.zoom) {
								zoomLevel = currentZoom + 1 // If current zoom is greater, increase by 1
							} else {
								zoomLevel = cluster.zoom || 16 // Set the zoom level to the cluster's zoom level
							}
							break
						}
					}
					const geometryFeature = feature.geometry as Feature<Point>['geometry']
					if (geometryFeature?.coordinates && mapRef.value) {
						mapRef.value.easeTo({
							center: geometryFeature?.coordinates as LngLatLike,
							zoom: zoomLevel
						})
					}
				})
			}
			// if not a cluster, ease to the clicked feature
			if (!clusterId && mapRef.value) {
				if (mapRef.value) {
				// if the feature is a point, ease to the point
					if (feature?.geometry?.type === 'Point') {
						mapRef.value.easeTo({
							center: feature.geometry.coordinates as LngLatLike
						})
					}

					// if the feature is a line or polygon, ease to the feature's centroid
					if (feature?.geometry?.type === 'LineString' || feature?.geometry?.type === 'Polygon') {
						const centroid = turf.centroid(feature).geometry.coordinates as LngLatLike
						mapRef.value.easeTo({
							center: centroid
						})
					}
				}
			}
		}
	})

	// Add listener for mouse hover to set the cursor to pointer and emit the event
	mapRef.value?.on('mousemove', (e) => {
		if (drawRef.value && mapRef.value) {
			const mode = drawRef.value?.getMode()

			// disable other cursors when drawing
			const isDrawing = mode === 'draw_rectangle' ||
				mode === 'draw_polygon' ||
				(mode === 'simple_select' && drawRef.value && drawRef.value?.getSelected()?.features?.length > 0)
			if (isDrawing) { return }
		}

		const features = mapRef.value?.queryRenderedFeatures(e.point)
		if (mapRef.value && features) {
			const allLayerIds = layers.value.map(layer => layer.id) || []
			const featureIsFromLayer = features.some(feature => feature?.layer?.id && allLayerIds.includes(feature.layer.id))
			mapRef.value.getCanvas().style.cursor = featureIsFromLayer && features.length ? 'pointer' : ''
			emit('hover', features)
		}
	})
}

// --- Start Map Style Logic -----------------------------------
const colorMode = useColorMode()
const styleId = ref('')

const styleSwitcherOptions = new StyleSwitcherControl([
	// Add style switcher styles to be set in the control on mounted
	{ label: 'Satellite', url: MAPBOX_STYLE_SATELLITE_URL },
	{ label: 'Streets Dark', url: MAPBOX_STYLE_DARK_URL },
	{ label: 'Streets Light', url: MAPBOX_STYLE_LIGHT_URL }
])

watch(colorMode, () => { // update the map style when the color mode changes (if not on satellite mode)
	styleId.value = mapRef.value?.getStyle()?.name || ''

	if (styleId.value?.toLowerCase()?.includes('satellite')) { // if satellite mode, do not update the style
		return
	}

	// find the style that matches the current color mode in styleSwitcher
	const newStyle = styleSwitcherOptions.styles.find(style => style.label.toLowerCase().includes(colorMode.value.toLowerCase()))

	if (newStyle) {
		mapRef.value?.setStyle(newStyle.url)
	}
})

useResizeObserver(mapWrapper, (_entries) => { // handle resizing the map when the container changes size
	mapRef.value?.resize()
})
// --- End Map Style Logic -------------------------------------

onMounted(() => {
	initializeMap()
})
</script>

<style>
html, body, #__nuxt {
	height: 100%;
	margin: 0;
}

.mapboxgl-ctrl-top-center {
  top: 0;
  left: 50%;
  position: absolute;
  pointer-events: none;
  z-index: 2;
}

.mapboxgl-ctrl-top-center .mapboxgl-ctrl {
	margin: 10px 0 0 10px;
}

.mapboxgl-popup-content {
	@apply rounded-lg min-w-[400px];
	text-align: left !important;
	background: linear-gradient(45deg, #ffffff, #F9F9F9);
}

.dark .mapboxgl-popup-content {
	background: linear-gradient(45deg, #162327, #1a2a2e);

}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: #fff;
}
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: #fff;
}
.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: #fff;
}
.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: #fff;
}

.dark .mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.dark .mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.dark .mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
	border-bottom-color: #18272B;
}

.dark .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.dark .mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.dark .mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
	border-top-color: #18272B;
}

.dark .mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
	border-right-color: #18272B;
}

.dark .mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
	border-left-color: #18272B;
}
</style>
