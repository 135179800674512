<template>
	<div>
		<Header />
		<NavSideBar
			id="nav"
			v-model="sideBarCollapsed"
			class="fixed top-0 hidden h-full w-60 md:block"
		/>
		<div
			class="relative h-auto min-h-screen pt-10 pb-0 overflow-x-hidden transition-all lg:pt-0 bg-mx-gray-100 dark:bg-mx-green-900"
			:class="sideBarCollapsed ? 'md:ml-[70px]' : 'md:ml-60'"
		>
			<InternalAdminHeader />
			<div
				v-if="accessAllowed"
				class="w-full py-32"
			>
				<slot />
			</div>
			<div
				v-else
				class="pt-32"
			>
				<!-- You dont have permission to view or modify system settings. -->
				<div class="panel-wrapper">
					<div class="flex items-center justify-center h-96 panel rounded-xl">
						<div class="text-center">
							<h2 class="text-2xl font-bold dark:text-mx-gray-200">
								Permission Denied
							</h2>
							<p class="mt-4 text-mx-gray-500 dark:text-mx-gray-400">
								You do not have permission to view system settings.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { useAuth } from '@/composables/useAuth'
import { useRoute } from 'vue-router'

const { hasSettingsViewPermission, hasAlertsViewAllPermission } = useAuth()

const sideBarCollapsed = ref(false)

const route = useRoute()

const currentRoute = computed(() => route.name)

const routePermissions = {
	'internal-settings': hasSettingsViewPermission,
	'internal-settings-alert': hasAlertsViewAllPermission
}

const accessAllowed = computed(() => {
	return !!routePermissions[currentRoute?.value as keyof typeof routePermissions]
})
</script>
