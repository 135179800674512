import type { SortMap } from '@/utils/sortMethods/types'

const TICKET_STATUS_OPTIONS = {
	OPEN: 'Open',
	CLOSED: 'Closed',
	PENDING_INTERNAL: 'Pending Internal',
	PENDING_EXTERNAL: 'Pending External',
	ACTIVE: 'Active',
	CANCELED: 'Canceled'
} as const

const TICKET_CATEGORY_OPTIONS = {
	GENERAL: 'General',
	BILLING: 'Billing',
	CONSTRUCTION: 'Construction',
	RECLAMATION: 'Reclamation',
	TECH_SUPPORT: 'Tech Support',
	ACCOUNTING: 'Accounting',
	VOIP: 'VOIP',
	STATIC_IP: 'Static IP'
} as const

const TICKET_PRIORITY_OPTIONS = {
	LOW: 'Low',
	MEDIUM: 'Medium',
	HIGH: 'High'
} as const

const TICKET_TIER_OPTIONS = {
	TIER1: 'Tier 1',
	TIER2: 'Tier 2'
} as const

const TICKET_STATUS_MAPPING: SortMap = {
	active: 4,
	open: 3,
	'pending-internal': 2,
	'pending-external': 1,
	closed: 0
}

const TICKET_PRIORITY_MAPPING: SortMap = {
	highest: 4,
	high: 3,
	medium: 2,
	low: 1,
	lowest: 0
}

const inProgressStatuses = [
	TICKET_STATUS_OPTIONS.OPEN,
	TICKET_STATUS_OPTIONS.PENDING_EXTERNAL,
	TICKET_STATUS_OPTIONS.PENDING_INTERNAL,
	TICKET_STATUS_OPTIONS.ACTIVE
]

export { TICKET_STATUS_OPTIONS, TICKET_PRIORITY_OPTIONS, TICKET_CATEGORY_OPTIONS, TICKET_STATUS_MAPPING, TICKET_PRIORITY_MAPPING, inProgressStatuses, TICKET_TIER_OPTIONS }
