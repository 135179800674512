<template>
	<div>
		<BatchActionWorkflowStepHeader>
			<template #title>
				Choose Action: Step {{ stepNumber }} of {{ totalSteps }}
			</template>
			<template #description>
				Select the action you want to perform on <strong class="text-mx-orange">
					{{ selectedItemsCount }}
				</strong> accounts.
			</template>
			<template #content>
				<!-- radio buttons and labels for actions -->
				<div>
					<div
						v-for="action in actionOptions"
						:key="action.id"
						class="group"
					>
						<div class="flex items-center flex-shrink-0 px-2 -mx-2 rounded-lg group-hover:bg-white/80 dark:group-hover:bg-mx-green-800">
							<div class="relative flex items-center flex-shrink-0">
								<input
									:id="action.id"
									v-model="selectedAction"
									type="radio"
									:value="action.id"
									class="transition-all duration-100 bg-mx-gray-200 border-2 border-mx-gray-400 appearance-none h-4 w-4 rounded-full cursor-pointer group-has-[:checked]:bg-mx-orange group-has-[:checked]:border-mx-orange text-mx-orange ring-mx-orange focus:ring-mx-orange group-hover:bg-mx-gray-100 dark:group-hover:bg-mx-green-900 dark:bg-mx-green-900 group-has-[:checked]:dark:bg-mx-orange"
								>
								<div class="absolute left-[5px] top-[5px] w-1.5 h-1.5 bg-transparent group-has-[:checked]:bg-white dark:group-has-[:checked]:bg-mx-green-900 rounded-full transform transition-transform duration-100" />
							</div>

							<label
								:for="action.id"
								class="flex flex-col w-full gap-4 py-4 pl-4 cursor-pointer lg:flex-row dark:text-mx-gray-400"
							>
								<span class="font-semibold lg:w-72 dark:text-mx-gray-300">
									{{ action.label }}
								</span>
								<span v-html="action.description" />
							</label>
						</div>
					</div>
				</div>
			</template>
		</BatchActionWorkflowStepHeader>

		<div class="flex items-center justify-start w-full pt-6 bg-mx-gray-100 dark:bg-mx-green-900">
			<div class="flex items-center gap-4">
				<FormButton
					id="continue-button"
					button-text="Continue"
					:variant="disabled ? 'custom' : 'primary'"
					:disabled="disabled"
					button-width="w-32"
					submit
					:class="{ 'border-2 border-transparent dark:border-mx-green-800 cursor-not-allowed bg-mx-gray-200 dark:bg-mx-green-800' : disabled }"
					@submit="handleContinue"
				/>
				<FormButton
					id="cancel-button"
					button-text="Cancel"
					variant="secondary-background"
					button-width="w-32"
					@click="handleClose"
				/>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import type { BatchActionOption } from '@/components/table/types'

const props = defineProps({
	actionOptions: {
		type: Object as PropType<BatchActionOption[]>,
		required: true
	},
	stepNumber: {
		type: Number,
		required: true
	},
	totalSteps: {
		type: Number,
		required: true
	},
	selectedItemsCount: {
		type: Number,
		default: 0
	}
})

const { actionOptions } = toRefs(props)

const emit = defineEmits([ 'close', 'save' ])

const selectedAction = ref<string | null>(null)

const disabled = computed(() => !selectedAction.value)

const handleContinue = () => {
	emit('save', { actionOption: actionOptions.value.find(action => action.id === selectedAction.value) })
}

const handleClose = () => {
	emit('close')
}
</script>
