import type { PresetFilter } from '@/components/table/types'
import {
	LEAD,
	PROSPECT,
	OPPORTUNITY,
	PENDING_INSTALL,
	PENDING_ACCEPTANCE,
	ACTIVE,
	INACTIVE,
	IGNORE,
	FAILED_INSTALL
} from '@/constants/accounts/statuses'

import type { BatchAction, BatchActionConfig } from '@/components/table/types'
import { API_ENDPOINT_PORTAL_BULK_SCHEDULE_EMAIL } from '@/constants'

const ACCOUNTS_TABLE_FILTERS = [
	{
		id: 'accountStatusActive',
		columnKey: 'accountStatus',
		label: 'Account Status: Active',
		matches: ACTIVE
	},
	{
		id: 'accountStatusInactive',
		columnKey: 'accountStatus',
		label: 'Account Status: Inactive',
		matches: INACTIVE
	},
	{
		id: 'accountStatusPendingInstall',
		columnKey: 'accountStatus',
		label: 'Account Status: Pending Install',
		matches: PENDING_INSTALL
	},
	{
		id: 'accountStatusPendingAcceptance',
		columnKey: 'accountStatus',
		label: 'Account Status: Pending Acceptance',
		matches: PENDING_ACCEPTANCE
	},
	{
		id: 'accountStatusLead',
		columnKey: 'accountStatus',
		label: 'Account Status: Lead',
		matches: LEAD
	},
	{
		id: 'accountStatusIgnore',
		columnKey: 'accountStatus',
		label: 'Account Status: Ignore',
		matches: IGNORE,
		preventIntersections: true
	},
	{
		id: 'accountStatusOpportunity',
		columnKey: 'accountStatus',
		label: 'Account Status: Opportunity',
		matches: OPPORTUNITY
	},
	{
		id: 'accountStatusProspect',
		columnKey: 'accountStatus',
		label: 'Account Status: Prospect',
		matches: PROSPECT
	},
	{
		id: 'accountStatusFailedInstall',
		columnKey: 'accountStatus',
		label: 'Account Status: Failed Install',
		matches: FAILED_INSTALL
	},
	/*
	{
		id: 'onboardingCompletedNo',
		columnKey: 'onboardingCompleted',
		label: 'Onboarding Completed: No',
		matches: 'false'
	},
	{
		id: 'onboardingCompletedYes',
		columnKey: 'onboardingCompleted',
		label: 'Onboarding Completed: Yes',
		matches: 'true'
	},
	*/
	{
		id: 'insideInstalledNo',
		columnKey: 'insideInstalled',
		label: 'Inside Installed: No',
		matches: 'false'
	},
	{
		id: 'insideInstalledYes',
		columnKey: 'insideInstalled',
		label: 'Inside Installed: Yes',
		matches: 'true'
	},
	{
		id: 'outsideInstalledNo',
		columnKey: 'outsideInstalled',
		label: 'Outside Installed: No',
		matches: 'false'
	},
	{
		id: 'outsideInstalledYes',
		columnKey: 'outsideInstalled',
		label: 'Outside Installed: Yes',
		matches: 'true'
	},
	{
		id: 'siteSurveyedNo',
		columnKey: 'siteSurveyed',
		label: 'Site Surveyed: No',
		matches: 'false'
	},
	{
		id: 'siteSurveyedYes',
		columnKey: 'siteSurveyed',
		label: 'Site Surveyed: Yes',
		matches: 'true'
	},
	{
		id: 'surveyStatusScheduled',
		columnKey: 'surveyStatus',
		label: 'Survey Status: Scheduled',
		matches: 'scheduled'
	},
	{
		id: 'surveyStatusNeeded',
		columnKey: 'surveyStatus',
		label: 'Survey Status: Needed',
		matches: 'needed'
	},
	{
		id: 'surveyStatusCompleted',
		columnKey: 'surveyStatus',
		label: 'Survey Status: Completed',
		matches: 'completed'
	},
	{
		id: 'insideInstallScheduled',
		columnKey: 'insideInstallStatus',
		label: 'Inside Install Status: Scheduled',
		matches: 'scheduled'
	},
	{
		id: 'insideInstallNeeded',
		columnKey: 'insideInstallStatus',
		label: 'Inside Install Status: Needed',
		matches: 'needed'
	},
	{
		id: 'insideInstallCompleted',
		columnKey: 'insideInstallStatus',
		label: 'Inside Install Status: Completed',
		matches: 'completed'
	},
	{
		id: 'cablePulledNo',
		columnKey: 'cablePulled',
		label: 'Cable Pulled: No',
		matches: 'false'
	},
	{
		id: 'cablePulledYes',
		columnKey: 'cablePulled',
		label: 'Cable Pulled: Yes',
		matches: 'true'
	},
	{
		id: 'cableTerminatedNo',
		columnKey: 'cableTerminated',
		label: 'Cable Terminated: No',
		matches: 'false'
	},
	{
		id: 'cableTerminatedYes',
		columnKey: 'cableTerminated',
		label: 'Cable Terminated: Yes',
		matches: 'true'
	},
	{
		id: 'cableSplicedNo',
		columnKey: 'cableSpliced',
		label: 'Cable Spliced: No',
		matches: 'false'
	},
	{
		id: 'cableSplicedYes',
		columnKey: 'cableSpliced',
		label: 'Cable Spliced: Yes',
		matches: 'true'
	},
	{
		id: 'priorityInstallYes',
		columnKey: 'priorityInstall',
		label: 'Priority Install: Yes',
		matches: 'true'
	},
	{
		id: 'priorityInstallNo',
		columnKey: 'priorityInstall',
		label: 'Priority Install: No',
		matches: 'false'
	},
	{
		id: 'hasMoveInYes',
		columnKey: 'has_move_in',
		label: 'Has Move-In Date: Yes',
		matches: '1'
	},
	{
		id: 'hasMoveInNo',
		columnKey: 'has_move_in',
		label: 'Has Move-In Date: No',
		matches: '0'
	},
	{
		id: 'hasIgnoreOverdueYes',
		columnKey: 'has_ignore_overdue',
		label: 'Has Ignore Overdue: Yes',
		matches: '1'
	},
	{
		id: 'hasIgnoreOverdueNo',
		columnKey: 'has_ignore_overdue',
		label: 'Has Ignore Overdue: No',
		matches: '0'
	},
	{
		id: 'mainlineCompletedYes',
		columnKey: 'zoneMainlineStatus',
		label: 'Zone Mainline: Completed',
		matches: 'completed'
	},
	{
		id: 'hasEquipmentYes',
		columnKey: 'hasEquipment',
		label: 'Has Equipment: Yes',
		matches: 'true'
	},
	{
		id: 'hasEquipmentNo',
		columnKey: 'hasEquipment',
		label: 'Has Equipment: No',
		matches: 'false'
	},
	{
		id: 'isDelinquentYes',
		columnKey: 'delinquent',
		label: 'Is Delinquent: Yes',
		matches: 'true'
	},
	{
		id: 'isDelinquentNo',
		columnKey: 'delinquent',
		label: 'Is Delinquent: No',
		matches: 'false'
	}
]

const ACCOUNTS_TABLE_COLUMNS = [
	{
		key: 'id',
		label: 'ID',
		sortable: true,
		searchable: true
	},
	{
		key: 'name',
		label: 'Name',
		sortable: true,
		searchable: true
	},
	{
		key: 'phone',
		label: 'Phone',
		sortable: true,
		searchable: true
	},
	{
		key: 'address',
		label: 'Address',
		sortable: true,
		searchable: true
	},
	{
		key: 'neighborhood',
		label: 'Neighborhood',
		sortable: true,
		searchable: true
	},
	{
		key: 'accountStatus',
		label: 'Account Status',
		sortable: true,
		searchable: false
	},
	{
		key: 'stickyNote',
		label: 'Sticky Note',
		sortable: false,
		searchable: false
	},
	{
		key: 'email',
		label: 'Email',
		sortable: true,
		searchable: true
	},
	{
		key: 'moveInDate',
		label: 'Move In Date',
		sortable: true,
		searchable: false
	},
	{
		key: 'accountCreatedTime',
		label: 'Account Created',
		sortable: true,
		searchable: false
	},
	/*
	{
		key: 'onboardingCompleted',
		label: 'Onboarding Completed',
		sortable: true,
		searchable: true
	},
	*/
	{
		key: 'surveyStatus',
		label: 'Survey Status',
		sortable: true
	},
	{
		key: 'insideInstallStatus',
		label: 'Inside Install Status',
		sortable: true
	},
	{
		key: 'siteSurveyed',
		label: 'Site Surveyed',
		sortable: true
	},
	{
		key: 'outsideInstalled',
		label: 'Outside Installed',
		sortable: true
	},
	{
		key: 'cablePulled',
		label: 'Cable Pulled',
		sortable: true
	},
	{
		key: 'cableSpliced',
		label: 'Cable Spliced',
		sortable: true
	},
	{
		key: 'cableTerminated',
		label: 'Cable Terminated',
		sortable: true
	},
	{
		key: 'insideInstalled',
		label: 'Inside Installed',
		sortable: true
	},
	{
		key: 'hasLogin',
		label: 'Has Login',
		sortable: true
	},
	{
		key: 'ignoreOverdue',
		label: 'Ignore Overdue',
		sortable: true
	},
	{
		key: 'activationDate',
		label: 'Activation Date',
		sortable: true
	},
	{
		key: 'mainlineCompleted',
		label: 'Mainline Completed',
		sortable: false
	},
	{
		key: 'hasEquipment',
		label: 'Has Equipment',
		sortable: false
	},
	{
		key: 'delinquent',
		label: 'Is Delinquent',
		sortable: false
	}
]

const defaultColumns = [ 'id', 'name', 'phone', 'address', 'neighborhood', 'accountStatus', 'priorityInstall', 'email' ]

const ACCOUNTS_TABLE_DEFAULT_COLUMNS = ACCOUNTS_TABLE_COLUMNS.filter(column => defaultColumns.includes(column.key))

const ACCOUNTS_TABLE_PRESET_FILTERS: PresetFilter[] = [
	/*
	{
		label: 'Ready for Activation',
		id: 'readyForActivation',
		filters: [
			{
				id: 'accountStatusInactive',
				columnKey: 'accountStatus',
				label: 'Account Status: Inactive',
				matches: 'inactive'
			},
			{
				id: 'cableSplicedYes',
				columnKey: 'cableSpliced',
				label: 'Cable Spliced: Yes',
				matches: 'true'
			},
			{
				id: 'cableTerminatedYes',
				columnKey: 'cableTerminated',
				label: 'Cable Terminated: Yes',
				matches: 'true'
			},
			{
				id: 'insideInstalledYes',
				columnKey: 'insideInstalled',
				label: 'Inside Installed: Yes',
				matches: 'true'
			},
			{
				id: 'outsideInstalledYes',
				columnKey: 'outsideInstalled',
				label: 'Outside Installed: Yes',
				matches: 'true'
			}
		],
		columnIds: [
			'id',
			'name',
			'phone',
			'address',
			'neighborhood',
			'accountStatus',
			'priorityInstall'
		]
	},
	{
		label: 'Needs Survey',
		id: 'needsSurvey',
		filters: [
			{
				id: 'siteSurveyedNo',
				columnKey: 'siteSurveyed',
				label: 'Site Surveyed: No',
				matches: 'false'
			}
		],
		columnIds: [
			'id',
			'name',
			'phone',
			'address',
			'neighborhood',
			'accountStatus',
			'priorityInstall'
		]
	},
	{
		label: 'Ready for Approval',
		id: 'readyForApproval',
		filters: [
			{
				id: 'siteSurveyedYes',
				columnKey: 'siteSurveyed',
				label: 'Site Surveyed: Yes',
				matches: 'true'
			},
			{
				id: 'accountStatusInactive',
				columnKey: 'accountStatus',
				label: 'Account Status: Inactive',
				matches: 'inactive'
			}
		],
		columnIds: [
			'id',
			'name',
			'phone',
			'address',
			'neighborhood',
			'accountStatus',
			'priorityInstall'
		]
	},
	*/
	/*
	{
		label: 'Has Ignore Overdue',
		id: 'ignoreOverdue',
		filters: [
			{
				id: 'hasIgnoreOverdueYes',
				columnKey: 'has_ignore_overdue',
				label: 'Has Ignore Overdue',
				matches: '1'
			}
		],
		columnIds: [
			'id',
			'name',
			'phone',
			'address',
			'neighborhood',
			'accountStatus',
			'priorityInstall',
			'ignoreOverdue'
		]
	},
	*/
	{
		label: 'Has Equipment',
		id: 'hasEquipment',
		filters: [
			{
				id: 'hasEquipmentYes',
				columnKey: 'hasEquipment',
				label: 'Has Equipment',
				matches: 'true'
			}
		],
		columnIds: [
			'id',
			'name',
			'phone',
			'address',
			'neighborhood',
			'accountStatus',
			'email',
			'hasEquipment'
		]
	},
	{
		label: 'Has Move In Date',
		id: 'moveInDate',
		filters: [
			{
				id: 'hasMoveInYes',
				columnKey: 'has_move_in',
				label: 'Has Move In Date',
				matches: '1'
			}
		],
		columnIds: [
			'id',
			'name',
			'phone',
			'address',
			'neighborhood',
			'accountStatus',
			'priorityInstall',
			'moveInDate'
		]
	},
	{
		label: 'Ignored Accounts',
		id: 'ignoredAccounts',
		filters: [
			{
				id: 'accountStatusIgnore',
				columnKey: 'accountStatus',
				label: IGNORE,
				matches: IGNORE,
				preventIntersections: true
			}
		]
	},
	{
		label: 'Survey Status',
		id: 'surveyStatus',
		multiSelect: false,
		allOption: false,
		options: [
			{
				id: 'surveyStatusScheduled',
				columnKey: 'surveyStatus',
				label: 'Scheduled',
				matches: 'scheduled'
			},
			{
				id: 'surveyStatusNeeded',
				columnKey: 'surveyStatus',
				label: 'Needed',
				matches: 'needed'
			},
			{
				id: 'surveyStatusCompleted',
				columnKey: 'surveyStatus',
				label: 'Completed',
				matches: 'completed'
			}
		],
		columnIds: [
			'id',
			'name',
			'phone',
			'address',
			'neighborhood',
			'accountStatus',
			'priorityInstall',
			'surveyStatus'
		]
	},
	{
		label: 'Inside Install Status',
		id: 'insideInstallStatus',
		multiSelect: false,
		allOption: false,
		options: [
			{
				id: 'insideInstallScheduled',
				columnKey: 'insideInstallStatus',
				label: 'Scheduled',
				matches: 'scheduled'
			},
			{
				id: 'insideInstallNeeded',
				columnKey: 'insideInstallStatus',
				label: 'Needed',
				matches: 'needed'
			},
			{
				id: 'insideInstallCompleted',
				columnKey: 'insideInstallStatus',
				label: 'Completed',
				matches: 'completed'
			}
		],
		columnIds: [
			'id',
			'name',
			'phone',
			'address',
			'neighborhood',
			'accountStatus',
			'priorityInstall',
			'insideInstallStatus'
		]
	}
]

const ACCOUNTS_BATCH_ACTION_IDS = {
	SEND_SCHEDULING_MESSAGE: 'sendSchedulingMessage'
}

const ACCOUNTS_BATCH_ACTION_COMPONENT_NAMES = {
	SEND_SCHEDULING_MESSAGE: {
		CHOOSE_ACCOUNTS: 'ChooseAccountsSchedulingMessage',
		CHOOSE_ACTION: 'ChooseActionSchedulingMessage',
		CONFIRMATION: 'ConfirmationSchedulingMessageAction'
	}
}

const ACCOUNTS_BATCH_ACTION_MENU_OPTIONS: BatchAction[] = [
	{
		id: ACCOUNTS_BATCH_ACTION_IDS.SEND_SCHEDULING_MESSAGE,
		label: 'Email'
	}
]

const SEND_SCHEDULING_MESSAGE_ACTION: BatchActionConfig = {
	id: ACCOUNTS_BATCH_ACTION_IDS.SEND_SCHEDULING_MESSAGE,
	label: 'Send Scheduling Message',
	actionOptions: [
		{
			id: 'sendSiteSurveyEmail',
			label: 'Site Survey Email',
			description: 'An email will be sent to the account(s) instructing them to schedule a <b class="dark:text-mx-gray-300">Site Survey.</b>',
			endpoint: {
				url: API_ENDPOINT_PORTAL_BULK_SCHEDULE_EMAIL,
				params: {
					accountIds: [],
					scheduleType: 'siteSurvey'
				}
			}
		},
		{
			id: 'sendInsideInstallEmail',
			label: 'Inside Install Email',
			description: 'An email will be sent to the account(s) instructing them to schedule an <b class="dark:text-mx-gray-300">Inside Install.</b>',
			endpoint: {
				url: API_ENDPOINT_PORTAL_BULK_SCHEDULE_EMAIL,
				params: {
					accountIds: [],
					scheduleType: 'insideInstall'
				}
			}
		},
		{
			id: 'sendInstallationPlanningEmail',
			label: 'Installation Planning Email',
			description: 'An email will be sent to the account(s) instructing them to complete <b class="dark:text-mx-gray-300">Installation Planning.</b>',
			endpoint: {
				url: API_ENDPOINT_PORTAL_BULK_SCHEDULE_EMAIL,
				params: {
					accountIds: [],
					scheduleType: 'installationPlanning'
				}
			}
		},
		{
			id: 'sendActivationEmail',
			label: 'Activation Email',
			description: 'An email will be sent to the account(s) instructing them to complete <b class="dark:text-mx-gray-300">Activation.</b>',
			endpoint: {
				url: API_ENDPOINT_PORTAL_BULK_SCHEDULE_EMAIL,
				params: {
					accountIds: [],
					scheduleType: 'activation'
				}
			}
		}
		/*
		{
			id: 'sendSiteSurveySMS',
			label: 'Site Survey SMS',
			description: 'An SMS will be sent to the account(s) instructing them to schedule a <b class="dark:text-mx-gray-300">Site Survey.</b>',
			{
				endpoint: {
					url: '',
					params: {}
				}
			}
		},
		{
			id: 'sendInsideInstallSMS',
			label: 'Inside Install SMS',
			description: 'An SMS will be sent to the account(s) instructing them to schedule an <b class="dark:text-mx-gray-300">Inside Install.</b>',
			{
				endpoint: {
					url: '',
					params: {}
				}
			}
		}
		*/
	],
	steps: [
		{
			id: 1,
			label: 'Choose Items',
			componentName: ACCOUNTS_BATCH_ACTION_COMPONENT_NAMES.SEND_SCHEDULING_MESSAGE.CHOOSE_ACCOUNTS
		},
		{
			id: 2,
			label: 'Choose Action',
			componentName: ACCOUNTS_BATCH_ACTION_COMPONENT_NAMES.SEND_SCHEDULING_MESSAGE.CHOOSE_ACTION
		},
		{
			id: 3,
			label: 'Confirmation',
			componentName: ACCOUNTS_BATCH_ACTION_COMPONENT_NAMES.SEND_SCHEDULING_MESSAGE.CONFIRMATION
		}
	]
}

const ACCOUNTS_BATCH_ACTIONS: BatchActionConfig[] = [
	SEND_SCHEDULING_MESSAGE_ACTION
]

export {
	ACCOUNTS_TABLE_FILTERS,
	ACCOUNTS_TABLE_COLUMNS,
	ACCOUNTS_TABLE_PRESET_FILTERS,
	ACCOUNTS_TABLE_DEFAULT_COLUMNS,
	ACCOUNTS_BATCH_ACTION_COMPONENT_NAMES,
	ACCOUNTS_BATCH_ACTION_IDS,
	ACCOUNTS_BATCH_ACTIONS,
	ACCOUNTS_BATCH_ACTION_MENU_OPTIONS,
	SEND_SCHEDULING_MESSAGE_ACTION
}
