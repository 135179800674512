import { ref, computed } from 'vue'
import { API_BASE } from '@/constants/index.js'
import { mapAccount } from '@/utils/mappers/AccountMap'
import { useErrorReporter } from '@/composables/useErrorReporter'
import type { MappedAccountData } from '@/components/account/types'

const accountData = ref<MappedAccountData>()
const lastAccountId = ref('')
const fullAddress = computed(() => {
	const streetAddress = accountData.value?.address || ''
	const city = accountData.value?.city || ''
	const state = accountData.value?.state || ''
	const country = 'USA'
	if (!streetAddress || !city || !state) {
		return ''
	}
	return `${streetAddress}, ${city}, ${state}, ${country}`
})
const fullName = computed(() => `${accountData.value?.firstName} ${accountData.value?.lastName}`)
const accountName = computed(() => {
	return {
		title: accountData.value?.title || null,
		first: accountData.value?.firstName || '',
		middle: accountData.value?.middleName || null,
		last: accountData.value?.lastName || '',
		suffix: accountData.value?.suffix || null
	}
})
const loading = ref(true)
const refetch = ref(false)

export function useAccount () {
	const { bugsnagReport } = useErrorReporter()

	const refetchAccount = async () => {
		refetch.value = true
		if (lastAccountId.value) {
			if (typeof lastAccountId.value === 'number') {
				lastAccountId.value = (lastAccountId.value as number)?.toString()
			}
			await getAccount(lastAccountId.value)
		}
	}

	const fetchAccount = async (accountId: string | number) => {
		loading.value = true

		if (lastAccountId.value === accountId?.toString()) { // Avoid unnecessary API calls
			loading.value = false
			return
		}

		if (typeof accountId === 'number') {
			lastAccountId.value = accountId?.toString()
		} else {
			lastAccountId.value = accountId
		}

		await $fetch(`${API_BASE}/portal/accounts/${accountId}`, {
			method: 'GET',
			credentials: 'include',
			onResponse ({ response }) {
				if (!response?.ok) {
					loading.value = false
					bugsnagReport({
						error: new Error(response._data?.error || response._data?.errors?.[0]),
						showToast: true,
						toast: {
							title: 'Error fetching account data',
							message: response._data?.errors?.[0]?.title
						}
					})
					throw new Error(response._data?.error || response._data?.errors?.[0])
				}
				loading.value = false
				accountData.value = mapAccount(response._data)
			}
		}).catch((e) => {
			bugsnagReport({
				error: e,
				showToast: true,
				toast: {
					title: 'Error fetching account data'
				}
			})
		})
		loading.value = false
		refetch.value = false
	}

	const getAccount = async (accountId: string) => {
		if (refetch.value) {
			await fetchAccount(accountId)
			return
		}
		if (accountId === lastAccountId.value) { return }
		await fetchAccount(accountId)
	}

	return {
		accountData,
		fullAddress,
		fullName,
		accountName,
		getAccount,
		refetchAccount,
		fetchAccount,
		loading
	}
}
