import { API_BASE, API_ENDPOINT_PORTAL_ACCOUNTS_NOTES, API_ENDPOINT_PORTAL_ACCOUNTS_NOTES_STICKY, API_ENDPOINT_PORTAL_NOTES_CATEGORIES, API_ENDPOINT_PORTAL_NOTES_UPDATE_CATEOGORY } from '@/constants/index.js'
import { useErrorReporter } from '@/composables/useErrorReporter'
import type { NoteResponse, MappedNote, MappedNoteCategory } from '@/components/internal/account/notes/types'
import { mapNoteResponse, mapNoteCategories } from '@/utils/mappers/NotesMap'
import { useAuth } from '@/composables/useAuth'
import { ref } from 'vue'
import { useToast } from '@/composables/useToast'

const notes = ref<MappedNote[]>([])
const loading = ref(true)
const lastAccountId = ref('')
const { fullName, email } = useAuth()
const stickyNote = ref<MappedNote>()
const ACTIVATION_SENT_NOTE_STATUS = 'Pending Install → Pending Acceptance'
const ACTIVATION_REMINDER_BODY = 'Activation Reminder sent'
const lastActivationInviteNote = ref<MappedNote>()
const notesCategories = ref<MappedNoteCategory[]>([])

export function useNotes () {
	const { addToast } = useToast()
	const { bugsnagReport } = useErrorReporter()
	const resetNotes = () => {
		notes.value = []
		stickyNote.value = undefined
	}

	const findLastActivationNote = (notes: MappedNote[]) => {
		return notes.find((note: MappedNote) => {
			return note?.json?.status === ACTIVATION_SENT_NOTE_STATUS || note.body.includes(ACTIVATION_REMINDER_BODY)
		})
	}

	const getNotes = async (accountId: string, refetch?: boolean) => {
		if (accountId === lastAccountId.value && notes.value.length > 0 && !refetch) {
			return
		}
		lastAccountId.value = accountId
		loading.value = true

		if (!accountId) {
			resetNotes()
			loading.value = false
			bugsnagReport({
				error: new Error('getNotes: No account id provided'),
				showToast: true,
				toast: {
					title: 'Error fetching notes',
					message: 'An error occurred while fetching notes'
				}
			})
			return
		}

		const fullPath = `${API_BASE}${API_ENDPOINT_PORTAL_ACCOUNTS_NOTES.replace('{account-id}', accountId)}`

		try {
			await $fetch(fullPath, {
				method: 'GET',
				credentials: 'include',
				onResponse ({ response }) {
					resetNotes()
					if (response.ok) {
						loading.value = false
						if (response._data?.length) {
							const mappedNotes = response._data.map((note: NoteResponse) => mapNoteResponse(note))
							notes.value = mappedNotes?.filter((note: MappedNote) => !note.sticky)
							stickyNote.value = mappedNotes?.find((note: MappedNote) => note.sticky)
							lastActivationInviteNote.value = findLastActivationNote(notes.value)
						}
					} else {
						bugsnagReport({
							error: new Error(`getNotes: Failed with status ${response.status}`),
							showToast: true,
							metaData: response._data?.errors,
							user: { id: accountId, email: email.value, name: fullName.value }
						})
					}
				}
			})
		} catch (e) {
			resetNotes()
			bugsnagReport({
				error: e instanceof Error ? e : new Error('getInvoices - catch: ' + (e as Error).toString()),
				showToast: true,
				user: { id: accountId, email: email.value, name: fullName.value }
			})
		} finally {
			loading.value = false
		}
	}

	const setNote = async (data: { note: string, accountId: string, category: string }) => {
		const { note, accountId, category } = data

		loading.value = true

		const fullPath = `${API_BASE}${API_ENDPOINT_PORTAL_ACCOUNTS_NOTES.replace('{account-id}', accountId)}`
		const body = {
			noteBody: note,
			category,
			lastModified: new Date().toISOString()
		}

		try {
			await $fetch(fullPath, {
				method: 'POST',
				credentials: 'include',
				body: JSON.stringify(body),
				onResponse ({ response }) {
					if (response.ok && response.status === 200) {
						getNotes(accountId, true)
					} else {
						bugsnagReport({
							error: new Error(`setNote: No data or failed with status ${response.status}`),
							showToast: true,
							metaData: response._data?.errors,
							user: { id: accountId, email: email.value, name: fullName.value }
						})
					}
				}
			})
		} catch (e) {
			bugsnagReport({
				error: e instanceof Error ? e : new Error('setNote - catch: ' + (e as Error).toString()),
				showToast: true,
				user: { id: accountId, email: email.value, name: fullName.value }
			})
		} finally {
			loading.value = false
		}
	}

	const setStickyNote = async (data: { note: string, accountId: string }) => {
		const { note, accountId } = data

		loading.value = true

		const fullPath = `${API_BASE}${API_ENDPOINT_PORTAL_ACCOUNTS_NOTES_STICKY.replace('{account-id}', accountId)}`
		const body = {
			noteBody: note
		}

		try {
			await $fetch(fullPath, {
				method: 'PUT',
				credentials: 'include',
				body: JSON.stringify(body),
				onResponse ({ response }) {
					if (response.ok && response.status === 200) {
						getNotes(accountId, true)
					} else {
						bugsnagReport({
							error: new Error(`setStickyNote: No data or failed with status ${response.status}`),
							showToast: true,
							metaData: response._data?.errors,
							user: { id: accountId, email: email.value, name: fullName.value }
						})
					}
				}
			})
		} catch (e) {
			bugsnagReport({
				error: e instanceof Error ? e : new Error('setStickyNote - catch: ' + (e as Error).toString()),
				showToast: true,
				user: { id: accountId, email: email.value, name: fullName.value }
			})
		} finally {
			loading.value = false
		}
	}

	const getNotesCategories = async () => {
		loading.value = true
		await $fetch(`${API_BASE}${API_ENDPOINT_PORTAL_NOTES_CATEGORIES}`, {
			method: 'GET',
			credentials: 'include',
			onResponse ({ response }) {
				if (!response.ok) {
					throw new Error(response._data?.errors?.[0]?.title)
				}
				notesCategories.value = mapNoteCategories(response._data)
			}
		}).catch((e) => {
			bugsnagReport({
				error: e instanceof Error ? e : new Error('getNotesCategories - catch: ' + (e as Error).toString()),
				showToast: true,
				toast: {
					title: 'Error fetching notes categories',
					message: e instanceof Error ? e.message : 'An error occurred while fetching notes categories'
				}
			})
		}).finally(() => {
			loading.value = false
		})
	}

	const updateNoteCategory = async (data: { noteId: string, accountId: string, categoryId: number }) => {
		loading.value = true
		const { noteId, accountId, categoryId } = data
		const fullPath = `${API_BASE}${API_ENDPOINT_PORTAL_NOTES_UPDATE_CATEOGORY.replace('{account-id}', accountId).replace('{note-id}', noteId)}`
		await $fetch(fullPath, {
			method: 'PUT',
			credentials: 'include',
			body: { noteCategoryId: categoryId },
			onResponse ({ response }) {
				if (!response.ok) {
					throw new Error(response._data?.errors?.[0]?.title)
				}
				getNotes(accountId, true)
				addToast({
					title: 'Note category updated',
					message: 'The note category has been updated successfully.',
					notificationType: 'success'
				})
			}
		}).catch((e) => {
			bugsnagReport({
				error: e instanceof Error ? e : new Error('updateNoteCategory - catch: ' + (e as Error).toString()),
				showToast: true,
				toast: {
					title: 'Error updating note category',
					message: e instanceof Error ? e.message : 'An error occurred while updating note category'
				}
			})
		}).finally(() => {
			loading.value = false
		})
	}

	return {
		getNotes,
		setNote,
		notes,
		loading,
		stickyNote,
		setStickyNote,
		lastActivationInviteNote,
		getNotesCategories,
		notesCategories,
		updateNoteCategory
	}
}
