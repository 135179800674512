import type { MacResponse, MappedMac, DeviceStatusResponse } from '@/components/internal/account/equipment/types'

const mapMacResponse = (response: MacResponse): MappedMac => {
	const { accountMacId, accountId, deviceName, macAddress, lastModified, createdTime } = response

	const mappedMac: MappedMac = {
		macId: accountMacId,
		accountId,
		deviceName,
		macAddress,
		lastModified: formatDate(new Date(lastModified)),
		created: formatDate(new Date(createdTime))
	}

	return mappedMac
}

const addStatusToMac = (mac: MappedMac, deviceStatus: DeviceStatusResponse): MappedMac => {
	return {
		...mac,
		status: deviceStatus?.status || 'unknown',
		uptime: formatTimerForDisplay(deviceStatus?.uptime) || 'unknown',
		lastSeen: formatDate(new Date(deviceStatus?.lastSeen)) || 'unknown'
	}
}

export { mapMacResponse, addStatusToMac }
