<template>
	<svg
		width="19"
		height="19"
		viewBox="0 0 19 19"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M13.1123 10.7919C13.0222 10.7018 12.9264 10.6511 12.7856 10.6511C12.6448 10.6511 12.5547 10.6961 12.459 10.7919L11.9915 11.2086C11.2931 10.5103 10.6905 9.90201 10.1273 9.29938L12.1774 7.24932L13.0616 6.36509C14.2725 6.87761 15.7143 6.596 16.6492 5.66672C17.4884 4.78249 17.815 3.5716 17.4884 2.40577L17.3926 2.07911L15.1567 4.31503L13.2474 2.45082L15.5341 0.220536L15.2074 0.124791C14.0416 -0.201867 12.7856 0.124791 11.9464 0.963965C11.0172 1.89888 10.7356 3.33505 11.2481 4.55157L10.3638 5.4358L8.26872 7.48587L4.12354 3.34069L3.19425 1.80314C3.19425 1.78127 3.18364 1.77002 3.17272 1.75843C3.16113 1.74615 3.1492 1.73348 3.1492 1.70739L1.61165 0.682363C1.56659 0.59225 1.42579 0.637307 1.38074 0.682363L0.682363 1.38074C0.637307 1.42579 0.586618 1.56659 0.682363 1.61165L1.70739 3.1492C1.73743 3.17923 1.76935 3.19425 1.80314 3.19425L3.34069 4.12917C3.6397 4.42726 3.92349 4.71043 4.19395 4.9803C5.56891 6.35224 6.59907 7.38015 7.53092 8.27435L5.4358 10.3695L4.55157 11.2086C3.34069 10.7412 1.89888 11.0228 0.963965 11.9521C0.124791 12.7912 -0.201867 14.0472 0.124791 15.168L0.214903 15.4946L2.49588 13.2587L4.36008 15.1229L2.07911 17.4039L2.40577 17.4996C3.5716 17.8263 4.82754 17.4996 5.66672 16.6154C6.596 15.6861 6.87761 14.2894 6.41015 13.0785L7.24932 12.1943L9.34444 10.0991L11.2537 12.0084L10.7862 12.4252C10.6961 12.5153 10.6454 12.611 10.6454 12.7518C10.6454 12.8926 10.6905 13.0278 10.7862 13.1235L15.5847 17.8713C15.6805 17.9671 15.8157 18.0121 15.9114 18.0121C16.0522 18.0121 16.193 17.9671 16.2831 17.8713L17.9108 16.2437C18.0065 16.1479 18.0516 16.0128 18.0516 15.917C18.0516 15.7762 18.0065 15.6354 17.9108 15.5453L13.1123 10.7975V10.7919ZM16.4633 15.9114L12.7406 12.2337L13.1573 11.862L16.835 15.5397L16.4633 15.9114ZM11.8563 13.1179L15.5791 16.7956L15.9508 16.4239L12.2731 12.7462L11.8563 13.1179Z"
			fill="currentColor"
		/>
	</svg>
</template>
