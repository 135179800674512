import type { AuthResponse, UserData, Address, MappedAddress } from '@/types/auth'

const mapAddress = (address: Address | undefined): MappedAddress => {
	return address
		? {
			addressId: address?.addressId ?? 0,
			streetAddress: address?.address1 ?? '',
			city: address?.city ?? '',
			state: address?.state?.name ?? '',
			stateIso: address?.state?.iso2Code ?? '',
			country: address?.country?.name ?? '',
			countryIso: address?.country?.iso2Code ?? '',
			zip: address?.postalCode,
			coordinates: {
				lat: address?.latitude ?? 0,
				lng: address?.longitude ?? 0
			}
		}
		: {
			addressId: 0,
			streetAddress: '',
			city: '',
			state: '',
			stateIso: '',
			country: '',
			countryIso: '',
			zip: '',
			coordinates: {
				lat: 0,
				lng: 0
			}
		}
}

const mapAuthResponse = (response: AuthResponse): UserData => {
	const { emailId, siteAdmin, isContractor, contact, accounts, roles, permissions, prospectConversionSteps, twoFactorEnabled } = response
	const { contactId, contactType, title, first: firstName, middle: middleName, last: lastName, suffix, dob, address, email, phone } = contact

	const mappedAddress = mapAddress(address)

	return {
		emailId,
		siteAdmin,
		isContractor,
		contact: {
			contactId,
			contactType,
			title,
			firstName,
			middleName,
			lastName,
			suffix,
			dob,
			address: mappedAddress,
			email,
			phone
		},
		accounts: accounts.map(account => ({
			...account,
			address: mapAddress(account.address)
		})),
		roles,
		permissions,
		prospectConversionSteps,
		twoFactorEnabled
	}
}

export { mapAuthResponse }
