import { useStorage, StorageSerializers } from '@vueuse/core'
import { calculateFutureTimestamp } from '@/utils/calculateFutureTimestamp'

export function useLanguageLocalStorage () {
	const setLanguage = (language: string) => {
		localStorage.setItem('language', language)
	}
	const cachedLanguage = useStorage('language', null)

	return {
		setLanguage,
		cachedLanguage
	}
}

export function useContactLocalStorage () {
	const setPhoneNumberToLocalStorage = (phoneNumber: string) => {
		const expirationTime = calculateFutureTimestamp({ seconds: 10 })
		localStorage.setItem('phoneNumber', JSON.stringify({ phoneNumber, expirationTime }))
	}

	const cachedPhoneNumberObject = useStorage('phoneNumber', null, undefined, { serializer: StorageSerializers.object })

	const getPhoneNumberFromLocalStorage = () => {
		return JSON.parse(localStorage?.getItem('phoneNumber') as string)
	}

	const set2faContactToLocalStorage = (contact: string) => {
		const expirationTime = calculateFutureTimestamp({ seconds: 10 })
		localStorage.setItem('2faContact', JSON.stringify({ contact, expirationTime }))
		// #TODO make an abstracted function for all of these, add the removeitem for each
		setTimeout(() => {
			localStorage.setItem('2faContact', JSON.stringify({ contact: '', expirationTime }))
		}, 10000)
	}

	const cached2faContactObject = useStorage('2faContact', null, undefined, { serializer: StorageSerializers.object })

	const get2faContactFromLocalStorage = () => {
		return JSON.parse(localStorage?.getItem('2faContact') as string)
	}

	const setEmailToLocalStorage = (email: string) => {
		const expirationTime = calculateFutureTimestamp({ seconds: 10 })
		localStorage.setItem('email', JSON.stringify({ email, expirationTime }))
	}

	const cachedEmailObject = useStorage('email', null, undefined, { serializer: StorageSerializers.object })

	const getEmailFromLocalStorage = () => {
		return JSON.parse(localStorage?.getItem('email') as string)
	}

	return {
		setPhoneNumberToLocalStorage,
		cachedPhoneNumberObject,
		getPhoneNumberFromLocalStorage,
		set2faContactToLocalStorage,
		cached2faContactObject,
		get2faContactFromLocalStorage,
		setEmailToLocalStorage,
		cachedEmailObject,
		getEmailFromLocalStorage
	}
}

export function useZoneLocalStorage () {
	const setZoneToLocalStorage = (zoneId: string) => {
		localStorage.setItem('zoneId', zoneId)
	}
	const cachedZoneId = useStorage('zoneId', '')

	return {
		setZoneToLocalStorage,
		cachedZoneId
	}
}

export function useNavCollapseLocalStorage () {
	const navCollapsed = useStorage('navCollapse', 'false')
	const toggleCollapsed = () => {
		localStorage.setItem('navCollapse', navCollapsed.value === 'true' ? 'false' : 'true')
		navCollapsed.value = localStorage.getItem('navCollapse') || 'false'
	}

	return {
		toggleCollapsed,
		navCollapsed
	}
}
