<template>
	<div class="flex items-center text-base font-semibold">
		<label
			:for="id"
			class="flex items-center cursor-pointer group"
		>
			<span
				v-if="label"
				:class="[
					labelColor, 'pr-2'
				]"
			>
				{{ label }}
			</span>
			<div
				:class="[
					'p-0.5 w-12 h-6 rounded-full shadow-inner transition-colors duration-100',
					isChecked ? 'bg-mx-orange' : trackColor
				]"
			>
				<div class="relative inline-block w-full h-full">
					<input
						:id="id"
						v-model="isChecked"
						type="checkbox"
						class="sr-only"
						:disabled="disableSwitch || readOnly"
						@click="$emit('handle-click')"
					>
					<div
						:class="[
							{ 'translate-x-full': isChecked },
							'absolute top-0 left-0 w-1/2 h-full rounded-full aspect-square shrink-0 grow-0 shadow transform transition-transform duration-200'
						]"
					>
						<div
							:class="[
								buttonColor,
								'inset-0 flex items-center justify-center w-full h-full transition-colors duration-100 rounded-full'
							]"
						>
							<IconCheck2
								:class="[
									isChecked ? 'opacity-100' : 'opacity-0',
									'duration-200 transition-opacity text-mx-gray-400'
								]"
							/>
						</div>
					</div>
				</div>
			</div>
		</label>
	</div>
</template>

<script setup lang="ts">
const props = defineProps({
	id: {
		type: String,
		required: true
	},
	label: {
		type: String,
		default: ''
	},
	labelColor: {
		type: String,
		default: 'text-mx-gray-500 dark:text-mx-gray-300'
	},
	buttonColor: {
		type: String,
		default: 'bg-white dark:bg-mx-gray-200 group-hover:bg-mx-gray-50 dark:group-hover:bg-white'
	},
	trackColor: {
		type: String,
		default: 'bg-mx-gray-100 dark:bg-mx-green-900 hover:bg-mx-gray-200 dark:hover:bg-mx-green-900/80'
	},
	debounceTime: {
		type: Number,
		default: 0
	},
	readOnly: {
		type: Boolean,
		default: false
	}
})

const isChecked = defineModel({ type: Boolean })
defineEmits([ 'handle-click' ])
const { debounceTime } = toRefs(props)
const disableSwitch = ref(false)

watch(isChecked, () => {
	if (debounceTime.value) {
		disableSwitch.value = true
		setTimeout(() => {
			disableSwitch.value = false
		}, debounceTime.value)
	}
}, { immediate: true })
</script>
