<template>
	<div class="relative w-full h-full overflow-y-hidden">
		<div class="absolute top-0 right-0 z-[90] flex h-full w-full">
			<BatchActionWorkflowSidebar
				:steps="steps"
				:current-step="currentStepId"
				:completed-steps="completedSteps"
				@set-step="handleSetStep($event)"
			/>
			<div class="flex justify-end w-[85%] h-screen dark:text-mx-gray-300">
				<div class="order-first w-full px-6 overflow-y-auto rounded-l-lg shadow-lg lg:px-8 bg-mx-gray-100 dark:bg-mx-green-900 border-mx-gray-200 dark:border-mx-gray-500">
					<component
						:is="currentComponent"
						v-bind="componentProps"
						@close="handleClose"
						@cancel="handleClose"
						@save="handleSave($event)"
						@submit="handleSubmit"
						@reset="handleReset"
						@selection-change="selectedItems = $event"
					/>
				</div>
				<button
					class="absolute top-0 left-0 p-1 text-white bg-mx-orange z-[90]"
					@click="handleClose"
				>
					<XCircleIcon
						class="w-8 h-8"
						aria-hidden="true"
					/>
				</button>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import type { BatchActionForm, BatchActionConfig, BatchActionTableData, ActionWorkflowStep, TableRow } from '@/components/table/types'
import { XCircleIcon } from '@heroicons/vue/20/solid'
import { ACCOUNTS_BATCH_ACTION_COMPONENT_NAMES } from '@/constants/accounts/table'

const props = defineProps({
	batchActionTableData: {
		type: Object as PropType<BatchActionTableData>,
		required: true
	},
	defaultColumns: {
		type: Array,
		required: true
	},
	openBatchActionWorkflow: {
		type: Boolean,
		required: true
	},
	actions: {
		type: Array as PropType<BatchActionConfig[]>,
		required: true
	},
	steps: {
		type: Array as PropType<ActionWorkflowStep[]>,
		required: true
	},
	stepComponents: {
		type: Object as PropType<{[key: number]: any}>,
		required: true
	},
	loading: {
		type: Boolean,
		default: false
	},
	actionCompleted: {
		type: Boolean,
		default: false
	},
	actionFailed: {
		type: Boolean,
		default: false
	},
	skippedAccounts: {
		type: Array as PropType<string[]>,
		default: () => []
	}
})

const emit = defineEmits([ 'close', 'save', 'submit', 'reset' ])

const {
	openBatchActionWorkflow,
	batchActionTableData,
	defaultColumns,
	steps,
	stepComponents,
	actions
} = toRefs(props)

const openWorkflow = ref(openBatchActionWorkflow.value)
const currentStepId = ref<number>(1)
const completedSteps = ref([ 0 ])
const selectedItems = ref<TableRow | null>(null)
const formData = ref<BatchActionForm>({
	actionOption: undefined,
	selectedItems: []
})

const handleNextStep = () => {
	emit('save', formData.value)
	currentStepId.value += 1
}

const handleSetStep = (stepId: number) => {
	currentStepId.value = stepId
}

const handleSave = (data: BatchActionForm) => {
	// Save the action data from the current step
	if (data.actionOption) {
		formData.value.actionOption = data.actionOption
	}
	// Save the selections
	if (data.selectedItems) {
		formData.value.selectedItems = data.selectedItems
	}
	completedSteps.value = [ ...completedSteps.value, currentStepId.value ]
	handleNextStep()
}

const handleClose = () => {
	openWorkflow.value = false
	emit('close')
}

const handleSubmit = () => {
	emit('submit', formData.value) // submit data and refetch table data in parent
}

const handleReset = () => {
	formData.value = {
		actionOption: undefined,
		selectedItems: []
	}
	completedSteps.value = [ 0 ]
	handleSetStep(1)
	selectedItems.value = null
	emit('reset')
}

const getStepName = (stepId: number) => {
	return steps.value.find(step => step.id === stepId)?.componentName
}

const currentComponent = computed(() => {
	return stepComponents.value[currentStepId.value]
})

const currentActionOptions = computed(() => {
	return actions.value.find(action => action.id === batchActionTableData.value.action.id)?.actionOptions
})

const componentProps = computed(() => {
	switch (getStepName(currentStepId.value)) {
	case ACCOUNTS_BATCH_ACTION_COMPONENT_NAMES.SEND_SCHEDULING_MESSAGE.CHOOSE_ACCOUNTS:
		return {
			tableData: batchActionTableData.value.items,
			defaultColumns: defaultColumns.value,
			action: batchActionTableData.value.action,
			stepNumber: currentStepId.value,
			totalSteps: Object.keys(stepComponents.value).length
		}
	case ACCOUNTS_BATCH_ACTION_COMPONENT_NAMES.SEND_SCHEDULING_MESSAGE.CHOOSE_ACTION:
		return {
			actionOptions: currentActionOptions.value,
			stepNumber: currentStepId.value,
			totalSteps: Object.keys(stepComponents.value).length,
			selectedItemsCount: formData.value.selectedItems?.length || 0 // pass the selected items to the choose action step for display
		}
	case ACCOUNTS_BATCH_ACTION_COMPONENT_NAMES.SEND_SCHEDULING_MESSAGE.CONFIRMATION:
		return {
			tableData: formData.value.selectedItems, // only show selected items in confirmation step
			defaultColumns: defaultColumns.value,
			action: batchActionTableData.value.action,
			activeActionOption: formData.value.actionOption, // pass the selected action option to confirmation step for display
			stepNumber: currentStepId.value,
			totalSteps: Object.keys(stepComponents.value).length
		}
	default:
		return {
			stepNumber: currentStepId.value,
			totalSteps: Object.keys(stepComponents.value).length
		}
	}
})
</script>
