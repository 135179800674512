<template>
	<div :class="containerClass">
		<div
			v-show="!hideComponentA"
			:id="`${id}-component-a`"
			ref="componentA"
			:class="[ componentAClass, componentClass ]"
			:style="componentAStyle"
		>
			<slot
				name="componentA"
				:component-a-height="componentAHeight"
				:component-a-width="componentAWidth"
			>
				<div class="h-full bg-green-500">
					Component A
				</div>
			</slot>
		</div>
		<div
			v-show="!loading && !hideComponentA && !hideComponentB"
			class="relative z-50 bg-mx-gray-100 dark:bg-mx-green-900 group"
			:class="[
				resizeHandleClass,
				'transition-all duration-100 ease-in-out',
			]"
			@mousedown="startClickDrag"
			@mouseup="grabbing = false"
			@touchstart="startTouchDrag"
		>
			<div class="flex items-center justify-center w-full h-full bg-mx-gray-300 dark:bg-mx-gray-500">
				<div
					:class="[
						isHorizontal ? 'transform rotate-90' : '',
						'absolute px-4 -translate-x-1/2 -translate-y-1/2 text-mx-gray-500 group-hover:text-black dark:text-mx-gray-300 dark:group-hover:text-mx-gray-100 top-1/2 left-1/2'
					]"
				>
					<IconTriangleArrowUp class="w-4 h-4" />
					<IconTriangleArrowUp class="w-4 h-4 transform rotate-180" />
				</div>
			</div>
		</div>
		<div
			v-show="!hideComponentB"
			:id="`${id}-component-b`"
			ref="componentB"
			:class="[ componentBClass, componentClass ]"
			:style="componentBStyle"
		>
			<slot
				name="componentB"
				:component-b-height="componentBHeight"
				:component-b-width="componentBWidth"
			>
				<div class="h-full bg-blue-500">
					Component B
				</div>
			</slot>
		</div>
	</div>
</template>

<script setup lang="ts">
type Direction = 'vertical' | 'horizontal'

const props = defineProps({
	id: {
		type: String,
		required: true
	},
	direction: {
		type: String as PropType<Direction>,
		default: 'vertical'
	},
	componentAClass: {
		type: String,
		default: ''
	},
	componentBClass: {
		type: String,
		default: ''
	},
	loading: {
		type: Boolean,
		default: false
	},
	scrollbarOffset: {
		type: Number,
		default: 16
	},
	parentId: {
		type: String,
		default: ''
	},
	hideComponentA: {
		type: Boolean,
		default: false
	},
	hideComponentB: {
		type: Boolean,
		default: false
	}
})

const { scrollbarOffset, parentId, direction, hideComponentA, hideComponentB } = toRefs(props)

const totalPeers = computed((): number => {
	if (hideComponentA.value || hideComponentB.value) {
		return 1
	}
	// get parent element
	if (parentId.value) {
		const parent = document.getElementById(parentId.value)
		if (parent) {
			return parent.children.length
		}
	}
	return 2
})

const grabbing = ref(false)

const componentAHeight = ref(0)
const componentBHeight = ref(0)
const componentAWidth = ref(0)
const componentBWidth = ref(0)

const componentA = ref<HTMLDivElement | null>(null)
const componentB = ref<HTMLDivElement | null>(null)

const isHorizontal = computed(() => direction.value === 'horizontal')

const containerClass = computed(() =>
	direction.value === 'vertical' ? 'h-full flex flex-col' : 'h-full w-full flex flex-row'
)
const componentClass = computed(() =>
	direction.value === 'vertical' ? 'overflow-auto' : 'overflow-auto'
)
const resizeHandleClass = computed(() =>
	direction.value === 'vertical' ? 'h-1 bg-gray-300 cursor-row-resize' : 'w-1 bg-gray-300 cursor-col-resize'
)
const componentAStyle = computed(() => {
	if (hideComponentA.value) {
		return { display: 'none' }
	}
	return direction.value === 'vertical' ? { height: `${componentAHeight.value}px` } : { width: `${componentAWidth.value}px` }
})
const componentBStyle = computed(() => {
	if (hideComponentB.value) {
		return { display: 'none' }
	}
	return direction.value === 'vertical' ? { height: `${componentBHeight.value}px` } : { width: `${componentBWidth.value}px` }
})
const startClickDrag = (e: MouseEvent) => {
	const startPosition = direction.value === 'vertical' ? e.clientY : e.clientX
	const startSizeA = direction.value === 'vertical' ? componentA.value?.clientHeight : componentA.value?.clientWidth
	const startSizeB = direction.value === 'vertical' ? componentB.value?.clientHeight : componentB.value?.clientWidth

	const onDrag = (e: MouseEvent) => {
		// prevent text selection while dragging
		componentA.value?.classList.add('select-none')
		componentB.value?.classList.add('select-none')

		const delta = direction.value === 'vertical' ? e.clientY - startPosition : e.clientX - startPosition
		const newSizeA = (startSizeA || 0) + delta
		const newSizeB = (startSizeB || 0) - delta

		if (direction.value === 'vertical') {
			componentAHeight.value = newSizeA
			componentBHeight.value = newSizeB
		} else {
			// if either component scrollHeight is taller than the screen, add offset for the scrollbar of 16
			componentAWidth.value = (componentA.value?.scrollHeight || 0) > window.innerHeight ? newSizeA + scrollbarOffset.value : newSizeA
			componentBWidth.value = (componentB.value?.scrollHeight || 0) > window.innerHeight ? newSizeB + scrollbarOffset.value : newSizeB
		}
	}

	const stopDrag = () => {
		componentA.value?.classList.remove('select-none')
		componentB.value?.classList.remove('select-none')
		window.removeEventListener('mousemove', onDrag)
		window.removeEventListener('mouseup', stopDrag)
	}

	window.addEventListener('mousemove', onDrag)
	window.addEventListener('mouseup', stopDrag)
}

const startTouchDrag = (e: TouchEvent) => {
	const startPosition = direction.value === 'vertical' ? e.touches[0].clientY : e.touches[0].clientX
	const startSizeA = direction.value === 'vertical' ? componentA.value?.clientHeight : componentA.value?.clientWidth
	const startSizeB = direction.value === 'vertical' ? componentB.value?.clientHeight : componentB.value?.clientWidth

	const onDrag = (e: TouchEvent) => {
		// prevent text selection while dragging
		componentA.value?.classList.add('select-none')
		componentB.value?.classList.add('select-none')

		const delta = direction.value === 'vertical' ? e.touches[0].clientY - startPosition : e.touches[0].clientX - startPosition
		const newSizeA = (startSizeA || 0) + delta
		const newSizeB = (startSizeB || 0) - delta

		if (direction.value === 'vertical') {
			componentAHeight.value = newSizeA
			componentBHeight.value = newSizeB
		} else {
			// if either component scrollHeight is taller than the screen, add offset for the scrollbar of 16
			componentAWidth.value = (componentA.value?.scrollHeight || 0) > window.innerHeight ? newSizeA + scrollbarOffset.value : newSizeA
			componentBWidth.value = (componentB.value?.scrollHeight || 0) > window.innerHeight ? newSizeB + scrollbarOffset.value : newSizeB
		}
	}

	const stopDrag = () => {
		componentA.value?.classList.remove('select-none')
		componentB.value?.classList.remove('select-none')
		window.removeEventListener('touchmove', onDrag)
		window.removeEventListener('touchend', stopDrag)
	}

	window.addEventListener('touchmove', onDrag)
	window.addEventListener('touchend', stopDrag)
}

const getDimensions = () => {
	const totalSize = direction.value === 'vertical' ? window.innerHeight : window.innerWidth
	if (direction.value === 'vertical') {
		componentAHeight.value = hideComponentA.value ? 0 : totalSize / totalPeers.value
		componentBHeight.value = hideComponentB.value ? 0 : totalSize / totalPeers.value
	} else {
		componentAHeight.value = window.innerHeight
		componentBHeight.value = window.innerHeight
		componentAWidth.value = hideComponentA.value ? 0 : totalSize / totalPeers.value
		componentBWidth.value = hideComponentB.value ? 0 : totalSize / totalPeers.value
	}
}

watch(() => direction.value, () => {
	getDimensions()
})

watch([ hideComponentA, hideComponentB ], () => {
	getDimensions()
})

onMounted(() => {
	getDimensions()
})
</script>
