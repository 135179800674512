<template>
	<div
		class="z-30 flex flex-col h-screen px-6 overflow-y-auto transition-all bg-white dark:bg-mx-green-800 grow gap-y-5"
		:class="sideBarCollapsed ? 'w-[70px] overflow-x-hidden' : 'w-60'"
	>
		<div class="h-16">
			<ClientOnly>
				<BrandingLogo
					:class="[
						'h-16 my-4 -ml-2.5 transition-all',
						sideBarCollapsed ? 'w-36' : 'w-48'
					]"
					:show-text="!sideBarCollapsed"
				/>
			</ClientOnly>
		</div>
		<ClientOnly>
			<NavListSection
				v-for="section in navigation"
				:key="section.title"
				:nav-section="section"
				:show-text="!sideBarCollapsed"
			/>
		</ClientOnly>
		<NavLanguageToggle
			v-if="isAccountPortal"
			:show-text="!sideBarCollapsed"
		/>
		<NavColorModeToggle :show-text="!sideBarCollapsed" />
		<button
			v-if="!isAccountPortal"
			class="absolute right-0 text-white transform lg:-translate-y-1/2 lg:top-1/2 bottom-16 lg:bottom-1/2"
			@click="toggleCollapsed"
		>
			<ChevronRightIcon
				:class="[
					'w-8 h-8 text-mx-gray-500 dark:text-mx-gray-400 hover:bg-mx-gray-100 dark:hover:bg-mx-green-600 dark:hover:text-mx-gray-300',
					sideBarCollapsed ? 'rounded-l-md' : 'rounded-r-md transform rotate-180'
				]"
			/>
		</button>
	</div>
</template>

<script setup lang="ts">
import { isAccountPortal } from '@/composables/useIsAccountPortal'
import { getNavLinks } from '@/composables/getNavLinks'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'
import { useNavCollapseLocalStorage } from '@/composables/useLocalStorage'

const { navigation } = getNavLinks()
const { navCollapsed, toggleCollapsed } = useNavCollapseLocalStorage()

const collapsed = defineModel({ type: Boolean, default: false })

const sideBarCollapsed = computed(() => collapsed.value && !isAccountPortal.value)

watch(() => navCollapsed.value, () => {
	collapsed.value = navCollapsed.value === 'true'
}, { immediate: true })

</script>
