import { defineNuxtPlugin } from '#app'
import { nextTick } from 'vue'
import type { DirectiveBinding, ObjectDirective } from 'vue'
import type { TooltipOptions } from '@/types/plugins'

// Extend HTMLElement to include a _tooltipCleanup property
interface TargetElement extends HTMLElement {
	_tooltipCleanup?: () => void
}

const DEFAULT_CLASSES = 'bg-mx-gray-50 text-black dark:text-mx-gray-300 dark:bg-mx-green-900 p-2 rounded-md shadow-lg text-sm'
const VISIBILITY_CLASSES = 'opacity-0 transition-opacity'

/**
 * A Vue directive that displays a tooltip with customizable content and styles.
 * Add tooltips to elements by applying `v-tooltip` with an options object.
 *
 * ### Usage
 * Basic usage with text content:
 * ```html
 * <span v-tooltip="{ content: 'Hello, World!' }">Hover me</span>
 * ```
 *
 * Advanced usage with an HTML element as content:
 * ```html
 * <span v-tooltip="{ content: customElement }">Hover me</span>
 * ```
 * ```script
 * const customElement: HTMLElement = document.createElement('strong')
 * customElement.appendChild(document.createTextNode('Hover me'))
 * ```
 *
 * Customizing tooltip styles with Tailwind classes:
 * ```html
 * <span v-tooltip="{ content: 'Custom styled tooltip', classes: 'font-semibold italicize border border-blue-500' }">Hover me</span>
 * ```
 *
 * Overriding default styles with custom classes:
 * ```html
 * <span v-tooltip="{ content: 'Custom styled tooltip', classes: 'bg-blue-500 text-white p-3 rounded-sm shadow-sm', overrideStyles: true }">Hover me</span>
 * ```
 */
export default defineNuxtPlugin((nuxtApp) => {
	const vTooltip: ObjectDirective = {
		async beforeMount (el: TargetElement, binding: DirectiveBinding<TooltipOptions>) {
			// Wait until the next DOM update cycle to ensure content is ready
			await nextTick()

			const options = binding.value

			// Check if content is available
			if (!options?.content) { return }

			// Create a new tooltip element
			const tooltipEl = document.createElement('div')

			// Set tooltip content: use innerText for strings, appendChild for HTMLElements
			if (typeof options.content === 'string') {
				tooltipEl.innerText = options.content
			} else if (options.content instanceof HTMLElement) {
				tooltipEl.appendChild(options.content)
			} else {
				return
			}

			// Apply default classes or override with user-provided classes
			const providedClasses = options?.classes || ''
			const shouldOverrideStyles = options?.overrideStyles && !!providedClasses.length
			const customClasses = `${VISIBILITY_CLASSES} ${providedClasses}`
			const defaultClasses = `${VISIBILITY_CLASSES} ${DEFAULT_CLASSES}`

			// Combine classes based on override status
			const tooltipClasses = shouldOverrideStyles ? customClasses : `${defaultClasses} ${providedClasses}`

			// Set tooltip element classes
			tooltipEl.className = tooltipClasses

			// Apply additional styles for positioning and z-index
			Object.assign(tooltipEl.style, {
				position: 'absolute',
				whiteSpace: 'nowrap',
				pointerEvents: 'none',
				top: '0',
				left: '0',
				zIndex: '1000'
			})

			// Append tooltip element to body
			document.body.appendChild(tooltipEl)

			// Show tooltip on mouse enter
			const onMouseEnter = (event: MouseEvent) => {
				tooltipEl.classList.remove('opacity-0')
				tooltipEl.classList.add('opacity-100')
				updateTooltipPosition(event)
			}

			// Hide tooltip on mouse leave
			const onMouseLeave = () => {
				tooltipEl.classList.remove('opacity-100')
				tooltipEl.classList.add('opacity-0')
			}

			// Update tooltip position on mouse move with boundary detection
			const updateTooltipPosition = (event: MouseEvent) => {
				const tooltipRect = tooltipEl.getBoundingClientRect()
				const viewportWidth = window.innerWidth
				const viewportHeight = window.innerHeight

				// Set initial position (defult is left of cursor)
				let top = event.pageY - 10
				let left = event.pageX + 15

				// Check if tooltip is close to the right edge
				if (left + tooltipRect.width > viewportWidth) {
					left = event.pageX - tooltipRect.width - 8
				}

				// Check if tooltip is close to the bottom edge
				if (top + tooltipRect.height > viewportHeight) {
					top = event.pageY - tooltipRect.height - 10
				}

				// Apply the calculated position
				tooltipEl.style.top = `${top}px`
				tooltipEl.style.left = `${left}px`
			}

			// Attach event listeners with explicit types
			el.addEventListener('mouseenter', onMouseEnter)
			el.addEventListener('mouseleave', onMouseLeave)
			el.addEventListener('mousemove', updateTooltipPosition)

			// Cleanup on unmount
			el._tooltipCleanup = () => {
				el.removeEventListener('mouseenter', onMouseEnter)
				el.removeEventListener('mouseleave', onMouseLeave)
				el.removeEventListener('mousemove', updateTooltipPosition)
				document.body.removeChild(tooltipEl)
			}
		},

		beforeUnmount (el: TargetElement) {
			// Run cleanup if it exists
			el._tooltipCleanup?.()
		}
	}

	// Register the directive globally
	nuxtApp.vueApp.directive('tooltip', vTooltip)
})
