<template>
	<div>
		<Header
			id="header"
			class="z-20"
		/>
		<SearchBar />
		<NavSideBar
			id="nav"
			v-model="sideBarCollapsed"
			class="fixed top-0 hidden h-full w-60 md:block"
		/>
		<div
			class="relative z-10 h-auto min-h-screen pt-16 pb-0 transition-all lg:pt-0 bg-mx-gray-100 dark:bg-mx-green-900 md:ml-60"
			:class="sideBarCollapsed ? 'md:ml-[70px]' : 'md:ml-60'"
		>
			<div
				v-if="showAlertBanner"
				class="pt-4"
			>
				<LazyAlertBanner
					:alert="alert"
				/>
			</div>
			<div :class="[ showAlertBanner ? '-mt-4': 'mt-0' ]">
				<slot name="title" />
				<slot />
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { useShowAlert } from '@/composables/useState'
import type { AlertProps } from '@/types/alert'
import { isAlertResponseValid } from '@/types/alert'
import MyWorker from '@/assets/workers/alertWorker?worker'
import { useRoute } from 'vue-router'

const route = useRoute()

const showAlert = useShowAlert()

const worker = ref<Worker | null>(null)

const alert = ref<AlertProps>({ title: '', message: '', active: false })

const sideBarCollapsed = ref(false)

const checkForAlerts = () => {
	worker.value = new MyWorker()
	worker.value.onmessage = (res) => {
		if (!res.data?.length) {
			alert.value.title = ''
			alert.value.message = ''
			alert.value.active = false
			showAlert.value = false
			return
		}
		if (res?.data?.isError) {
			reportError(res?.data?.error)
			return
		}
		if (isAlertResponseValid(res?.data?.[0])) {
			reportError('Invalid data returned from alerts endpoint')
			return
		}
		const { title, message, active } = res.data[0]
		alert.value.title = title || ''
		alert.value.message = message || ''
		showAlert.value = active ?? false
	}
}

const showAlertBanner = computed(() => {
	return showAlert.value && (route.name === 'account' || route.name === 'account-contact')
})

onMounted(() => {
	checkForAlerts()
	document.addEventListener('visibilitychange', () => {
		if (document.visibilityState === 'visible' && !worker.value) {
			checkForAlerts()
		} else {
			worker.value?.terminate()
			worker.value = null
		}
	})
})

</script>
