<template>
	<label
		:for="id"
		class="relative flex items-center w-full px-2 space-x-2 cursor-pointer group dark:text-mx-gray-300"
	>
		<div class="relative flex items-center shrink-0">
			<input
				:id="id"
				v-model="checkedValue"
				type="radio"
				:value="option.value"
				:tabindex="tabindex"
				class="transition-all duration-100 bg-mx-gray-200 dark:border-2 border-mx-gray-500 appearance-none h-4 w-4 rounded-full cursor-pointer group-has-[:checked]:bg-mx-orange group-has-[:checked]:border-mx-orange text-mx-orange ring-mx-orange focus:ring-mx-orange hover:bg-mx-gray-300 dark:hover:bg-mx-gray-500/20 dark:bg-mx-green-900 group-has-[:checked]:dark:bg-mx-orange"
			>
			<div class="absolute left-[5px] top-[5px] w-1.5 h-1.5 bg-transparent group-has-[:checked]:bg-white dark:group-has-[:checked]:bg-mx-green-900 rounded-full transform transition-transform duration-100" />
		</div>
		<span class="pt-1">
			{{ option.label }}
		</span>
	</label>
</template>

<script setup lang="ts">
const props = defineProps({
	option: {
		type: Object as PropType<{ id: string | number; value: string | number; label: string }>,
		required: true
	},
	tabindex: {
		type: Number,
		default: 0
	},
	modelValue: {
		type: String,
		required: true
	}
})

const { option, modelValue } = toRefs(props)

const checkedValue = ref('')

const id = computed(() => option.value?.id?.toString())

const emit = defineEmits([ 'update:modelValue' ])

watch(checkedValue, (value) => {
	emit('update:modelValue', value)
})

watch(modelValue, (value) => {
	checkedValue.value = value
}, { immediate: true })
</script>
