<template>
	<div>
		<div class="px-4 pb-6 bg-white dark:bg-mx-green-800 sm:hidden">
			<label
				for="tabs"
				class="sr-only"
			>
				Select a tab
			</label>
			<select
				id="tabs"
				name="tabs"
				class="block w-full px-3 py-2 overflow-auto text-base bg-white border rounded-md dark:border-mx-green-600 border-mx-gray-100 dark:text-mx-gray-300 focus:outline-none focus:ring-0 dark:bg-mx-green-700 dark:shadow-white/5 sm:text-sm"
				@change="handleMobileNavigation"
			>
				<option
					v-for="tab in tabs"
					:key="tab.name"
					:selected="tab.current"
					:value="tab.href"
					class="shadow-lg"
				>
					{{ tab.name }}
				</option>
			</select>
		</div>
		<div
			id="account-sub-nav"
			class="hidden sm:block"
		>
			<div class="px-8 pt-8">
				<nav
					class="flex space-x-2"
					aria-label="Tabs"
				>
					<template v-if="staticPage">
						<button
							v-for="tab in tabs"
							:key="tab.name"
							:class="[
								isBillingTabActive(tab) ? 'bg-white text-black shadow-sm dark:bg-mx-green-700 dark:hover:bg-mx-green-600 dark:text-white' : 'text-mx-gray-500 dark:text-mx-gray-500 hover:text-black shadow-inner bg-mx-gray-200 dark:bg-mx-green-800 dark:hover:bg-mx-green-800',
								'whitespace-nowrap py-2 px-4 text-sm font-bold rounded-lg transition-all duration-100 ease-in-out'
							]"
							@click="handleStaticNavigation(tab)"
						>
							<div class="flex items-center">
								<ExclamationTriangleIcon
									v-if="showAlertIcon(tab.name)"
									class="h-5 pr-1 text-mx-orange animate-ping-twice"
								/>
								{{ tab.name }}
							</div>
						</button>
					</template>
					<template v-else>
						<NuxtLink
							v-for="tab in tabs"
							:key="tab.name"
							:to="tab.href"
							:class="[
								tab.current ? 'bg-white text-black shadow-sm dark:bg-mx-green-700 dark:hover:bg-mx-green-600 dark:text-white' : 'text-mx-gray-500 dark:text-mx-gray-500 hover:text-black shadow-inner bg-mx-gray-200 dark:bg-mx-green-800 dark:hover:bg-mx-green-800',
								'whitespace-nowrap py-2 px-4 text-sm font-bold rounded-lg transition-all duration-100 ease-in-out'
							]"
							:aria-current="tab.current ? 'page' : undefined"
						>
							<div class="flex items-center">
								<ExclamationTriangleIcon
									v-if="showAlertIcon(tab.name)"
									class="h-5 pr-1 text-mx-orange animate-ping-twice"
								/>
								{{ tab.name }}
							</div>
						</NuxtLink>
					</template>
				</nav>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import { useTicketing } from '@/composables/useTicketing'
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'
import { useStaticAccountProfile } from '@/composables/useStaticAccountProfile'

interface Tab { // TODO: move to types file
	id: string
  name: string
  href: string
  route?: string
	current?: boolean
  isDefault?: boolean
}

const { isBillingTabActive, setActiveBillingTab } = useStaticAccountProfile()

const props = defineProps({
	accountId: {
		type: String,
		default: ''
	},
	staticPage: {
		type: Boolean,
		default: false
	}
})

const { staticPage, accountId } = toRefs(props)

const route = useRoute()
const router = useRouter()
const { hasOpenTickets, fetchTicketsOnAccount } = useTicketing()

const nonLocalRouteName = computed(() => route?.name?.toString())
const isActiveRoute = (routeName: string) => {
	return nonLocalRouteName.value?.includes(routeName) && routeName.length >= nonLocalRouteName.value?.length
}

const computedAccountId = computed(() => {
	return staticPage.value ? accountId.value : route.params?.accountId?.toString()
})

const tabs = computed(() => {
	return [
		{ id: 'invoices', name: 'Invoices', href: `/accounts/${computedAccountId.value}/billing`, current: isActiveRoute('internal-accounts-accountId-billing') },
		{ id: 'transactions', name: 'Transactions', href: `/accounts/${computedAccountId.value}/billing/transactions`, current: isActiveRoute('internal-accounts-accountId-billing-transactions') },
		{ id: 'services', name: 'Services & Packages', href: `/accounts/${computedAccountId.value}/billing/services`, current: isActiveRoute('internal-accounts-accountId-billing-services') },
		{ id: 'payment-methods', name: 'Payment Methods', href: `/accounts/${computedAccountId.value}/billing/payment-methods`, current: isActiveRoute('internal-accounts-accountId-billing-payment-methods') }
	]
})

const handleStaticNavigation = (tab: Tab) => {
	setActiveBillingTab(tab)
}

const handleMobileNavigation = (payload: Event) => {
	if (staticPage.value) {
		const foundTab = tabs.value.find(t => t.href === (payload.target as HTMLSelectElement)?.value)
		if (!foundTab) { return }
		setActiveBillingTab(foundTab) // TODO: Fix this type
		return
	}
	const target = payload.target as HTMLSelectElement
	router.push(target?.value || '')
}

const showAlertIcon = (tabName: string) => {
	return tabName === 'Ticketing' && hasOpenTickets.value
}

onMounted(() => {
	fetchTicketsOnAccount(computedAccountId.value)
})
</script>
