<template>
	<TableJsSorting
		id="accounts-batch-action-table"
		:columns="ACCOUNTS_TABLE_COLUMNS"
		:default-columns="defaultColumns"
		:table-data="tableData"
		:show-empty-state="!tableData?.length"
		empty-state-title="No Accounts Found"
		:total-count="tableData.length"
		:per-page="50"
		:per-page-options="[10, 25, 50, 100, 500]"
		table-controls
		:batch-select="batchSelect"
		hide-header
		class="py-8"
		@selection-change="selectedRows = $event"
	>
		<template #outerTopLeftControls>
			<slot
				name="default"
				:selected-rows="selectedRows"
			/>
		</template>
	</TableJsSorting>
</template>

<script setup lang="ts">
import type { TableRow, TableColumn, BatchAction } from '@/components/table/types'
import { ACCOUNTS_TABLE_COLUMNS } from '@/constants/accounts/table'

defineProps({
	tableData: {
		type: Object as PropType<TableRow[]>,
		required: true
	},
	defaultColumns: {
		type: Array as PropType<TableColumn[]>,
		required: true
	},
	action: {
		type: Object as PropType<BatchAction>,
		required: true
	},
	batchSelect: {
		type: Boolean,
		default: true
	}
})

const selectedRows = ref<TableRow[]>([])

const emit = defineEmits([ 'close', 'save', 'selection-change' ])

watch(selectedRows, () => {
	emit('selection-change', selectedRows.value)
}, { deep: true })
</script>
