import {
	API_BASE,
	API_ENDPOINT_PORTAL_SYSTEM_EMAIL_SEND
} from '@/constants/index.js'
import { useErrorReporter } from '@/composables/useErrorReporter'

export function useSystemEmailSend () {
	const { bugsnagReport } = useErrorReporter()
	const sendSystemEmail = async (data: { to: string, from: string, subject: string, body: string }) => {
		await $fetch(`${API_BASE}${API_ENDPOINT_PORTAL_SYSTEM_EMAIL_SEND}`, {
			method: 'POST',
			credentials: 'include',
			body: data,
			onResponse ({ response }) {
				if (!response.ok) {
					bugsnagReport({
						error: new Error(`sendSystemEmail: Failed with status ${response.status}`),
						showToast: true,
						metaData: response._data?.errors
					})
				}
			}
		})
	}

	return {
		sendSystemEmail
	}
}
