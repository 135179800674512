import {
	API_BASE,
	API_ENDPOINT_PORTAL_PERMISSIONS
} from '@/constants/index.js'
import { ref, computed } from 'vue'
import type { PermissionUserResponse, Option, TicketAssignee } from '@/types/permissions'
import { sortAssigneeNames } from '@/utils/sortMethods'

const permissableUsers = ref<PermissionUserResponse[]>([])
const accountServiceRepOptions = computed<Option[]>(() => {
	const users = permissableUsers.value?.map((user: PermissionUserResponse) => {
		return {
			id: user?.contact?.contactId?.toString(),
			name: `${user?.contact?.first} ${user?.contact?.last}`.trim() // remove leading spaces from names with no first name
		}
	})
	return sortAssigneeNames(users)
})

const ticketAssignees = computed<TicketAssignee[]>(() => {
	return permissableUsers.value?.map((user: PermissionUserResponse) => {
		return {
			id: user?.contact?.contactId?.toString(),
			firstName: user?.contact?.first,
			lastName: user?.contact?.last
		}
	}) || []
})

export function usePermissions () {
	const fetchPermissions = async (permissionName: string, refetch?: boolean) => {
		if (permissableUsers.value.length && !refetch) {
			return
		}
		await $fetch(`${API_BASE}/${API_ENDPOINT_PORTAL_PERMISSIONS.replace('{permission-name}', permissionName)}`, {
			method: 'GET',
			credentials: 'include',
			onResponse ({ response }) {
				if (!response?.ok) {
					throw new Error(response._data?.error || response._data?.errors[0])
				}
				if (permissionName === 'notes.create') {
					permissableUsers.value = response._data
				}
			}
		})
	}
	return {
		fetchPermissions,
		permissableUsers,
		accountServiceRepOptions,
		ticketAssignees
	}
}
