<template>
	<div class="relative flex w-full gap-2 flex-nowrap">
		<ClientOnly>
			<div class="relative w-full">
				<input
					ref="searchInputElement"
					v-model="searchQueryValue"
					type="text"
					:class="[
						isDropdownOpen ? 'rounded-t-lg' : 'rounded-lg',
						minWidth
					]"
					class="w-full h-full px-4 py-2 text-sm font-normal leading-tight shadow-inner outline-none text-mx-gray-500 placeholder-mx-gray-400 focus:ring-transparent bg-mx-gray-50 dark:bg-mx-green-800 dark:border-mx-green-900 dark:text-mx-gray-300 dark:placeholder-mx-gray-300"
					:placeholder="placeholder"
					@focus="searchFocused"
					@blur="handleBlur"
					@keydown.enter.prevent="handleSearchEnterPress"
					@keydown.down.prevent="focusNextOption"
					@keydown.up.prevent="focusPreviousOption"
				>
				<div
					v-if="!searchQueryValue && !searchQueryOptionKey"
					class="absolute inset-y-0 right-0 flex items-center pr-3"
				>
					<MagnifyingGlassIcon
						class="w-5 h-5 text-mx-gray-400 dark:text-mx-gray-300"
						aria-hidden="true"
					/>
				</div>
				<div
					v-if="searchQueryValue || searchQueryOptionKey"
					class="absolute inset-y-0 right-0 flex items-center pr-3"
				>
					<button
						class="text-xs font-bold uppercase cursor-pointer text-mx-gray-400 dark:text-mx-gray-400 hover:text-mx-gray-500 dark:hover:text-mx-gray-300"
						@click="clearSearch"
					>
						<XCircleIcon class="w-5 h-5" />
					</button>
				</div>
				<select
					v-show="isDropdownOpen"
					ref="selectElement"
					v-model="selectedOption"
					:class="[minWidth, dropdownClasses]"
					class="absolute left-0 z-20 focus:outline-none focus:ring-0 w-full border-t-2 dark:border-mx-gray-500 border-mx-gray-100 overflow-auto bg-white dark:bg-mx-green-800 rounded-b-lg shadow-lg dark:shadow-white/5 max-h-[500px]"
					:size="options?.length"
					@blur="searchBlurred"
					@change="handleOptionSelect"
				>
					<option
						v-for="(option, index) in options"
						:key="option.key"
						:value="option.key"
						class="flex w-full h-10 py-3 pl-4 pr-10 bg-white cursor-pointer select-none whitespace-nowrap dark:bg-mx-green-700 dark:hover:bg-mx-green-600 dark:focus:bg-mx-green-600 focus:bg-mx-gray-200 focus:outline-mx-orange dark:text-mx-gray-300 hover:bg-mx-gray-100"
						:class="{'bg-mx-gray-200 dark:bg-mx-green-700 dark:text-white': index === focusedOptionIndex, 'text-black dark:text-gray-300 dark:bg-mx-green-800': index !== focusedOptionIndex}"
						@mouseover="focusedOptionIndex = index"
					>
						<span>
							<!-- select option element are only minimally stylable-->
							{{ option.label }} <span v-if="searchQueryOptionKey === option.key">
								&nbsp; ✓
							</span>
						</span>
					</option>
				</select>
			</div>
			<div
				v-if="searchQueryOptionKey"
				class="flex flex-col items-start justify-center h-full text-xs font-semibold text-black capitalize dark:text-mx-gray-300 whitespace-nowrap"
			>
				Searching: <br>
				<span class="block text-mx-orange dark:text-mx-orange">
					{{ activeSearchOptionLabel }}
				</span>
			</div>
		</ClientOnly>
	</div>
</template>

<script setup lang="ts">
import { MagnifyingGlassIcon, XCircleIcon } from '@heroicons/vue/20/solid'
import type { TableColumn } from '@/components/table/types'

const props = defineProps({
	options: {
		type: Array as PropType<TableColumn[]>,
		required: true
	},
	initialValue: {
		type: String,
		default: ''
	},
	dropdownClasses: {
		type: String,
		default: 'top-[52px]'
	},
	minWidth: {
		type: String,
		default: 'min-w-64'
	},
	placeholder: {
		type: String,
		default: 'Search'
	}
})

const { options, initialValue } = toRefs(props)
const emit = defineEmits([ 'search' ])

const searchQueryValue = ref('')
const searchQueryOptionKey = ref('')
const isDropdownOpen = ref(false)
const focusedOptionIndex = ref(-1)
const searchInputElement = ref<HTMLInputElement | null>(null)
const selectElement = ref<HTMLSelectElement | null>(null)
const selectedOption = ref('')

const activeSearchOptionLabel = computed(() => {
	const option = options.value.find(option => option.key === searchQueryOptionKey.value)
	return option ? option.label : ''
})

const searchFocused = () => {
	isDropdownOpen.value = true
}

const searchBlurred = () => {
	isDropdownOpen.value = false
	setSearchOption(searchQueryOptionKey.value)
	emit('search', searchQueryOptionKey.value, searchQueryValue.value)
}

const handleBlur = (event: FocusEvent) => {
	if (!event.relatedTarget || !(event.relatedTarget as HTMLElement).closest('select')) {
		searchBlurred()
	}
}

const setSearchOption = (key: string) => {
	searchQueryOptionKey.value = key
	isDropdownOpen.value = false
}

const clearSearch = () => {
	searchQueryValue.value = ''
	searchQueryOptionKey.value = ''
	focusedOptionIndex.value = -1
	selectedOption.value = ''
	emit('search', searchQueryOptionKey.value, searchQueryValue.value)
}

const focusNextOption = () => {
	if (focusedOptionIndex.value < options.value.length - 1) {
		focusedOptionIndex.value++
	} else {
		focusedOptionIndex.value = 0
	}
	nextTick(() => {
		if (selectElement.value) {
			selectElement.value.options[focusedOptionIndex.value].selected = true
		}
	})
}

const focusPreviousOption = () => {
	if (focusedOptionIndex.value > 0) {
		focusedOptionIndex.value--
	} else {
		focusedOptionIndex.value = options.value.length - 1
	}
	nextTick(() => {
		if (selectElement.value) {
			selectElement.value.options[focusedOptionIndex.value].selected = true
		}
	})
}

const handleOptionSelect = (event: Event) => {
	const target = event.target as HTMLSelectElement
	setSearchOption(target.value)
}

const handleSearchEnterPress = (event: KeyboardEvent) => {
	searchBlurred()
	const input = event.target as HTMLInputElement
	input.blur()
}

watch(initialValue, (newValue) => {
	if (newValue) {
		const [ key, value ] = newValue.split(':').map(item => item.trim())
		searchQueryValue.value = value || ''
		searchQueryOptionKey.value = key || ''
	}
}, { immediate: true })
</script>
