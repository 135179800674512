<template>
	<ClientOnly>
		<div class="relative w-full h-full">
			<div
				ref="searchRef"
				:class="[
					'fixed top-0 right-0 z-[90]',
					isExpanded ? 'h-full w-full' : 'w-64'
				]"
			>
				<div class="flex justify-end w-full dark:text-mx-gray-300">
					<SearchBarInput
						:expanded="isExpanded"
						:class="['ml-auto min-w-[400px] shrink-0',
							isExpanded ? 'order-last' : 'order-first'
						]"
						@search="handleSearch"
						@expanded="handleExpanded"
					/>

					<div
						v-show="isExpanded"
						class="order-first w-full h-screen p-6 overflow-y-auto rounded-l-lg shadow-lg bg-mx-gray-100 dark:bg-mx-green-900 lg:p-12 dark:bg-mx-gray-600 border-mx-gray-200 dark:border-mx-gray-500"
						@click="isExpanded = false"
					>
						<TableJsSorting
							:columns="ACCOUNTS_SEARCH_COLUMNS"
							:default-columns="ACCOUNTS_SEARCH_DEFAULT_COLUMNS"
							:table-data="searchResults"
							:is-loading="isLoading"
							:show-empty-state="!isLoading && !searchResults?.length"
							empty-state-title="No Accounts Found"
							pagination
							api-pagination
							:total-count="searchResultCount"
							:per-page="100"
							per-page-select
							:per-page-options="[10, 25, 50, 100]"
							search-bar
							:search-term-highlight="searchTerm"
							column-select
							@click.stop
							@page-change="handleSearch({ ...searchParams, pageCurrent: $event as number - 1 })"
							@per-page-change="handleSearch({ ...searchParams, pageSize: $event as number, pageCurrent: 0 })"
							@sort-api="handleSearch({ ...searchParams, ...$event })"
						>
							<template #header>
								<div
									class="pb-4 text-2xl font-semibold dark:text-mx-gray-50"
								>
									Search Results: <span class="pl-2 text-mx-orange">
										{{ searchParams.search }}
									</span>
								</div>
							</template>
						</TableJsSorting>
					</div>
				</div>
			</div>
			<!-- Fullscreen Backdrop -->
			<div
				v-if="isExpanded"
				class="fixed inset-0 bg-black bg-opacity-80 z-[80]"
				@click="isExpanded = false"
			/>
			<button
				v-if="isExpanded"
				class="absolute top-0 left-0 p-1 text-white bg-mx-orange z-[90]"
				@click="isExpanded = false"
			>
				<XCircleIcon
					class="w-8 h-8"
					aria-hidden="true"
				/>
			</button>
		</div>
	</ClientOnly>
</template>

<script setup lang="ts">
import { API_BASE, API_ENDPOINT_PORTAL_REDISEARCH_QUERY } from '@/constants'
import type { TableRow, TableColumn } from '@/components/table/types'
import { onClickOutside } from '@vueuse/core'
import { XCircleIcon } from '@heroicons/vue/20/solid'

interface AccountSearchItem {
	id: string
	accountId: string
	accountStatusId: string
	status: string
	source: string
	activated: string
	serviceLocationId: string
	contactId: string
	name: string
	emailId: string
	email: string
	phoneId: string
	phone: string
	geoLocationId: string
	addressId: string
	serviceLocationTypeId: string
	countyPropertyId: string
	speed: string
	priorityInstall: string
	surveyed: string
	surveyScheduledDate: string
	surveyScheduledTime: string
	insideInstalled: string
	insideScheduledDate: string
	insideScheduledTime: string
	excavationMethod: string
	outsideInstalled: string
	outsideScheduledDate: string
	outsideScheduledTime: string
	temporaryLine: string
	conduitProblem: string
	hasConduitProblem: string
	cablePulled: string
	cableTerminated: string
	cableSpliced: string
	latitude: string
	longitude: string
	address1: string
	address2: string
	cityId: string
	stateId: string
	countryId: string
	postalCode: string
	city: string
	state: string
	country: string
	zoneId: string
	neighborhood: string
	project: string
	noteId: string
	noteBody: string
	assignedTo: string
	hasAssignedTo: string
}

const ACCOUNTS_SEARCH_COLUMNS: TableColumn[] = [
	{ key: 'accountId', label: 'Account ID', sortable: true },
	{ key: 'activated', label: 'Activated', sortable: true },
	{ key: 'address1', label: 'Address 1', sortable: true },
	{ key: 'address2', label: 'Address 2', sortable: true },
	{ key: 'addressId', label: 'Address ID', sortable: true },
	// { key: 'assignedTo', label: 'Assigned To', sortable: true },
	{ key: 'cablePulled', label: 'Cable Pulled', sortable: true },
	{ key: 'cableSpliced', label: 'Cable Spliced', sortable: true },
	{ key: 'cableTerminated', label: 'Cable Terminated', sortable: true },
	{ key: 'city', label: 'City', sortable: true },
	{ key: 'cityId', label: 'City ID', sortable: true },
	{ key: 'conduitProblem', label: 'Conduit Problem', sortable: true },
	{ key: 'contactId', label: 'Contact ID', sortable: true },
	{ key: 'country', label: 'Country', sortable: true },
	{ key: 'countryId', label: 'Country ID', sortable: true },
	{ key: 'countyPropertyId', label: 'County Property ID', sortable: true },
	{ key: 'email', label: 'Email', sortable: true },
	{ key: 'emailId', label: 'Email ID', sortable: true },
	{ key: 'excavationMethod', label: 'Excavation Method', sortable: true },
	{ key: 'geoLocationId', label: 'Geo Location ID', sortable: true },
	{ key: 'hasAssignedTo', label: 'Has Assigned To', sortable: true },
	{ key: 'hasConduitProblem', label: 'Has Conduit Problem', sortable: true },
	{ key: 'insideInstalled', label: 'Inside Installed', sortable: true },
	{ key: 'insideScheduledDate', label: 'Inside Scheduled Date', sortable: true },
	{ key: 'insideScheduledTime', label: 'Inside Scheduled Time', sortable: true },
	{ key: 'latitude', label: 'Latitude', sortable: true },
	{ key: 'longitude', label: 'Longitude', sortable: true },
	{ key: 'name', label: 'Name', sortable: true },
	{ key: 'neighborhood', label: 'Neighborhood', sortable: true },
	{ key: 'noteBody', label: 'Note Body', sortable: true },
	{ key: 'noteId', label: 'Note ID', sortable: true },
	{ key: 'outsideInstalled', label: 'Outside Installed', sortable: true },
	{ key: 'outsideScheduledDate', label: 'Outside Scheduled Date', sortable: true },
	{ key: 'outsideScheduledTime', label: 'Outside Scheduled Time', sortable: true },
	{ key: 'phone', label: 'Phone', sortable: true },
	{ key: 'phoneId', label: 'Phone ID', sortable: true },
	{ key: 'postalCode', label: 'Postal Code', sortable: true },
	{ key: 'priorityInstall', label: 'Priority Install', sortable: true },
	{ key: 'project', label: 'Project', sortable: true },
	{ key: 'serviceLocationId', label: 'Service Location ID', sortable: true },
	{ key: 'serviceLocationTypeId', label: 'Service Location Type ID', sortable: true },
	{ key: 'source', label: 'Source', sortable: true },
	{ key: 'speed', label: 'Speed', sortable: true },
	{ key: 'state', label: 'State', sortable: true },
	{ key: 'stateId', label: 'State ID', sortable: true },
	{ key: 'status', label: 'Status', sortable: true },
	{ key: 'surveyScheduledDate', label: 'Survey Scheduled Date', sortable: true },
	{ key: 'surveyScheduledTime', label: 'Survey Scheduled Time', sortable: true },
	{ key: 'surveyed', label: 'Surveyed', sortable: true },
	{ key: 'temporaryLine', label: 'Temporary Line', sortable: true },
	{ key: 'zoneId', label: 'Zone ID', sortable: true }
]

const ACCOUNTS_SEARCH_DEFAULT_COLUMNS: TableColumn[] = [
	{
		key: 'accountId',
		label: 'Account ID',
		sortable: true
	},
	{
		key: 'status',
		label: 'Status',
		sortable: true
	},
	{
		key: 'name',
		label: 'Name',
		sortable: true
	},
	{
		key: 'email',
		label: 'Email',
		sortable: true
	},
	{
		key: 'phone',
		label: 'Phone',
		sortable: true
	},
	{
		key: 'city',
		label: 'City',
		sortable: true
	},
	{
		key: 'neighborhood',
		label: 'Neighborhood',
		sortable: true
	},
	/*
	{
		key: 'assignedTo',
		label: 'Assigned To',
		sortable: true
	}
	*/
	{
		key: 'noteBody',
		label: 'Note',
		sortable: false
	}
]

const searchRef = ref()
const searchParams = ref({ search: '' })
const searchResults = ref<TableRow[]>([])
const searchResultCount = ref(0)
const isLoading = ref(false)

const isExpanded = ref(false)

const searchTerm = computed(() => searchParams.value.search || '')

onClickOutside(searchRef, (_event) => {
	isExpanded.value = false
})

watch(isExpanded, (val) => {
	if (val) {
		// set the body to overflow hidden
		document.body.style.overflow = 'hidden'
	} else {
		document.body.style.overflow = 'auto'
	}
})

const handleSearch = async (params: any) => {
	searchParams.value = params
	isLoading.value = true

	await $fetch(`${API_BASE}${API_ENDPOINT_PORTAL_REDISEARCH_QUERY}`, {
		method: 'POST',
		credentials: 'include',
		body: JSON.stringify(params),
		onResponse ({ response }) {
			if (response.ok) {
				searchResults.value = mapAccountsTableData(response._data.data)
				searchResultCount.value = response._data.count
			} else {
				searchResults.value = []
				searchResultCount.value = 0
			}
		}
	}).finally(() => {
		isLoading.value = false
	})
}

const mapAccountsTableData = (accounts: AccountSearchItem[]): TableRow[] => {
	return accounts.map(account => ({
		id: account.accountId, // NOTE: all tables require an id for row styling
		accountId: account.accountId,
		accountStatusId: account.accountStatusId,
		status: account.status,
		source: account.source,
		activated: account.activated,
		serviceLocationId: account.serviceLocationId,
		contactId: account.contactId,
		name: account.name,
		emailId: account.emailId,
		email: account.email,
		phoneId: account.phoneId,
		phone: account.phone,
		geoLocationId: account.geoLocationId,
		addressId: account.addressId,
		serviceLocationTypeId: account.serviceLocationTypeId,
		countyPropertyId: account.countyPropertyId,
		speed: account.speed,
		priorityInstall: account.priorityInstall,
		surveyed: account.surveyed,
		surveyScheduledDate: account.surveyScheduledDate,
		surveyScheduledTime: account.surveyScheduledTime,
		insideInstalled: account.insideInstalled,
		insideScheduledDate: account.insideScheduledDate,
		insideScheduledTime: account.insideScheduledTime,
		excavationMethod: account.excavationMethod,
		outsideInstalled: account.outsideInstalled,
		outsideScheduledDate: account.outsideScheduledDate,
		outsideScheduledTime: account.outsideScheduledTime,
		temporaryLine: account.temporaryLine,
		conduitProblem: account.conduitProblem,
		hasConduitProblem: account.hasConduitProblem,
		cablePulled: account.cablePulled,
		cableTerminated: account.cableTerminated,
		cableSpliced: account.cableSpliced,
		latitude: account.latitude,
		longitude: account.longitude,
		address1: account.address1,
		address2: account.address2,
		cityId: account.cityId,
		stateId: account.stateId,
		countryId: account.countryId,
		postalCode: account.postalCode,
		city: account.city,
		state: account.state,
		country: account.country,
		zoneId: account.zoneId,
		neighborhood: account.neighborhood,
		project: account.project,
		noteId: account.noteId,
		noteBody: account.noteBody,
		assignedTo: account.assignedTo,
		hasAssignedTo: account.hasAssignedTo,
		navigateTo: `/accounts/${account.accountId?.toString()}`
	}))
}

const handleExpanded = (expanded: boolean) => {
	isExpanded.value = expanded
}
</script>
