import { setIsAccountPortal, isAccountPortal } from '@/composables/useIsAccountPortal'

export default defineNuxtRouteMiddleware((to) => {
	setIsAccountPortal(to?.name)
	if (!isAccountPortal.value && to.fullPath === '/') {
		return navigateTo('/accounts')
	}

	// TODO: does this break 404s?  Also, we dont have a 404 page
})
