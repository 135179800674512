import {
	HomeIcon,
	ChatBubbleOvalLeftIcon,
	ClockIcon,
	UsersIcon,
	MapPinIcon,
	FaceFrownIcon,
	Cog6ToothIcon
} from '@heroicons/vue/24/outline'
import IconTicket from '@/components/icon/Ticket.vue'
import IconDollarSign from '@/components/icon/DollarSign.vue'
import IconLogout from '@/components/icon/Logout.vue'
import { useRoute }	from 'vue-router'
import { useAuth } from '@/composables/useAuth'
import { useI18n } from 'vue-i18n'
import type { NavItem, NavItemSection } from '@/components/nav/types'
import type { MappedAccount } from '@/types/auth'
import { useLogout } from '@/composables/useLogout'
import { CURRENT_ITEM_OVERRIDE } from '@/constants/navigation/account'
import { IN_CONVERSION_ROUTES } from '@/constants/index'
import { isAccountPortal } from '@/composables/useIsAccountPortal'
import { computed, watch } from 'vue'

export function getNavLinks () {
	const { handleLogout } = useLogout()
	const { t } = useI18n()
	const { showBilling, hasMultipleAccounts, accounts, selectedAccount, hasSettingsViewPermission, formatAddress, setSelectedAccount } = useAuth()

	const accountView = computed<NavItem[]>(() => {
		const items = []
		if (hasMultipleAccounts.value) {
			items?.push({
				name: formatAddress(selectedAccount.value?.address),
				href: CURRENT_ITEM_OVERRIDE,
				children: accounts.value?.filter((account: MappedAccount) => {
					return account.accountId !== selectedAccount.value?.accountId
				}).map((account: MappedAccount) => {
					return {
						id: account.accountId,
						name: formatAddress(account.address),
						action: setSelectedAccount
					}
				})
			})
		}
		return items
	})

	const servicesItems = computed<NavItem[]>(() => {
		const items = []

		if (showBilling.value) {
			items.push(
				{
					name: t('nav-links.dashboard'),
					href: '/',
					icon: HomeIcon
				},
				{
					name: t('nav-links.billing'),
					href: '/billing',
					icon: IconDollarSign,
					children: [
						{
							name: t('nav-links.invoices'),
							href: '/invoices',
							icon: HomeIcon
						},
						{
							name: t('nav-links.payment-methods'),
							href: '/payment-methods',
							icon: HomeIcon
						}
					]
				}
			)
		} else {
			items.push({
				name: t('nav-links.install-status'),
				href: '/install-status',
				icon: ClockIcon
			})
		}

		items.push({
			name: t('nav-links.contact'),
			href: '/contact',
			icon: ChatBubbleOvalLeftIcon
		})
		return items
	})

	const accountItems = computed<NavItem[]>(() => {
		return [
			{
				name: t('nav-links.my-details'),
				href: '/my-details',
				icon: UsersIcon
			},
			{
				name: t('nav-links.logout'),
				action: handleLogout,
				icon: IconLogout
			}
		]
	})

	const AccountNavigation = computed<NavItemSection[]>(() => {
		const items = [
			{
				title: t('nav-links.my-services'),
				items: servicesItems.value
			},
			{
				title: t('nav-links.my-account'),
				items: accountItems.value
			}
		]
		if (hasMultipleAccounts.value) {
			items.unshift({
				title: t('nav-links.account-view'),
				items: accountView.value
			})
		}
		return items
	})

	const InternalNavigation = computed<NavItemSection[]>(() => {
		const links = [
			{
				title: '',
				items: [
					{
						name: 'Accounts',
						href: '/accounts',
						icon: UsersIcon
					},
					{
						name: 'Zones',
						href: '/zones',
						icon: MapPinIcon
					},
					{
						name: 'Delinquent Accounts',
						href: '/delinquent',
						icon: FaceFrownIcon
					},
					// {
					// 	name: 'Equipment',
					// 	href: '/equipment',
					// 	icon: IconEquipment
					// },
					{
						name: 'Tickets',
						href: '/ticketing',
						icon: IconTicket
					},
					{
						name: 'Logout',
						action: handleLogout,
						icon: IconLogout
					},
					{
						name: 'Beta',
						href: '/beta',
						icon: IconDollarSign,
						children: [
							{
								name: 'Ticket Inbox',
								href: '/ticket-inbox',
								icon: HomeIcon
							}
						]
					}
				]
			}
		]

		if (hasSettingsViewPermission.value) {
			links[0].items.push({
				name: 'Settings',
				href: '/settings',
				icon: Cog6ToothIcon
			})
		}
		return links
	})

	const navigation = computed(() => {
		return isAccountPortal.value ? AccountNavigation.value : InternalNavigation.value
	})

	const route = useRoute()

	watch(AccountNavigation, async (newValue) => {
		if (isAccountPortal.value) {
			if (newValue?.map(section => section.items?.filter(item => item.href)?.map(item => item.href))?.flat()?.includes(route.fullPath)) {
				return
			}
			if (!IN_CONVERSION_ROUTES.includes(route.fullPath) && !showBilling.value) {
				return await navigateTo('/install-status')
			}
			if (IN_CONVERSION_ROUTES.includes(route.fullPath) && showBilling.value) {
				return await navigateTo('/')
			}
		}
	})

	return { AccountNavigation, InternalNavigation, navigation }
}
