<template>
	<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="Icon">
			<path
				id="Icon_2"
				d="M6 2.43701H5C3.34315 2.43701 2 3.78016 2 5.43701L2 15.437C2 17.0939 3.34315 18.437 5 18.437H6M14 14.437L18 10.437M18 10.437L14 6.43701M18 10.437L6 10.437"
				stroke="currentColor"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</g>
	</svg>
</template>
