// API endpoints for editing account details

/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
import type { AccountNameBody, AccountDelinquentBody } from '@/types/edit-account-details'
import type { AccountStatusBody } from '@/types/account-statuses'
/* eslint-enable no-unused-vars, @typescript-eslint/no-unused-vars */

/**
 * Update the *installation* status of an account by ID
 *
 * @note **THIS DOES NOT UPDATE CONTRACT/BILLING/AUTOPAY STATUS!**
 * @method PUT
 * @param {string} {account-id} - Account ID
 * @returns {object} { "success": true }
 * @example
 * URL: "https://api.maverixbroadband.com/portal/accounts/1/updateStatus"
 * body: {
 *   "accountId": 1,
 *   "status": "Ignore",
 *   "reason": "A really good reason"
 * }
 * @see {@link AccountStatusBody}
 * @see {@link [swagger doc](https://dev-mx-back.maverixbroadband.com/docs/index.html#/Portal/7ff5ec43a1be176618c01aaad8ff9780}
 */
export const API_ENDPOINT_PORTAL_ACCOUNTS_UPDATE_STATUS = '/portal/accounts/{account-id}/updateStatus'

/**
 * Update a contact's name by ID
 *
 * @method PUT
 * @param {string} {account-id} - Account ID
 * @param {string} {contact-id} - Contact ID
 * @returns {object} { "success": true }
 * @example
 * URL: "https://api.maverixbroadband.com/portal/accounts/1/contacts/1/name"
 * body: {
 *   "title": "Mr.",
 *   "first": "John",
 *   "middle": null,
 *   "last": "Wick",
 *   "suffix": "Jr."
 * }
 * @see {@link AccountNameBody}
 * @see {@link [swagger doc](https://dev-mx-back.maverixbroadband.com/docs/index.html#/Portal/b0113e7c2f945d0790f8f4261057af42)}
 */
export const API_ENDPOINT_PORTAL_ACCOUNTS_UPDATE_NAME = '/portal/accounts/{account-id}/contacts/{contact-id}/name'

/**
 * Toggles on/off ignore overdue notices for an account
 *
 * @note When turned on, the `accounts.ignore_overdue` is set with todays timestamp; when turned off, it is set to `null`. **NOTE**: The timestamp will be returned in the ISO-8601 format with account endpoints.
 * @method PUT
 * @param {string} {account-id} - Account ID
 * @returns {object} { "success": true }
 * @example
 * URL: "https://api.maverixbroadband.com/portal/accounts/1/ignoreOverdue"
 * @see {@link [swagger doc](https://dev-mx-back.maverixbroadband.com/docs/index.html#/Portal/d60a0f8d5c664fd8944eec6b37d2c75d}
 */
export const API_ENDPOINT_PORTAL_IGNORE_OVERDUE = '/portal/accounts/{account-id}/ignoreOverdue'

/**
 * Update a phone number.
 *
 * @method PUT
 * @returns {object} { "success": true }
 * @example
 * URL: "https://api.maverixbroadband.com/portal/updatePhone"
 * body: {
 *  "phoneId": 1,
 *  "newPhone": "123-456-7890"
 * }
 * @todo Implement this endpoint
 * @todo add see tag link with request body object type
 * @see {@link [swagger doc](https://dev-mx-back.maverixbroadband.com/docs/index.html#/Portal/30118dabc2023414706db2b341bc6255}
 */
export const API_ENDPOINT_PORTAL_ACCOUNTS_UPDATE_PHONE = '/portal/updatePhone'

/**
 * Update an email address.
 *
 * @method PUT
 * @returns {object} { "success": true }
 * @example
 * URL: "https://api.maverixbroadband.com/portal/updateEmail"
 * body: {
 * "emailId": 1,
 * "newEmail": 'newemail@example.com'
 * }
 * @todo Implement this endpoint
 * @todo add see tag link with request body object type
 * @see {@link [swagger doc](https://dev-mx-back.maverixbroadband.com/docs/index.html#/Portal/475a70e6f58849b6ad95368af1437c23}
 */
export const API_ENDPOINT_PORTAL_ACCOUNTS_UPDATE_EMAIL = '/portal/updateEmail'

/**
 * Update the priority install status of an account by ID
 *
 * @method PUT
 * @param {string} {account-id} - Account ID
 * @returns {object} { "success": true }
 * @example
 * URL: "https://api.maverixbroadband.com/portal/accounts/1/priority-install"
 * body: {
 *  "enablePriorityInstall": true
 * }
 * @todo Implement this endpoint
 * @todo add see tag link with request body object type
 * @see {@link [swagger doc](https://dev-mx-back.maverixbroadband.com/docs/index.html#/Portal/0fad447399494f28a7d81bf9df7744db}
 */
export const API_ENDPOINT_PORTAL_ACCOUNTS_UPDATE_PRIORITY_INSTALL = '/portal/accounts/{account-id}/priority-install'

/**
 * Toggle delinquent status of an account by ID
 *
 * @method POST
 * @param {string} {account-id} - Account ID
 * @returns {object} { "success": true }
 *
 * @example
 * URL: "https://api.maverixbroadband.com/portal/accounts/1/toggleDelinquent"
 * body: {
 *   "reason": "A really good reason"
 * }
 * @see {@link AccountStatusBody}
 *
 * @see {@link [swagger doc](https://dev-mx-back.maverixbroadband.com/docs/index.html#/Portal/b0b51a561d548ece4fae0d2c175c3b67)}
 */
export const API_ENDPOINT_PORTAL_ACCOUNT_TOGGLE_DELINQUENT = '/portal/accounts/{account-id}/toggleDelinquent'

export default {
	API_ENDPOINT_PORTAL_ACCOUNTS_UPDATE_STATUS,
	API_ENDPOINT_PORTAL_ACCOUNTS_UPDATE_NAME,
	API_ENDPOINT_PORTAL_IGNORE_OVERDUE,
	API_ENDPOINT_PORTAL_ACCOUNTS_UPDATE_PHONE,
	API_ENDPOINT_PORTAL_ACCOUNTS_UPDATE_EMAIL,
	API_ENDPOINT_PORTAL_ACCOUNTS_UPDATE_PRIORITY_INSTALL,
	API_ENDPOINT_PORTAL_ACCOUNT_TOGGLE_DELINQUENT
}
