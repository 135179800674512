import type { StoredSystemSetting } from '@/types/system-settings'
import { TEXT /* BOOLEAN */ } from '@/constants/form/text-inputs/types'

// Define the custom permissions that are required for some settings to use for navigation guards
// NOTE: These permissions should be defined in the backend as well instead of being lumped in with all other permissions. Either prefixed or in a separate object.
export const DELINQUENT_WEBHOOK_PERMISSION = 'delinquent.webhook'

export const CUSTOM_SETTINGS_PERMISSIONS = [
	DELINQUENT_WEBHOOK_PERMISSION
	// add more permissions here
]

// Define the default permissions that work for normal settings
export const SETTINGS_EDIT_PERMISSION = 'settings.edit'
export const SETTINGS_VIEW_PERMISSION = 'settings.view'
export const SETTINGS_DELETE_PERMISSION = 'settings.delete'
export const SETTINGS_CREATE_PERMISSION = 'settings.create'

export const DEFAULT_SETTINGS_PERMISSIONS = [
	SETTINGS_EDIT_PERMISSION,
	SETTINGS_VIEW_PERMISSION,
	SETTINGS_DELETE_PERMISSION,
	SETTINGS_CREATE_PERMISSION
]

export const ALL_SYSTEM_SETTINGS_PERMISSIONS = [
	...CUSTOM_SETTINGS_PERMISSIONS,
	...DEFAULT_SETTINGS_PERMISSIONS
]

// Define the settings that are available in the system
export const DELINQUENT_WEBHOOK: StoredSystemSetting = {
	id: 'delinquent_macs_webhook_url',
	permission: DELINQUENT_WEBHOOK_PERMISSION,
	inputType: TEXT,
	validation: (value: string) => /^https?:\/\/.+$/.test(value)
}

// Map the settings to their respective IDs
export const SYSTEM_SETTINGS_MAP = new Map([
	[ DELINQUENT_WEBHOOK.id, DELINQUENT_WEBHOOK ]
	// add more settings here
])

export default {
	DEFAULT_SETTINGS_PERMISSIONS,
	CUSTOM_SETTINGS_PERMISSIONS,
	DELINQUENT_WEBHOOK_PERMISSION,
	ALL_SYSTEM_SETTINGS_PERMISSIONS,
	DELINQUENT_WEBHOOK,
	SYSTEM_SETTINGS_MAP
}
