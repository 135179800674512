<template>
	<div>
		<BatchActionWorkflowStepHeader>
			<template #title>
				Choose Accounts: Step {{ stepNumber }} of {{ totalSteps }}
			</template>
			<template #description>
				Select the accounts you want to perform the action on.
			</template>
		</BatchActionWorkflowStepHeader>
		<TableAccountsBatchAction
			:table-data="tableData"
			:default-columns="defaultColumns"
			:action="action"
		>
			<template #default="{ selectedRows }">
				<div class="flex items-center justify-start w-full pb-4 bg-mx-gray-100 dark:bg-mx-green-900">
					<div class="flex items-center gap-4">
						<FormButton
							id="continue-button"
							button-text="Continue"
							variant="primary"
							button-width="w-32"
							@click="handleContinue(selectedRows)"
						/>
						<FormButton
							id="cancel-button"
							button-text="Cancel"
							variant="secondary-background"
							button-width="w-32"
							@click="handleClose"
						/>
					</div>
				</div>
			</template>
		</TableAccountsBatchAction>
	</div>
</template>

<script setup lang="ts">
import type { TableRow, TableColumn, BatchAction } from '@/components/table/types'
defineProps({
	stepNumber: {
		type: Number,
		required: true
	},
	totalSteps: {
		type: Number,
		required: true
	},
	tableData: {
		type: Object as PropType<TableRow[]>,
		required: true
	},
	defaultColumns: {
		type: Array as PropType<TableColumn[]>,
		required: true
	},
	action: {
		type: Object as PropType<BatchAction>,
		required: true
	}
})

const emit = defineEmits([ 'close', 'save' ])

const handleContinue = (selectedRows: TableRow[]) => {
	emit('save', { selectedItems: selectedRows })
}

const handleClose = () => {
	emit('close')
}
</script>
