import type { MapboxSource, MapboxLayer, LayerFilter, Legend, MapIcon, Popup, ClusterConfig } from '@/components/mapbox/types'
import type { Feature, Point, Polygon, GeoJsonProperties } from 'geojson'
import type { FillLayerSpecification, LineLayerSpecification, CircleLayerSpecification, SymbolLayerSpecification } from 'mapbox-gl'
import { reactive } from 'vue'
import type { Ref } from 'vue'

interface MapboxMapProps {
  mapId: string
  mapClasses: string
  sources: MapboxSource[]
  layers: MapboxLayer[]
  legend: Legend[]
  icons: MapIcon[]
  defaultCenter: [number, number]
  pitch: number
  clusterConfig: ClusterConfig
  drawControl: boolean
  styleSwitcherControl: boolean
  fullscreenControl: boolean
  navigationControl: boolean
  layerVisibilityControl: boolean
  legendControl: boolean
  activePolygonControl: boolean
  activePolygonLabel: string
  activePoint: Point
  defaultStyle: string
  zoom: number
  reload: boolean
  popup: Popup
  layerFilters: LayerFilter[]
}

interface MapboxMapMethods {
  hideLayer: (layerId: string) => void
  hideLayers: (layerIds: string[]) => void
  showLayer: (layerId: string) => void
  showLayers: (layerIds: string[]) => void
  updateLayerFilters: (newVal: LayerFilter[], oldVal: LayerFilter[] | undefined) => void
  updateLayerVisibility: (layerVisibility: Record<string, boolean>) => void
	combineLayerFilterExpressions: (newFilters: LayerFilter[], oldFilters: LayerFilter[] | undefined) => Record<string, any[]> | undefined
}

export const useMapbox = (layers: MapboxLayer[], mapRef: Ref<mapboxgl.Map | undefined>): MapboxMapMethods => {
	// Make the layers object reactive to ensure reactivity
	const reactiveLayers = reactive(layers as any)

	const getLayerType = (layerId: string): FillLayerSpecification | LineLayerSpecification | SymbolLayerSpecification | CircleLayerSpecification | null => {
		let layerType = null
		const layer = reactiveLayers.find((layer: any) => layer.id === layerId)
		layerType = layer?.type

		switch (layerType) {
		case 'fill':
			return layer as FillLayerSpecification
		case 'line':
			return layer as LineLayerSpecification
		case 'symbol':
			return layer as SymbolLayerSpecification
		case 'circle':
			return layer as CircleLayerSpecification
		default:
			return null
		}
	}

	const hideLayer = (layerId: string) => {
		const mapLayer = getLayerType(layerId)
		if (
			mapLayer &&
			mapLayer.layout &&
			mapLayer.layout?.visibility !== 'none'
		) {
			mapLayer.layout.visibility = 'none'
		}
	}

	const hideLayers = (layerIds: string[]) => {
		layerIds.forEach((layerId) => {
			hideLayer(layerId)
		})
	}

	const showLayer = (layerId: string) => {
		const mapLayer = getLayerType(layerId)
		if (
			mapLayer &&
      mapLayer.layout &&
      mapLayer.layout?.visibility !== 'visible'
		) {
			mapLayer.layout.visibility = 'visible'
		}
	}

	const showLayers = (layerIds: string[]) => {
		layerIds.forEach((layerId) => {
			showLayer(layerId)
		})
	}

	// Helper function to combine and track filters
	const combineLayerFilterExpressions = (filters: LayerFilter[] = []): Record<string, any[]> => {
		const combinedExpressions: Record<string, any[]> = {}
		const filterMap: Record<string, Record<string, any>> = {}

		filters.forEach((filter) => {
			const { layerId, filterId, expression } = filter

			if (!combinedExpressions[layerId]) {
				combinedExpressions[layerId] = [ 'all' ]
				filterMap[layerId] = {}
			}

			// Add or update the filter expression for the layer
			filterMap[layerId][filterId] = expression
		})

		// Recombine expressions per layerId
		Object.keys(filterMap).forEach((layerId) => {
			const filtersForLayer = Object.values(filterMap[layerId])
			combinedExpressions[layerId].push(...filtersForLayer)
		})

		return combinedExpressions
	}

	// Identify removed filters by comparing oldVal and newVal
	const getRemovedFilters = (oldFilters: LayerFilter[] | undefined, newFilters: LayerFilter[]) => {
		return oldFilters?.filter(oldFilter => !newFilters.some(newFilter => newFilter.filterId === oldFilter.filterId)) || []
	}

	// Remove a specific filter from the combined filters of a layer
	const removeFilterFromLayer = (layerId: string, filterId: string, filters: LayerFilter[]) => {
		const remainingFilters = filters.filter(filter => filter.filterId !== filterId)
		const combinedExpressions = combineLayerFilterExpressions(remainingFilters)
		mapRef.value?.setFilter(layerId, combinedExpressions[layerId] || null)
	}

	// Apply filters and manage layer visibility
	const applyFiltersAndVisibility = (filters: LayerFilter[], removedFilters: LayerFilter[] = []) => {
		// see if any items in the filter.expressions matches a property in the source
		// expressions can be nested arrays, so we need to flatten them
		// if they do, keep the filter in the list
		// if not, remove the filter from the list

		// NOTE: this was added to handle cases where filters are applied to layers that don't have the properties in the source (e.g. 'has_move_in_date')
		filters = filters.filter((filter) => {
			const sourceId = reactiveLayers.find((layer: any) => layer?.id === filter?.layerId)?.layer?.source
			const source = mapRef.value?.getSource(sourceId) as any
			const features = source?._data?.features as Feature<Polygon, GeoJsonProperties>[] | Feature<Point, GeoJsonProperties>[]
			const properties = features?.map(feature => Object.keys(feature?.properties as Object))?.flat() || []
			const expressions = filter.expression.flat(Infinity)
			return expressions.some(expression => properties.includes(expression))
		})

		// Combine and apply filters for each layerId
		const combinedExpressions = combineLayerFilterExpressions(filters)

		// Used to track which layers are currently visible
		const allVisibleLayerIds = new Set<string>()

		// Apply combined filters for each layerId
		Object.keys(combinedExpressions).forEach((layerId) => {
			const filterExpression = combinedExpressions[layerId]
			mapRef.value?.setFilter(layerId, filterExpression)
		})

		// Manage visibility for remaining filters
		filters.forEach((filter) => {
			filter.visibleLayerIds.forEach((layerId) => {
				if (!allVisibleLayerIds.has(layerId)) {
					mapRef.value?.setLayoutProperty(layerId, 'visibility', 'visible')
					allVisibleLayerIds.add(layerId)
				}
			})
		})

		// Handle removing specific filters from layers
		removedFilters.forEach((filter) => {
			const { layerId, filterId } = filter
			removeFilterFromLayer(layerId, filterId, filters)
		})
	}

	// Watch for changes in layerFilters and pass new and old values to this
	const updateLayerFilters = (newVal: LayerFilter[], oldVal: LayerFilter[] | undefined) => {
		const removedFilters = getRemovedFilters(oldVal, newVal)
		applyFiltersAndVisibility(newVal, removedFilters)
	}

	const toggleLayerVisibility = (layerId: string, visibility: boolean) => {
		mapRef.value?.setLayoutProperty(layerId, 'visibility', visibility ? 'visible' : 'none')
	}

	const updateLayerVisibility = (layerVisibility: Record<string, boolean>) => {
		if (mapRef.value) {
			Object.keys(layerVisibility).forEach((layerId) => {
				toggleLayerVisibility(layerId, layerVisibility[layerId])
			})
		}
	}

	return {
		hideLayer,
		hideLayers,
		showLayer,
		showLayers,
		updateLayerFilters,
		updateLayerVisibility,
		combineLayerFilterExpressions
	}
}

export type { MapboxMapProps, MapboxMapMethods }
