const updateGradientMap = new Map()

export default defineNuxtPlugin((nuxtApp) => {
	nuxtApp.vueApp.directive('mouse-gradient-border', {
		beforeMount (el, _binding) { // TODO: make customizable with binding options
			// Add the necessary classes to the element
			el.classList.add(
				'isolate',
				'ring-1',
				'relative',
				'ring-mx-gray-200',
				'dark:ring-mx-green-700',
				'before:hidden',
				'before:lg:block',
				'before:absolute',
				'before:-inset-[4px]',
				'before:h-[calc(100%+4px)]',
				'before:w-[calc(100%+4px)]',
				'before:top-[-2px]',
				'before:left-[-2px]',
				'before:right-0',
				'before:bottom-0',
				'before:z-[-1]',
				'before:rounded-[inherit]',
				'background-gradient'
			)

			// Dynamically create <style> element
			const styleEl = document.createElement('style')
			styleEl.innerHTML = `
							.background-gradient::before {
								content: "";
								background: radial-gradient(350px circle at var(--x) var(--y), var(--gradient) 0, transparent 100%);
								will-change: background;
							}
						`

			// Append <style> element to <head>
			document.head.appendChild(styleEl)

			const updateGradient = (event) => {
				const { left, top } = el.getBoundingClientRect()
				const deltaX = event.clientX - left
				const deltaY = event.clientY - top

				el.style.setProperty('--x', `${deltaX}px`)
				el.style.setProperty('--y', `${deltaY}px`)
				el.style.setProperty('--gradient', 'rgba(255, 87, 51, 1)') // maverix orange
			}

			// Store the handler in the Map, associated with the element
			updateGradientMap.set(el, updateGradient)

			// Attach mousemove event listener
			document.addEventListener('mousemove', updateGradient)

			// Cleanup when the directive is unmounted
			el._cleanup = () => {
				document.removeEventListener('mousemove', updateGradient)
				// Remove the dynamically added <style> element
				document.head.removeChild(styleEl)
			}
		},
		beforeUnmount (el) {
			if (el._cleanup) {
				el._cleanup()
			}
			// Clean up the Map
			updateGradientMap.delete(el)
		}
	})
})
