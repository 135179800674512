<template>
	<div
		:class="[
			'flex items-center font-semibold',
			fontSize
		]"
	>
		<label
			:for="id"
			:class="[
				'flex items-center cursor-pointer p-0.5 rounded-md shadow-inner',
				trackColor
			]"
		>
			<div
				:class="[
					'relative'
				]"
			>
				<div
					:class="[
						buttonWidth,
						buttonHeight,
						'inline-block group'
					]"
				>
					<input
						:id="id"
						v-model="isChecked"
						type="checkbox"
						class="sr-only"
						:required="required"
					>
					<div
						:class="[
							{ 'translate-x-full': isChecked },
							buttonColor,
							'absolute top-0 left-0 w-1/2 h-full rounded-md shadow transform transition-transform duration-200'
						]"
					/>
					<div class="absolute inset-y-0 flex items-center justify-between w-full mt-0.5 select-none">
						<span
							:class="[
								!isChecked ? 'text-black dark:text-white' : 'text-mx-gray-500 dark:text-mx-gray-500',
								'flex justify-center w-1/2 text-center'
							]"
						>
							{{ offLabel }}
						</span>
						<span
							:class="[
								isChecked ? 'text-black dark:text-white' : 'text-mx-gray-500 dark:text-mx-gray-500',
								'flex justify-center w-1/2 text-center'
							]"
						>
							{{ onLabel }}
						</span>
					</div>
				</div>
			</div>
		</label>
	</div>
</template>

<script setup>
const props = defineProps({
	modelValue: {
		type: Boolean,
		default: false
	},
	onLabel: {
		type: String,
		default: 'YES'
	},
	offLabel: {
		type: String,
		default: 'NO'
	},
	buttonWidth: {
		type: String,
		default: 'w-[136px]'
	},
	buttonHeight: {
		type: String,
		default: 'h-[28px]'
	},
	fontSize: {
		type: String,
		default: 'text-base'
	},
	trackColor: {
		type: String,
		default: 'bg-mx-gray-100 dark:bg-mx-green-900'
	},
	buttonColor: {
		type: String,
		default: 'bg-white dark:bg-mx-green-700 dark:group-hover:bg-mx-green-600'
	},
	id: {
		type: String,
		required: true
	},
	required: {
		type: Boolean,
		default: false
	}
})

const { modelValue, onLabel, offLabel } = toRefs(props)
const emit = defineEmits([ 'update:modelValue' ])

const isChecked = ref(modelValue.value)

watch(isChecked, (value) => {
	emit('update:modelValue', value)
})

watch(modelValue, (value) => {
	isChecked.value = value
}, { immediate: true })

</script>
