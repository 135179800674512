<template>
	<div>
		<NuxtLayout>
			<div class="container text-6xl text-center dark:text-white">
				404
			</div>
		</NuxtLayout>
	</div>
</template>

<script setup lang="ts">
import { accountDirectory } from '@/constants/portalNames.js'
import { setIsAccountPortal } from './composables/useIsAccountPortal'
import type { NuxtError } from '#app'
import { useErrorReporter } from '@/composables/useErrorReporter'

// NOTE: this is a bit stupid.  I think the whole bit that i made aroudn this can be refactored to make a lot more sense.  but this gets 404s to show the right nav
try {
	if (window?.location?.host?.indexOf(accountDirectory) >= 0) {
		setIsAccountPortal(accountDirectory)
	}
} catch (e) {
	// console.log(e)
}

const props = defineProps({
	error: {
		type: Object as () => NuxtError,
		default: null
	}
})

const { error } = toRefs(props)

watchEffect(() => {
	if (error.value) {
		const { bugsnagReport } = useErrorReporter()
		bugsnagReport({
			error: new Error(error.value?.statusMessage),
			metaData: {
				statusCode: error.value.statusCode,
				message: error.value.statusMessage,
				unhandled: error.value.unhandled
			}
		})
	}
})

</script>
