<template>
	<div>
		<NuxtPwaManifest />
		<NuxtLayout>
			<NuxtPage />
			<Toast />
		</NuxtLayout>
	</div>
</template>

<script setup>

import { useHead } from '@vueuse/head'
import { useI18n } from 'vue-i18n'
import { provideUseId } from '@headlessui/vue'

// #TODO: Revisit when we updgrade to vue ^=3.5 https://github.com/tailwindlabs/headlessui/issues/2913
provideUseId(() => useId())

const { locale } = useI18n()

useHead({
	htmlAttrs: [
		{
			lang: () => {
				return locale.value
			}
		}
	]
})

</script>
