import type { MapboxLayer, QueryFeature } from '@/components/mapbox/types'
import type { FillLayerSpecification, LineLayerSpecification, CircleLayerSpecification, SymbolLayerSpecification } from 'mapbox-gl'

export const useMapEvents = () => {
	const isClusterEvent = (features: QueryFeature[] | undefined, mapLayers: MapboxLayer[]) => {
		if (!features) { return false }
		const clusterLayerIds: string[] = mapLayers
			?.filter((mapLayer) => {
				const layer = mapLayer.layer
				return layer.filter?.includes('has') && layer.filter?.includes('point_count')
			})
			.map(mapLayer => mapLayer.id) ?? []
		return features.some((feature) => {
			const layer = feature?.layer as CircleLayerSpecification | SymbolLayerSpecification
			return layer?.layout?.visibility === 'visible' && clusterLayerIds.includes(layer.id)
		})
	}

	const isMarkerEvent = (features: QueryFeature[] | undefined, mapLayers: MapboxLayer[]): boolean => {
		if (!features) { return false }

		if (features.some(feature => feature.properties?.cluster)) {
			return false
		}

		const circleLayerIds: string[] = mapLayers
			?.filter((mapLayer) => {
				const layer = mapLayer.layer as CircleLayerSpecification
				return layer.type === 'circle'
			})
			.map(mapLayer => mapLayer.id) ?? []

		return features.some((feature) => {
			const layer = feature?.layer as CircleLayerSpecification
			return layer?.layout?.visibility === 'visible' && circleLayerIds.includes(layer.id)
		})
	}

	const isPolygonEvent = (features: QueryFeature[] | undefined, mapLayers: MapboxLayer[]): boolean => {
		if (!features) { return false }

		const polygonLayerIds: string[] = mapLayers
			?.filter((mapLayer) => {
				const layer = mapLayer.layer as FillLayerSpecification
				return layer.type === 'fill'
			})
			.map(mapLayer => mapLayer.id) ?? []

		return features.some((feature) => {
			const layer = feature?.layer as FillLayerSpecification
			return layer?.layout?.visibility === 'visible' && polygonLayerIds.includes(layer.id)
		})
	}

	const isLineEvent = (features: QueryFeature[] | undefined, mapLayers: MapboxLayer[]) => {
		if (!features) { return false }

		const lineLayerIds: string[] = mapLayers
			?.filter((mapLayer) => {
				const layer = mapLayer.layer as LineLayerSpecification
				return layer.type === 'line'
			})
			.map(mapLayer => mapLayer.id) ?? []

		return features.some((feature) => {
			const layer = feature?.layer as LineLayerSpecification
			return layer?.layout?.visibility === 'visible' && lineLayerIds.includes(layer.id)
		})
	}

	const isMapEvent = (features: QueryFeature[] | undefined, mapLayers: MapboxLayer[]) => {
		return features && !isMarkerEvent(features, mapLayers) && !isClusterEvent(features, mapLayers) && !isPolygonEvent(features, mapLayers) && !isLineEvent(features, mapLayers)
	}

	return {
		isClusterEvent,
		isMapEvent,
		isMarkerEvent,
		isPolygonEvent,
		isLineEvent
	}
}
