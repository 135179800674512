import type { RouterConfig } from '@nuxt/schema'
import { accountDirectory, portalDirectory } from '@/constants/portalNames.js'
import { determineRouteDirectory } from '@/utils/determineRouteDirectory.js'

export default <RouterConfig> {
	routes: (_routes) => {
		let routesDirectory: any = null
		const { ssrContext } = useNuxtApp()

		// check for subdomain in the url on the server
		if (process.server && ssrContext && ssrContext.event.node.req) {
			const req = ssrContext.event.node.req
			const subdomain = req.headers.host?.split('.')[0]
			routesDirectory = determineRouteDirectory(subdomain)
		}

		// check for subdomain in the url on the client
		if (process.client && window.location.hostname) {
			const subdomain = window.location.hostname.split('.')[0]
			routesDirectory = determineRouteDirectory(subdomain)
		}

		// function to clear a directory not in use
		function isUnderDirectory (route: any, directory: any) {
			const path = route.path
			return path === '/' + directory || path.startsWith('/' + directory + '/')
		}

		let newRoutes = _routes

		if (routesDirectory) {
			// filter routes
			newRoutes = _routes.filter((route: any) => {
				const toRemove = routesDirectory === accountDirectory ? portalDirectory : accountDirectory
				return !isUnderDirectory(route, toRemove)
			}).map((route: any) => {
				if (isUnderDirectory(route, accountDirectory)) {
					return {
						...route,
						path: route.path.replace(`/${accountDirectory}`, '/'),
						name: route.name || 'index'
					}
				} else if (isUnderDirectory(route, portalDirectory)) {
					return {
						...route,
						path: route.path.substr(routesDirectory.length + 1) || '/',
						name: route.name || 'index'
					}
				}
				return route
			})

			return newRoutes
		}
	},
	scrollBehavior (to) {
		if (to.hash) {
			const el = document.getElementById(to.hash.slice(1))
			if (el) {
				window?.scrollTo({ top: el.offsetTop - 100, behavior: 'smooth' })
			}
		}
	}
}
