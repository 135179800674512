export const TEXT = 'text'
export const TEXTAREA = 'textarea'
export const EMAIL = 'email'
export const PASSWORD = 'password'
export const NUMBER = 'number'
export const DATE = 'date'
export const SEARCH = 'mx-search'
export const TEL = 'tel'
export const ZIP = 'zip'
export const USD = 'usd'
export const URL = 'url'
export const BOOLEAN = 'boolean'

export const CARD_HOLDER_NAME = 'cardHolderName'
export const CARD_NUMBER = 'cardNumber'
export const CARD_EXPIRATION = 'cardExpiration'
export const CARD_SECURITY_CODE = 'cardSecurityCode'
export const CARD_ZIP = 'cardZip'

export const BANK_ACCOUNT_NUMBER = 'bankAccountNumber'
export const BANK_ROUTING_NUMBER = 'bankRoutingNumber'
export const BANK_ACCOUNT_HOLDER_NAME = 'bankAccountHolderName'
export const BANK_NAME = 'bankName'

export default {
	TEXT,
	TEXTAREA,
	EMAIL,
	PASSWORD,
	NUMBER,
	DATE,
	SEARCH,
	TEL,
	ZIP,
	CARD_HOLDER_NAME,
	CARD_NUMBER,
	CARD_EXPIRATION,
	CARD_SECURITY_CODE,
	CARD_ZIP,
	BANK_ACCOUNT_NUMBER,
	BANK_ROUTING_NUMBER,
	BANK_ACCOUNT_HOLDER_NAME,
	BANK_NAME,
	USD,
	URL,
	BOOLEAN
}
