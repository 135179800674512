import { useToast } from '@/composables/useToast'
import type { ToastParams } from '@/types/toast'
import { IS_PRODUCTION } from '@/constants/index.js'

interface User {
	id: string
	name: string
	email?: string
	emailId: string
}

interface MetaData {
	[key: string]: any
}

interface ReportErrorParams {
	error: Error
	showToast?: boolean
	toast?: ToastParams
	metaData?: MetaData
	user?: User
}

const { addToast } = useToast()

export function useErrorReporter () {
	const bugsnagReport = ({
		error,
		showToast = false,
		toast = {
			title: 'Oops!',
			message: 'Something went wrong. Please try again later.'
		},
		metaData,
		user
	}: ReportErrorParams) => {
		if (showToast) {
			addToast({
				title: toast.title,
				message: toast.message,
				options: { duration: 7000 }
			})
		}

		useBugsnag().notify(error, (event) => {
			if (metaData) {
				event.addMetadata('data', metaData)
			}
			if (user) {
				event.setUser(user.id, user.email, user.name)
			}
		})

		if (!IS_PRODUCTION) {
			// eslint-disable-next-line no-console
			console.log(error)
		}
	}

	return {
		bugsnagReport
	}
}
