import { ref, computed } from 'vue'
import { API_BASE, SUPPORT_INTERNAL_EMAIL, PORTAL_BASE } from '@/constants'
import type { TicketCreate, MappedTicket, TicketComment, TicketCommentCreate, TicketUpdateObject, DynamicObject } from '@/types/ticketing'
import { mapTickets } from '@/utils/mappers/TicketMap'
import { useErrorReporter } from '@/composables/useErrorReporter'
import { TICKET_STATUS_OPTIONS, TICKET_TIER_OPTIONS } from '@/constants/ticketing'
import { useToast } from '@/composables/useToast'
import { useNotes } from '@/composables/useNotes'
import { useSystemEmailSend } from '@/composables/useSystemEmailSend'
import { usePermissions } from '@/composables/usePermissions'

const allTickets = ref<MappedTicket[]>([])
const accountTickets = ref<MappedTicket[]>([])
const activeAccountId = ref('')

const hasOpenTickets = computed(() => accountTickets.value?.some((ticket: MappedTicket) => ticket.status === TICKET_STATUS_OPTIONS.OPEN))
const formerAccountId = ref('')

const accountTicketsLoading = ref(false)
const allTicketsLoading = ref(false)
const updateLoading = ref(false)
const commentsLoading = ref(false)

const comments = ref<TicketComment[]>([])
const formerTicketId = ref('')

export function useTicketing () {
	const { bugsnagReport } = useErrorReporter()
	const { addToast } = useToast()
	const { setNote } = useNotes()
	const { fetchPermissions, accountServiceRepOptions } = usePermissions()

	const createTicket = async (ticketData: TicketCreate) => {
		const createdTime = new Date()
		const assignee = { ...ticketData.assignee }
		const assigneeString = assignee?.id ? `${assignee?.firstName || ''} ${assignee?.lastName || ''}` : 'Unassigned'
		const { sendSystemEmail } = useSystemEmailSend()
		await $fetch(`${API_BASE}/mongo/tickets`,	{
			method: 'POST',
			credentials: 'include',
			body: { doc: JSON.stringify({ ...ticketData, createdTime }) },
			onResponse ({ response }) {
				if (!response?.ok) {
					throw new Error(response._data?.error || response._data?.errors?.[0])
				}
				addToast({
					notificationType: 'success',
					title: 'Ticket created'
				})
				fetchTicketsOnAccount(ticketData.accountId, true)
				setNote({
					note: `TICKET CREATED \rTitle: <a target="_blank" href="${PORTAL_BASE}/accounts/${ticketData.accountId}/ticketing/${response._data?.id?.$oid}" class="text-mx-orange hover:underline">${ticketData.title}</a> \rId: ${response._data.id?.$oid}`,
					accountId: ticketData.accountId,
					category: 'other'
				})
				sendSystemEmail({
					to: SUPPORT_INTERNAL_EMAIL,
					from: 'info@maverixbroadband.com',
					subject: `New Ticket Created: ${ticketData.title}`,
					body: `A new ticket has been created. \r\n Title: <a target="_blank" href="${PORTAL_BASE}/accounts/${ticketData.accountId}/ticketing/${response._data?.id?.$oid}">${ticketData.title}</a> \r\n Description: ${ticketData.content} \r\n Account: ${ticketData.accountId} \r\n Assignee: ${assigneeString}`
				})
			}
		}).catch((e) => {
			bugsnagReport({
				error: e instanceof Error ? e : new Error('createTicket - catch: ' + (e as Error).toString()),
				showToast: true
			})
		})
	}

	const fetchTickets = async () => {
		if (allTicketsLoading.value) { return } // Prevents multiple fetches

		allTicketsLoading.value = true

		await fetchPermissions('notes.create')

		$fetch(`${API_BASE}/mongo/get/tickets`, {
			method: 'POST',
			credentials: 'include',
			onResponse ({ response }) {
				if (!response?.ok) {
					throw new Error(response._data?.error || response._data?.errors?.[0])
				}
				allTickets.value = mapTickets(response._data.docs).sort((a, b) => {
					return new Date(b.lastUpdated || '')?.getTime() - new Date(a.lastUpdated || '')?.getTime()
				})
				allTicketsLoading.value = false
			}
		}).catch((e) => {
			bugsnagReport({
				error: e instanceof Error ? e : new Error('fetchTickets - catch: ' + (e as Error).toString()),
				showToast: true
			})
			allTicketsLoading.value = false
		})
	}

	const fetchTicketsOnAccount = async (accountId: string, refetch?: boolean) => {
		if (!accountId) { return }
		if (accountId && formerAccountId.value === accountId && !refetch) { return }

		await fetchPermissions('notes.create')

		formerAccountId.value = accountId || ''
		activeAccountId.value = accountId || ''

		const data = {
			mongoQueryFilters: {
				filters: {
					accountId
				}
			}
		}

		accountTicketsLoading.value = true
		$fetch(`${API_BASE}/mongo/get/tickets`, {
			method: 'POST',
			credentials: 'include',
			body: JSON.stringify(data),
			onResponse ({ response }) {
				if (!response?.ok) {
					throw new Error(response._data?.error || response._data?.errors?.[0])
				}
				accountTickets.value = mapTickets(response._data.docs).sort((a, b) => {
					return new Date(b.lastUpdated || '')?.getTime() - new Date(a.lastUpdated || '')?.getTime()
				})
				accountTicketsLoading.value = false
			}
		}).catch((e) => {
			bugsnagReport({
				error: e instanceof Error ? e : new Error('fetchTicketsById - catch: ' + (e as Error).toString()),
				showToast: true
			})
			accountTicketsLoading.value = false
		})
	}

	const fetchComments = (ticketId?: string, refetch?: boolean) => {
		if (formerTicketId.value === ticketId && !refetch) { return }
		formerTicketId.value = ticketId || ''
		commentsLoading.value = true
		$fetch(`${API_BASE}/mongo/get/ticketsComments`, {
			method: 'POST',
			credentials: 'include',
			onResponse ({ response }) {
				if (!response?.ok) {
					commentsLoading.value = false
					throw new Error(response._data?.error || response._data?.errors?.[0])
				}
				comments.value = ticketId ? response._data.docs.filter((comment: any) => comment?.ticketId === ticketId) : response._data.docs
				commentsLoading.value = false
			}
		}).catch((e) => {
			bugsnagReport({
				error: e instanceof Error ? e : new Error('fetchComments - catch: ' + (e as Error).toString()),
				showToast: true
			})
			commentsLoading.value = false
		})
	}

	const createComment = (commentData: TicketCommentCreate) => {
		commentsLoading.value = true
		$fetch(`${API_BASE}/mongo/ticketsComments`, {
			method: 'POST',
			credentials: 'include',
			body: { doc: JSON.stringify(commentData) },
			onResponse ({ response }) {
				if (!response?.ok) {
					commentsLoading.value = false
					throw new Error(response._data?.error || response._data?.errors?.[0])
				}
				fetchComments(commentData.ticketId, true)
				commentsLoading.value = false
			}
		}).catch((e) => {
			bugsnagReport({
				error: e instanceof Error ? e : new Error('createComment - catch: ' + (e as Error).toString()),
				showToast: true
			})
			commentsLoading.value = false
		})
	}

	const updateTicket = async (data: { ticketData: TicketUpdateObject, accountId: string, email?: string | null, title: string }) => {
		const { ticketData, accountId, email, title } = data || {}
		const { sendSystemEmail } = useSystemEmailSend()
		updateLoading.value = true
		const getUpdateText = (update: DynamicObject) => {
			const updateObj = update
			const updateText = Object.keys(updateObj).map((key) => {
				return key === 'assignee' ? `${key.toUpperCase()}: ${updateObj[key].firstName} ${updateObj[key].lastName}` : `${key.toUpperCase()}: ${updateObj[key]}`
			}).join(', ')
			return updateText
		}
		const updateText = getUpdateText(ticketData.update)

		ticketData.update.updated = new Date()
		if (ticketData.update.tier === TICKET_TIER_OPTIONS.TIER2) {
			ticketData.update.escalated = new Date()
		}

		const payload = {
			key: ticketData.key,
			value: ticketData.value,
			update: JSON.stringify(ticketData.update)
		}

		await $fetch(`${API_BASE}/mongo/tickets`, {
			method: 'PUT',
			credentials: 'include',
			body: payload,
			/* async */ onResponse ({ response }) {
				if (!response?.ok) {
					updateLoading.value = false
					throw new Error(response._data?.error || response._data?.errors?.[0])
				}
				addToast({
					notificationType: 'success',
					title: 'Ticket updated'
				})
				updateLoading.value = false
				setNote({
					note: `TICKET UPDATED \rTitle: <a target="_blank" href="${PORTAL_BASE}/accounts/${accountId}/ticketing/${ticketData.value}" class="text-mx-orange hover:underline">${title}<a> \r Update: ${updateText}`,
					accountId,
					category: 'other'
				})
				sendSystemEmail({
					to: email ?? SUPPORT_INTERNAL_EMAIL,
					from: 'info@maverixbroadband.com',
					subject: `Ticket Status Updated: ${title}`,
					body: `The status of ticket <a target="_blank" href="${PORTAL_BASE}/accounts/${accountId}/ticketing/${ticketData.value}">${title}</a> has been updated. \r\n Update: \r\n ${updateText}`
				})
				fetchTicketsOnAccount(accountId, true)
				// await navigateTo(`/accounts/${accountId}/ticketing`)
			}
		}).catch((e) => {
			bugsnagReport({
				error: e instanceof Error ? e : new Error('updateTicket - catch: ' + (e as Error).toString()),
				showToast: true
			})
			updateLoading.value = false
		})
	}

	const getTicketById = (ticketId: string) => {
		const accountsTicketFound = accountTickets.value?.find((ticket: MappedTicket) => ticket?.id?.toString() === ticketId?.toString())
		const allTicketsFound = allTickets.value?.find((ticket: MappedTicket) => ticket?.id?.toString() === ticketId?.toString())
		return accountsTicketFound || allTicketsFound
	}

	return {
		createTicket,
		fetchTickets,
		fetchTicketsOnAccount,
		accountTickets,
		allTickets,
		hasOpenTickets,
		getTicketById,
		fetchComments,
		comments,
		createComment,
		updateTicket,
		allTicketsLoading,
		accountTicketsLoading,
		updateLoading,
		commentsLoading,
		accountServiceRepOptions
	}
}
