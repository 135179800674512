<template>
	<div class="w-full">
		<div class="flex w-full mx-auto justify-items-center">
			<slot name="header">
				<div
					v-if="!hideHeader"
					class="pb-4 text-2xl font-semibold dark:text-mx-gray-50"
				>
					{{ title }}
				</div>
				<div
					v-if="showCreateButton"
					class="ml-auto"
				>
					<button
						class="primary-btn"
						@click="emitOpenCreateModal"
					>
						<span class="mt-0.5">
							Create {{ itemName }}
						</span>
					</button>
				</div>
			</slot>
		</div>
		<div class="w-full">
			<div class="flex justify-between">
				<slot name="outerTopLeftControls">
					<slot name="presetFilters">
						<div
							v-if="presetFilters.length"
							class="pb-2"
						>
							<div class="flex flex-wrap gap-2 py-2">
								<div
									v-for="filter in presetFiltersWithAllOption"
									:key="filter.id"
								>
									<button
										v-if="filter.filters?.length"
										class="h-12 px-4 py-2 text-xs font-bold transition-all duration-100 ease-in-out rounded-lg shadow text-mx-gray-500 dark:text-mx-gray-300"
										:class="[ showPresetActive(filter) ? 'bg-mx-orange text-white hover:bg-mx-orange-muted' : 'text-mx-gray-500 bg-white dark:bg-mx-green-700 hover:bg-mx-gray-200 dark:hover:bg-mx-green-600' ]"
										@click="addOrRemovePreset(filter)"
									>
										{{ filter.label }}
									</button>
									<div
										v-if="filter.options?.length"
										class="relative flex gap-2"
									>
										<Combobox multiple>
											<div class="flex gap-2 flex-nowrap">
												<ComboboxButton class="bg-white whitespace-nowrap inline-flex gap-x-1.5 items-center shadow-sm dark:bg-mx-green-700 hover:bg-mx-gray-200 dark:hover:bg-mx-green-600 px-4 py-1.5 h-12 rounded-lg text-sm font-bold text-mx-gray-500 dark:text-mx-gray-300">
													<span
														class="after:content-[':']"
														:class="{'after:text-transparent' : !presetActiveLabel(filter.label) }"
													>
														{{ presetLabel(filter.label) }}
													</span><span
														v-if="presetActiveLabel(filter.label)"
														class="text-mx-orange"
													>
														{{ presetActiveLabel(filter.label) }}
													</span> <ChevronDownIcon
														class="w-5 h-5 -mr-1.5 text-mx-gray-400"
														aria-hidden="true"
													/>
												</ComboboxButton>
												<ComboboxOptions class="absolute right-0 top-[52px] z-10 w-full min-w-64 mt-1 overflow-auto bg-white dark:bg-mx-green-800 rounded-md shadow-lg max-h-[500px]">
													<ComboboxOption
														v-for="option in filter.options"
														:key="option.id"
														v-slot="{ active }"
														:value="option"
														@click="addOrRemovePresetOptions(option.id?.toString(), filter)"
													>
														<div
															:class="[
																'relative py-2 pl-4 pr-10 cursor-pointer select-none whitespace-nowrap',
																{ 'bg-mx-gray-100 dark:bg-mx-green-600 dark:text-white': active },
																{ 'text-black dark:text-mx-gray-300': !active }
															]"
														>
															<span class="pr-3">
																{{ option.label }}
															</span>
															<span
																v-if="showPresetOptionSelected(option.id, filter.options)"
																class="absolute inset-y-0 right-0 flex items-center pr-3"
															>
																✓
															</span>
														</div>
													</ComboboxOption>
												</ComboboxOptions>
											</div>
										</Combobox>
									</div>
								</div>
								<button
									v-if="showPresetReset"
									class="-ml-0.5 text-xs font-bold uppercase text-mx-orange hover:bg-mx-gray-200 dark:hover:bg-mx-green-800 px-2 py-1.5 rounded-lg cursor-pointer"
									@click="clearActiveFilters"
								>
									reset
								</button>
							</div>
						</div>
					</slot>
				</slot>
				<slot name="outerTopRightControls">
					<div
						:class="[
							'flex items-bottom flex-wrap gap-2 ml-auto',
							{ 'justify-end' : showTableControlBar }
						]"
					>
						<slot name="extraControlsTopRight" />

						<slot name="cellSizeSelect">
							<div
								v-if="cellSizeSelect || tableControls"
								:class="[
									'flex mt-auto gap-2 pb-4 pl-2 ml-auto',
								]"
							>
								<!-- Table Cell Size Select Buttons -->
								<slot name="cell-size">
									<div
										v-if="cellSizeSelect || tableControls"
										class="flex justify-center space-x-2 items-bottom bg-mx-gray-100 dark:bg-mx-green-900"
									>
										<button
											:class="[
												'w-8 h-8 text-center rounded-lg cursor-pointer transition-colors duration-100 text-sm font-semibold select-none',
												cellSize === 'sm' ? 'bg-white text-mx-gray-400/80 dark:text-mx-gray-300 dark:bg-mx-green-600 shadow' : 'bg-mx-gray-500/10 dark:hover:text-mx-gray-400 hover:text-white dark:bg-mx-green-800 shadow-inner text-mx-gray-500/50 dark:text-mx-green-600'
											]"
											@click="userSelectedCellSize = 'sm'"
										>
											A
										</button>

										<button
											:class="[
												'w-8 h-8 rounded-lg cursor-pointer transition-colors duration-100 text-lg font-semibold select-none',
												cellSize === 'md' ? 'bg-white text-mx-gray-400/80 dark:text-mx-gray-300 dark:bg-mx-green-600 shadow' : 'bg-mx-gray-500/10 dark:hover:text-mx-gray-400 hover:text-white dark:bg-mx-green-800 shadow-inner text-mx-gray-500/50 dark:text-mx-green-600'
											]"
											@click="userSelectedCellSize = 'md'"
										>
											A
										</button>

										<button
											:class="[
												'w-8 h-8 rounded-lg cursor-pointer transition-colors duration-100 text-2xl font-semibold select-none',
												cellSize === 'lg' ? 'bg-white text-mx-gray-400/80 dark:text-mx-gray-300 dark:bg-mx-green-600 shadow' : 'bg-mx-gray-500/10 dark:hover:text-mx-gray-400 hover:text-white dark:bg-mx-green-800 shadow-inner text-mx-gray-500/50 dark:text-mx-green-600'
											]"
											@click="userSelectedCellSize = 'lg'"
										>
											A
										</button>
									</div>
								</slot>
							</div>
						</slot>
					</div>
				</slot>
			</div>
			<div class="w-full bg-white rounded-lg shadow dark:bg-mx-green-700">
				<slot name="table-controls">
					<div
						:class="[
							showTableControlBar ? controlBarClasses : '',
							'flex justify-between w-full gap-2 justify-items-center flex-wrap'
						]"
					>
						<div class="flex flex-wrap items-center gap-2 lg:flex-nowrap">
							<slot
								name="search"
								:search-query="searchQuery"
							>
								<div
									v-if="searchBar || tableControls"
									class="w-full max-w-sm min-w-44"
								>
									<div class="relative">
										<input
											v-model="searchQuery"
											type="text"
											:class="controlButtonClasses"
											class="w-full px-4 text-sm font-normal rounded-lg shadow-inner outline-none min-w-64 focus:ring-transparent bg-mx-gray-50 text-mx-gray-500 placeholder-mx-gray-400 dark:bg-mx-green-800 dark:border-mx-green-900 dark:text-mx-gray-300 dark:placeholder-mx-gray-300 focus:outline-mx-orange"
											:placeholder="isApiPagination ? 'Search this page...' : 'Search...'"
										>
										<div class="absolute inset-y-0 right-0 flex items-center pr-3">
											<MagnifyingGlassIcon
												class="w-5 h-5 text-mx-gray-400 dark:text-mx-gray-300"
												aria-hidden="true"
											/>
										</div>
									</div>
								</div>
							</slot>
							<slot name="filters">
								<Menu
									v-if="showFilters"
									as="div"
									class="relative inline-block text-left group"
								>
									<div>
										<MenuButton
											:class="controlButtonClasses"
											class="inline-flex w-full justify-between items-center gap-x-1.5 px-4 py-2 rounded-md bg-mx-gray-100 dark:bg-mx-green-800 whitespace-nowrap text-sm font-semibold text-mx-gray-500 dark:text-mx-gray-300 shadow-sm hover:bg-mx-gray-200 dark:hover:bg-mx-green-600"
										>
											<span>
												{{ activeFilterLabel }}
											</span>
											<ChevronDownIcon
												class="w-5 h-5 -mr-1.5 text-mx-gray-400"
												aria-hidden="true"
											/>
										</MenuButton>
									</div>

									<Transition
										enter-active-class="transition duration-100 ease-out"
										enter-from-class="transform scale-95 opacity-0"
										enter-to-class="transform scale-100 opacity-100"
										leave-active-class="transition duration-75 ease-in"
										leave-from-class="transform scale-100 opacity-100"
										leave-to-class="transform scale-95 opacity-0"
									>
										<MenuItems class="absolute right-0 z-10 w-auto mt-2 origin-top-right bg-white rounded-md shadow-lg dark:bg-mx-green-800 focus:outline-none">
											<div class="first-of-type:rounded-t-md last-of-type:rounded-b-md">
												<MenuItem
													v-for="(filter, index) in filters"
													:key="filter.id"
													as="div"
													class="first-of-type:rounded-t-md last-of-type:rounded-b-md"
												>
													<template #default="{ active }">
														<button
															:class="[
																active ? 'bg-mx-gray-100 dark:bg-mx-green-600 dark:text-white' : 'text-gray-700 dark:text-mx-gray-300',
																'block w-full px-4 py-2 text-left focus:outline-transparent whitespace-nowrap',
																{ 'rounded-t-md' : index === 0 },
																{ 'rounded-b-md' : index === filters.length - 1 }
															]"
															@click="handleFilterChange(filter.id.toString())"
														>
															{{ filter.label }}
														</button>
													</template>
												</MenuItem>
											</div>
										</MenuItems>
									</Transition>
								</Menu>
								<button
									v-if="activeFilterKey"
									:class="controlButtonClasses"
									class="-ml-0.5 text-xs font-bold uppercase text-mx-orange hover:bg-mx-gray-200 dark:hover:bg-mx-green-800 px-2 py-1.5 rounded-lg cursor-pointer"
									@click="activeFilterKey = ''"
								>
									reset
								</button>
							</slot>
							<slot name="multiFilterSelect">
								<div
									v-if="multiFilterSelect"
									class="relative flex gap-2"
								>
									<Combobox multiple>
										<div class="flex gap-2 flex-nowrap">
											<ComboboxButton
												:class="controlButtonClasses"
												class="bg-mx-gray-100 whitespace-nowrap inline-flex gap-x-1.5 items-center shadow-sm dark:bg-mx-green-800 hover:bg-mx-gray-200 dark:hover:bg-mx-green-600 px-4 py-1.5 rounded-lg text-sm font-bold text-mx-gray-500 dark:text-mx-gray-300"
											>
												Multi-Filter <ChevronDownIcon
													class="w-5 h-5 -mr-1.5 text-mx-gray-400"
													aria-hidden="true"
												/>
											</ComboboxButton>
											<ComboboxOptions
												:class="dropdownClasses"
												class="absolute left-0 z-10 w-auto overflow-x-hidden mt-1 overflow-auto bg-white dark:bg-mx-green-800 rounded-md shadow-lg max-h-[500px]"
											>
												<ComboboxOption
													v-for="filter in filters"
													:key="filter.id"
													v-slot="{ active }"
													:value="filter"
													@click="addOrRemoveFilter(filter.id.toString())"
												>
													<div
														class="relative py-2 pl-4 pr-10 cursor-pointer select-none whitespace-nowrap"
														:class="[
															{ 'bg-mx-gray-100 dark:bg-mx-green-600 dark:text-white': active },
															{ 'text-black dark:text-mx-gray-300': !active }
														]"
													>
														<span class="pr-3">
															{{ filter.label }}
														</span>
														<span
															v-if="activeMultiFilterIds.includes(filter.id)"
															class="absolute inset-y-0 right-0 flex items-center pr-3"
														>
															✓
														</span>
													</div>
												</ComboboxOption>
											</ComboboxOptions>
											<button
												v-if="showMultiFilterReset()"
												class="-ml-0.5 text-xs font-bold uppercase text-mx-orange hover:bg-mx-gray-200 dark:hover:bg-mx-green-800 px-2 py-1.5 rounded-lg cursor-pointer"
												@click="clearActiveMultiFilters"
											>
												reset
											</button>
										</div>
									</Combobox>
								</div>
							</slot>
							<slot name="column-select">
								<div
									v-if="columnSelect || tableControls"
									class="relative flex gap-2"
								>
									<Combobox multiple>
										<div class="flex gap-2 flex-nowrap">
											<ComboboxButton
												:class="controlButtonClasses"
												class="bg-mx-gray-100 whitespace-nowrap inline-flex gap-x-1.5 items-center shadow-sm dark:bg-mx-green-800 hover:bg-mx-gray-200 dark:hover:bg-mx-green-600 px-4 py-1.5 rounded-lg text-sm font-bold text-mx-gray-500 dark:text-mx-gray-300"
											>
												Columns <ChevronDownIcon
													class="w-5 h-5 -mr-1.5 text-mx-gray-400"
													aria-hidden="true"
												/>
											</ComboboxButton>
											<ComboboxOptions
												:class="dropdownClasses"
												class="absolute left-0 z-10 w-full min-w-60 mt-1 overflow-auto bg-white dark:bg-mx-green-800 rounded-md shadow-lg max-h-[500px]"
											>
												<ComboboxOption
													v-for="column in columns"
													v-slot="{ active }"
													:key="column.key"
													:value="column"
													@click="addOrRemoveColumn(column.key.toString())"
												>
													<div
														class="relative py-2 pl-4 pr-10 cursor-pointer select-none whitespace-nowrap"
														:class="[
															{ 'bg-mx-gray-100 dark:bg-mx-green-600 dark:text-white': active },
															{ 'text-black dark:text-mx-gray-300': !active }
														]"
													>
														{{ column.label }} <span
															v-if="selectedColumnKeys.includes(column.key)"
															class="absolute inset-y-0 right-0 flex items-center pr-3"
														>
															✓
														</span>
													</div>
												</ComboboxOption>
											</ComboboxOptions>
											<button
												v-if="showColumnReset"
												class="-ml-0.5 text-xs font-bold uppercase text-mx-orange hover:bg-mx-gray-200 dark:hover:bg-mx-green-800 px-2 py-1.5 rounded-lg cursor-pointer"
												@click="resetColumnSelect"
											>
												reset
											</button>
										</div>
									</Combobox>
								</div>
							</slot>

							<slot name="perPage">
								<div
									v-if="perPageSelect || tableControls"
									class="flex items-center gap-2"
								>
									<Menu
										as="div"
										class="relative inline-block text-left group"
									>
										<div>
											<MenuButton
												:class="controlButtonClasses"
												class="inline-flex w-36 justify-between items-center gap-x-1.5 rounded-md bg-mx-gray-100 dark:bg-mx-green-800 px-4 py-2 whitespace-nowrap text-sm font-semibold text-mx-gray-500 dark:text-mx-gray-300 shadow-sm hover:bg-mx-gray-200 dark:hover:bg-mx-green-600"
											>
												<template v-if="!perPageOptions.length">
													<template v-if="itemsPerPage !== filteredTableData?.length">
														{{ itemsPerPage }} per page
													</template>
													<template v-else>
														show all
													</template>
												</template>
												<template v-else>
													{{ itemsPerPage }} per page
												</template>
												<ChevronDownIcon
													class="w-5 h-5 -mr-1.5 text-mx-gray-400"
													aria-hidden="true"
												/>
											</MenuButton>
										</div>
										<Transition
											enter-active-class="transition duration-100 ease-out"
											enter-from-class="transform scale-95 opacity-0"
											enter-to-class="transform scale-100 opacity-100"
											leave-active-class="transition duration-75 ease-in"
											leave-from-class="transform scale-100 opacity-100"
											leave-to-class="transform scale-95 opacity-0"
										>
											<MenuItems class="absolute left-0 z-10 mt-1 origin-top-left bg-white rounded-md shadow-lg min-w-36 dark:bg-mx-green-800 focus:outline-none">
												<div class="first-of-type:rounded-t-md last-of-type:rounded-b-md">
													<MenuItem
														v-for="(option, index) in paginationOptions"
														:key="option"
														as="div"
														class="first-of-type:rounded-t-md last-of-type:rounded-b-md"
													>
														<template #default="{ active }">
															<button
																:class="[
																	active ? 'bg-mx-gray-100 dark:bg-mx-green-600 dark:text-white' : 'text-gray-700 dark:text-mx-gray-300',
																	'block w-full px-4 py-2 text-left focus:outline-transparent',
																	{ 'rounded-t-md' : index === 0 },
																	{ 'rounded-b-md' : perPageOptions.length ? paginationOptions.length : option === filteredTableData.length }
																]"
																@click="userSelectedPerPage = option"
															>
																<template v-if="option !== filteredTableData.length && !perPageOptions.length">
																	{{ option }} per page
																</template>
																<template v-else-if="!perPageOptions.length">
																	show all
																</template>
																<template v-else>
																	{{ option }} per page
																</template>
															</button>
														</template>
													</MenuItem>
												</div>
											</MenuItems>
										</Transition>
									</Menu>
									<span
										class="text-sm text-mx-gray-500 dark:text-mx-gray-300 whitespace-nowrap"
										:class="tableTextClasses"
									>
										<template v-if="!isApiPagination">
											<template v-if="totalFilteredItems !== totalItems">
												matching {{ totalFilteredItems }} records of <br>
											</template>
											{{ totalItems }} results
										</template>
										<template v-if="isApiPagination">
											<template v-if="totalFilteredItems !== totalItems">
												matching <br> {{ totalFilteredItems }} records on page {{ currentPage }} <br> of
											</template>
											{{ totalCount }} results
										</template>
									</span>
								</div>
							</slot>
						</div>

						<!-- Right Control Slot -->
						<slot
							name="rightControl"
							:control-button-classes="controlButtonClasses"
						/>

						<!-- Pagination Controls -->
						<slot name="pagination">
							<template v-if="showPagination">
								<div class="flex items-center justify-between space-x-2 bg-white rounded-lg dark:bg-mx-green-700">
									<input
										id="currentPage"
										v-model="pageInput"
										:class="[tableTextClasses, pageInputClasses]"
										class="-mr-0.5 text-center rounded-md shadow-inner text-mx-gray-500 dark:text-mx-gray-300 bg-mx-gray-50 dark:bg-mx-green-800 focus:outline-none ring-0"
										type="number"
										:min="1"
										:max="totalPages"
										@focus="pageInputBlurred = false"
										@blur="pageInputBlurred = true"
										@keydown.enter.prevent="handlePageInputEnter"
									>
									</input>
									<label
										for="currentPage"
										class="text-mx-gray-500 dark:text-mx-gray-300 whitespace-nowrap"
										:class="tableTextClasses"
									>
										of {{ totalPages }}
									</label>
									<div class="flex items-center">
										<button
											:disabled="currentPage <= 1"
											:class="controlButtonClasses"
											class="p-2 mr-2 rounded-md aspect-square bg-mx-gray-100 dark:bg-mx-green-800 hover:bg-mx-gray-200 dark:hover:bg-mx-green-600 disabled:opacity-50 disabled:hover:bg-mx-gray-100 dark:disabled:hover:bg-mx-green-800 disabled:cursor-not-allowed dark:text-mx-gray-400"
											@click="previousPage"
										>
											<ChevronLeftIcon />
										</button>
										<button
											:disabled="currentPage >= totalPages"
											:class="controlButtonClasses"
											class="p-2 rounded-md aspect-square bg-mx-gray-100 dark:bg-mx-green-800 hover:bg-mx-gray-200 dark:hover:bg-mx-green-600 disabled:opacity-50 disabled:cursor-not-allowed dark:disabled:hover:bg-mx-green-800 dark:text-mx-gray-400 disabled:hover:bg-mx-gray-100"
											@click="nextPage"
										>
											<ChevronRightIcon />
										</button>
									</div>
								</div>
							</template>
						</slot>
					</div>
				</slot>
				<div class="w-full overflow-x-auto">
					<table
						:id="tableId"
						class="w-full h-auto max-w-full border-separate border-none rounded-lg shadow border-spacing-0 dark:bg-mx-green-700"
					>
						<colgroup>
							<col
								v-for="(col, index) in displayedColumns"
								:key="index"
								:span="col.span || 1"
								:style="col.style"
							>
						</colgroup>
						<thead class="rounded-t-lg">
							<tr
								v-if="isLoading"
								class="rounded-t-lg first-of-type:rounded-tl-lg last-of-type:rounded-tr-lg"
							>
								<th
									v-for="i in loadingColumnsCount"
									:key="i"
									class="px-1 py-2 bg-mx-gray-200 md:px-6 dark:bg-mx-green-800 animate-pulse first-of-type:rounded-tl-lg last-of-type:rounded-tr-lg"
								>
									<div class="w-full h-4 rounded bg-mx-gray-50 dark:bg-mx-green-700" />
								</th>
							</tr>
							<tr
								v-else
								:class="[
									'first-of-type:bg-mx-gray-200 dark:first-of-type:dark:bg-mx-green-800',
									{ 'first-of-type:rounded-tl-lg last-of-type:rounded-tr-lg' : !(searchBar || tableControls) }
								]"
							>
								<!-- Select All Checkbox Header -->
								<th
									v-if="batchSelect"
									scope="col"
									:class="[
										'relative text-left border-b border-mx-gray-100 dark:border-mx-green-900 dark:text-mx-gray-300',
										tableColumnClasses,
										{ 'first-of-type:rounded-tl-lg last-of-type:rounded-tr-lg' : !(showTableControlBar) }
									]"
								>
									<FormCheckbox
										:id="`${tableId}-checkbox-row-all`"
										hide-label
										required
										screen-reader-text="Select All"
										variant="secondary"
										:checked="allRowsSelected"
										@change="toggleSelectAll"
									/>
								</th>
								<th
									v-for="column in displayedColumns"
									:key="column.key"
									scope="col"
									:class="[
										'text-left border-b border-mx-gray-100 dark:border-mx-green-900 dark:text-mx-gray-300',
										tableColumnClasses,
										{ 'first-of-type:rounded-tl-lg last-of-type:rounded-tr-lg' : !(searchBar || tableControls) }
									]"
								>
									<div
										v-if="!column.hidden"
										:class="[
											'select-none inline-flex items-center text-xs font-medium text-black uppercase dark:text-mx-gray-300 rounded-lg px-2 pt-2 pb-1.5 -ml-2',
											{ 'dark:hover:bg-mx-green-600 hover:bg-mx-gray-300 cursor-pointer' : column?.sortable || isApiPagination }
										]"
										@click="handleSort(column)"
									>
										<span class="font-bold">
											{{ column.label }}
										</span>
										<span
											v-if="activeSortColumnKey === column.key"
											class="ml-2 -mb-1"
										>
											<BarsArrowUpIcon
												v-if="sortOrder === 'ASC'"
												class="w-5 h-5"
											/>
											<BarsArrowDownIcon
												v-if="sortOrder === 'DESC'"
												class="w-5 h-5"
											/>
										</span>
									</div>
								</th>
							</tr>
						</thead>
						<tbody class="rounded-b-lg">
							<template v-if="isLoading">
								<tr
									v-for="i in perPage"
									:key="i"
									class="w-full bg-white border-b border-mx-gray-100 dark:border-mx-green-900 dark:bg-mx-green-800"
								>
									<td
										v-for="j in loadingColumnsCount"
										:key="j"
										:class="[
											{ 'first-of-type:rounded-bl-lg last-of-type:rounded-br-lg' : i === perPage }
										]"
									>
										<div
											:class="[
												'bg-white dark:bg-mx-green-700 animate-pulse self-stretch w-full justify-start items-center inline-flex',
												tableDataClasses,
												{ 'rounded-bl-lg' : j === 1 && i === perPage },
												{ 'rounded-br-lg' : j === perPage && i === perPage }
											]"
										>
											<div class="w-full h-4 rounded bg-mx-gray-200 dark:bg-mx-green-800" />
										</div>
									</td>
								</tr>
							</template>
							<template v-else>
								<tr
									v-for="(row, index) in paginatedTableData"
									:id="`${tableId}-${row.id}`"
									:key="`${row.id}-${index}`"
									:class="[
										row.rowClasses ? row.rowClasses : 'text-mx-gray-500 dark:text-mx-gray-400',
										'w-full border-b border-mx-gray-100 last-of-type:rounded-br-lg dark:border-mx-green-900 group'
									]"
								>
									<!-- Row Checkbox -->
									<td
										v-if="batchSelect"
										:class="[
											'justify-start border-b border-mx-gray-100 dark:border-mx-green-900',
											tableDataClasses,
											{ 'first-of-type:rounded-bl-lg last-of-type:rounded-br-lg' : isLastRow(row) },
											{ 'dark:group-hover/row:bg-mx-green-600 group-hover/row:bg-mx-gray-100' : row?.navigateTo }
										]"
									>
										<FormCheckbox
											:id="`${tableId}-checkbox-row-${row.id}`"
											hide-label
											required
											screen-reader-text="Select Row"
											:checked="isSelected(row)"
											@change="toggleRowSelection(row, $event)"
										/>
									</td>
									<td
										v-for="column in displayedColumns"
										:key="column.key"
										:class="[
											tableDataClasses,
											'justify-start border-b border-mx-gray-100 dark:border-mx-green-900',
											{ 'first-of-type:rounded-bl-lg last-of-type:rounded-br-lg' : isLastRow(row) },
											{ 'dark:group-hover:bg-mx-green-600 group-hover:bg-mx-gray-100 cursor-pointer' : row?.navigateTo }
										]"
										:style="column.style"
										:colspan="column.span || 1"
									>
										<NuxtLink
											:href="row.navigateTo"
											target="_blank"
											:class="[
												'items-center self-stretch justify-start w-full leading-tight',
												tableTextClasses
											]"
										>
											<template v-if="$slots[column.key]">
												<slot
													:name="column.key"
													:row="row"
													:column="column"
													:format-cell="formatCell"
													:highlight="highlight"
												/>
											</template>
											<template v-else>
												<span
													class="line-clamp-3"
													:class="tableTextClasses"
													v-html="highlight(formatCell(row[column.key], column.formatter, row)).value"
												/>
											</template>
										</NuxtLink>
									</td>
								</tr>
							</template>
						</tbody>
					</table>
					<div
						v-if="(showEmptyState && !isLoading) || emptyFilteredData"
						class="w-full"
					>
						<slot name="empty">
							<TableCellsIcon
								class="w-1/2 max-w-md mx-auto text-mx-gray-300 dark:text-mx-green-600 h-1/2"
								aria-hidden="true"
							/>
							<div class="w-full max-w-sm pb-8 mx-auto -mt-8">
								<div class="text-2xl font-semibold text-center text-mx-gray-400">
									{{ emptyStateMessage }}
								</div>
							</div>
							<div
								v-if="showResetTableButton"
								class="flex justify-center w-full pb-8"
							>
								<button
									v-if="(showEmptyState && !isLoading) || emptyFilteredData"
									class="h-10 primary-btn"
									@click="resetTableState()"
								>
									<span class="text-base">
										Reset Table
									</span>
								</button>
							</div>
						</slot>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import {
	TableCellsIcon,
	BarsArrowDownIcon,
	BarsArrowUpIcon,
	MagnifyingGlassIcon,
	ChevronLeftIcon,
	ChevronRightIcon
} from '@heroicons/vue/24/outline'

import type {
	TableRow,
	TableColumn,
	TableFilter,
	PresetFilter,
	CellSize
} from '@/components/table/types'

import { useTableSorting, useTableSearchHighlighting, useTableSizing } from '@/composables/useTable'
import type { SortOrder } from '@/utils/sortMethods/types'
import { useRoute } from 'vue-router'

import {
	Combobox,
	ComboboxButton,
	ComboboxOptions,
	ComboboxOption,
	Menu,
	MenuButton,
	MenuItem,
	MenuItems
} from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'

const route = useRoute()

const props = defineProps({
	tableId: {
		type: String,
		default: 'table'
	},
	columns: {
		type: Array as PropType<(TableColumn)[]>,
		default: () => []
	},
	defaultColumns: {
		type: Array as PropType<(TableColumn)[]>,
		default: () => []
	},
	filters: {
		type: Array as PropType<(TableFilter)[]>,
		default: () => []
	},
	tableData: { // fields must match column keys, must have id
		type: Array as PropType<TableRow[]>,
		required: true
	},
	title: {
		type: String,
		default: 'Table Title'
	},
	emptyStateTitle: {
		type: String,
		default: 'No Table Data Found'
	},
	tableCellSize: {
		type: String as PropType<CellSize>,
		default: 'sm'
	},
	cellSizeSelect: {
		type: Boolean,
		default: false
	},
	itemName: {
		type: String,
		default: 'Item'
	},
	emptyStateMessage: {
		type: String,
		default: 'No Table Data Found'
	},
	isLoading: {
		type: Boolean,
		default: false
	},
	showEmptyState: {
		type: Boolean,
		default: true
	},
	showCreateButton: {
		type: Boolean,
		default: false
	},
	hideHeader: {
		type: Boolean,
		default: false
	},
	searchBar: {
		type: Boolean,
		default: false
	},
	filterable: {
		type: Boolean,
		default: false
	},
	pagination: {
		type: Boolean,
		default: false
	},
	perPageSelect: {
		type: Boolean,
		default: false
	},
	perPageOptions: {
		type: Array as PropType<number[]>,
		default: () => []
	},
	apiPagination: {
		type: Boolean,
		default: false
	},
	totalCount: {
		type: Number,
		default: 0
	},
	columnSelect: {
		type: Boolean,
		default: false
	},
	multiFilterSelect: {
		type: Boolean,
		default: false
	},
	tableControls: {
		type: Boolean,
		default: false
	},
	perPage: {
		type: Number,
		default: 14
	},
	presetFilters: {
		type: Array as PropType<PresetFilter[]>,
		default: () => []
	},
	allowFilterUnions: {
		type: Boolean,
		default: false
	},
	defaultFilters: {
		type: Array as PropType<(TableFilter)[]>,
		default: () => []
	},
	defaultColumnSort: {
		type: Object as PropType<{ key: string, order: SortOrder }>,
		default: () => ({ key: '', order: 'ASC' })
	},
	showResetTableButton: {
		type: Boolean,
		default: true
	},
	searchTermHighlight: {
		type: String,
		default: ''
	},
	batchSelect: {
		type: Boolean,
		default: false
	}
})

const { tableId, tableData, isLoading, columns, perPage, pagination, perPageOptions, apiPagination, totalCount, tableControls, tableCellSize, searchBar, columnSelect, perPageSelect, filterable, filters, multiFilterSelect, presetFilters, defaultColumns, allowFilterUnions, defaultFilters, defaultColumnSort, searchTermHighlight, batchSelect } = toRefs(props)

const emit = defineEmits([
	'open-create-modal',
	'active-preset-options',
	'page-change',
	'per-page-change',
	'sort-api',
	'columns-change',
	'selection-change'
])

const { highlight } = useTableSearchHighlighting(searchTermHighlight)

const {
	tableDataClasses,
	tableColumnClasses,
	tableTextClasses,
	controlButtonClasses,
	controlBarClasses,
	dropdownClasses,
	pageInputClasses,
	cellSize,
	userSelectedCellSize
} = useTableSizing(tableId.value, tableCellSize)

const allFilters = computed(() => {
	const presetFilterOptions = presetFilters.value?.map(({ options }) => {
		return options || []
	}).flat() || []
	return [ ...filters.value, ...presetFilterOptions ]
})

const sortOrder = ref<SortOrder>(defaultColumnSort.value.order)
const activeSortColumnKey = ref<string>(defaultColumnSort.value.key)
const activeFilterKey = ref<string>('')
const searchQuery = ref('')
const selectedColumnKeys = ref(defaultColumns.value.map(column => column.key))
const activeMultiFilterIds = ref(defaultFilters.value?.map(({ id }) => id) || [])
const currentPage = ref(1)
const userSelectedPerPage = ref()
const pageInput = ref(1)
const pageInputBlurred = ref(false)

const paginationOptions = computed(() => {
	return perPageOptions.value.length ? perPageOptions.value : [ 14, 28, 42, filteredTableData.value.length ]
})

const filteredTableData = ref<TableRow[]>([])

const showTableControlBar = computed(() => {
	return searchBar.value || showFilters.value || multiFilterSelect.value || columnSelect.value || perPageSelect.value || tableControls.value
})

const showPagination = computed(() => {
	return pagination.value || tableControls.value
})

const activeFilterLabel = computed(() => {
	const activeFilter = filters.value.find(f => f.id === activeFilterKey.value)
	return activeFilter?.label || 'Filters'
})

const totalFilteredItems = computed(() => {
	return filteredTableData.value?.length || 0
})

const setFilteredTableData = () => {
	// Directly return the full table data if there's no search query and no active filter key
	if (!searchQuery.value && !activeFilterKey.value && !activeMultiFilterIds.value.length) {
		filteredTableData.value = tableData.value
		return
	}

	filteredTableData.value = tableData.value.filter((row) => {
		// If there's an active filter key, filter based on the active filter
		if (activeFilterKey.value) {
			const filter = allFilters.value.find(f => f.id === activeFilterKey.value)
			const column = columns.value.find(c => c.key === filter?.columnKey)

			if (column && row[column.key]?.toString()?.toLowerCase() !== filter?.matches?.toLowerCase()) {
				return false
			}
		}

		// If there's active muti-filters selected, filter based on all selected filter ids,
		// but only if the row passes each previous filter
		if (activeMultiFilterIds.value.length) {
			const matches = activeMultiFilterIds.value.map((id) => {
				const filter = allFilters.value.find(f => f.id === id)
				const column = columns.value.find(c => c.key === filter?.columnKey)
				return column && row[column.key]?.toString()?.toLowerCase() === filter?.matches?.toLowerCase()
			})
			if (allowFilterUnions.value) {
				if (!matches.some(Boolean)) {
					return false
				}
			} else if (!matches.every(Boolean)) {
				return false
			}
		}

		// For all rows that pass the initial filter, or if there's no active filter,
		// further filter based on the search query across all columns.
		// Only search columns that are included in the columns array
		return columns.value.some((column) => {
			const cellValue = row[column.key]?.toString()?.toLowerCase() || ''
			return cellValue.includes(searchQuery.value?.toLowerCase())
		})
	})
}

const presetFiltersWithAllOption = computed(() => {
	return presetFilters.value.map(filter => (
		filter?.options && filter?.allOption ? { ...filter, options: [ { id: 'All', label: 'All', columnKey: filter?.id, matches: 'All' }, ...filter.options ] } : filter
	))
})

const isApiPagination = computed(() => {
	return apiPagination.value && totalCount.value
})

const itemsPerPage = computed(() => {
	if (!tableControls.value && !pagination.value) {
		return totalFilteredItems.value
	}
	return userSelectedPerPage.value || perPage.value
})

const getPaginatedTableData = (data: any) => {
	if (!tableControls.value && !pagination.value) {
		return data
	}
	if (apiPagination.value) {
		return data
	}
	const start = (currentPage.value - 1) * itemsPerPage.value
	const end = start + itemsPerPage.value
	const paginatedData = [ ...data ]

	return paginatedData.slice(start, end)
}

const emptyFilteredData = computed(() => {
	return !isLoading.value && !filteredTableData.value?.length
})

const paginatedTableData = ref<TableRow[]>(getPaginatedTableData(filteredTableData.value))

const presetLabel = (label: string) => {
	// split label at : and return the first part
	return label.split(':')[0] || label
}

const presetActiveLabel = (label: string) => {
	// split label at : and return the second part
	return label.split(':')[1] || ''
}

watch(filteredTableData, () => { // reset currentPage when new filter is selected
	if (!apiPagination.value) {
		currentPage.value = 1
		paginatedTableData.value = getPaginatedTableData(filteredTableData.value)
	} else if (apiPagination.value && totalCount.value) {
		paginatedTableData.value = getPaginatedTableData(filteredTableData.value)
	}
})

watch(itemsPerPage, () => {
	paginatedTableData.value = getPaginatedTableData(filteredTableData.value)
	emit('per-page-change', itemsPerPage.value)
})

watch(currentPage, () => {
	if (pageInput.value !== currentPage.value) {
		pageInput.value = currentPage.value
	}
	paginatedTableData.value = getPaginatedTableData(filteredTableData.value)
	emit('page-change', currentPage.value)
})

watch(searchQuery, () => {
	setFilteredTableData()
	paginatedTableData.value = getPaginatedTableData(filteredTableData.value)
})

watch(activeMultiFilterIds, () => {
	setFilteredTableData()
	paginatedTableData.value = getPaginatedTableData(filteredTableData.value)
})

watch(tableData, () => {
	setFilteredTableData()
}, { immediate: true, deep: true })

watch(activeSortColumnKey, () => {
	setFilteredTableData()
})

watch(sortOrder, () => {
	setFilteredTableData()
})

watch(activeFilterKey, () => {
	setFilteredTableData()
})

const resetTableState = () => {
	activeFilterKey.value = ''
	sortOrder.value = defaultColumnSort.value.order
	activeSortColumnKey.value = defaultColumnSort.value.key
	searchQuery.value = ''
	selectedColumnKeys.value = defaultColumns.value.map(column => column.key)
	activeMultiFilterIds.value = defaultFilters.value?.map(({ id }) => id) || []
	currentPage.value = 1
	userSelectedPerPage.value = perPage.value
}

watch([ presetFilters, activeMultiFilterIds ], () => { // watch both presetFilters (for first load) and activeMultiFilterIds (for changes after first load)
	// presetFilters.options can come from the api so we need to watch deeply to detect changes to the "options" array since they may not be populated yet when the activeMultiFilterIds array is updated from the route query

	// find all objects in the presetFilters array that have a property of "options" (array) that contains an object that has a property of "label" (string) that matches a value in the activeMultiFilterIds array
	const presetFiltersWithOptions = presetFilters.value.filter(filter => filter?.options?.some(option => activeMultiFilterIds.value.includes(option.id)))

	// then return all objects in presetFilter.options that has a property of id that matches any value in the activeMultiFilterIds array
	const activePresetOptions = presetFiltersWithOptions.map(filter => filter?.options?.filter(option => activeMultiFilterIds.value.includes(option.id))).flat() // The map method creates an array of arrays, and flat is used to combine these into a single array

	// finally emit the activePresetOptions to the parent component to be used to display the active filters
	emit('active-preset-options', activePresetOptions)
}, { deep: true })

const totalPages = computed(() => {
	if (isApiPagination.value) {
		return Math.ceil(totalCount.value / itemsPerPage.value)
	}
	return Math.ceil(filteredTableData.value.length / itemsPerPage.value)
})

const totalItems = computed(() => {
	return tableData.value?.length || 0
})

const displayedColumns = computed(() => {
	// Filter and prioritize default columns that are in selectedColumnKeys
	const selectedColumns = columns.value.filter(column => selectedColumnKeys.value.includes(column.key))
	const defaultColumnsInSelectedOrder = defaultColumns.value.filter(column => selectedColumnKeys.value.includes(column.key))

	// Combine default columns with the remaining selected columns
	const combinedColumns = [
		...defaultColumnsInSelectedOrder,
		...selectedColumns.filter(column => !defaultColumnsInSelectedOrder.some(defCol => defCol.key === column.key))
	]

	// Remove duplicates and return unique columns
	return [ ...new Map(combinedColumns.map(column => [ column.key, column ])).values() ]
})

const showFilters = computed(() => {
	return filters.value.length > 0 && (filterable.value || tableControls.value) && !multiFilterSelect.value
})

const showColumnReset = computed(() => {
	return selectedColumnKeys.value.length !== defaultColumns.value.length
})

const loadingColumnsCount = computed(() => {
	return (displayedColumns.value.length + (batchSelect.value ? 1 : 0)) || 5
})

const selectedRows = ref<TableRow[]>([])

const isSelected = (row: TableRow) => {
	return selectedRows.value.some(selectedRow => selectedRow.id === row.id)
}

const toggleRowSelection = (row: TableRow, event: Event) => {
	const checkbox = event.target as HTMLInputElement
	if (checkbox.checked) {
		selectedRows.value.push(row)
	} else {
		selectedRows.value = selectedRows.value.filter(selectedRow => selectedRow.id !== row.id)
	}
}

const allRowsSelected = computed(() => {
	return tableData.value.length > 0 && selectedRows.value.length === tableData.value.length
})

const toggleSelectAll = () => {
	if (allRowsSelected.value) {
		selectedRows.value = []
	} else {
		selectedRows.value = [ ...tableData.value ]
	}
}

const clearSelectedRows = () => {
	selectedRows.value = []
}

watch(selectedRows, (newValue) => {
	emit('selection-change', newValue)
})

watch(userSelectedPerPage, () => {
	clearSelectedRows()
})

watch(displayedColumns, () => {
	emit('columns-change', displayedColumns.value)
})

watch(batchSelect, () => {
	// if batch select is enable, initially select all rows
	if (!batchSelect.value) {
		clearSelectedRows()
	} else {
		toggleSelectAll()
	}
}, { immediate: true })

const { sortTable } = useTableSorting()

const handleSort = (column: TableColumn | undefined) => {
	if (column) {
		if (isApiPagination.value) {
			activeSortColumnKey.value = column.key
			sortOrder.value = sortOrder.value === 'ASC' ? 'DESC' : 'ASC'
			emit('sort-api', { sort: column.key, sortDirection: sortOrder.value })
		} else {
			sortTable({ column, activeSortColumnKey, sortOrder, tableData })
			paginatedTableData.value = getPaginatedTableData(filteredTableData.value)
		}
	}
}

const handleFilterChange = (id: string) => {
	activeFilterKey.value = id
}

const formatCell = (value: any, formatter?: (value: any, row: TableRow) => string, row?: TableRow) => {
	return formatter ? formatter(value, row || {}) : value
}

const isLastRow = (row: TableRow) => {
	return row.id === paginatedTableData.value?.[paginatedTableData.value?.length - 1]?.id
}

const resetColumnSelect = () => {
	selectedColumnKeys.value = defaultColumns.value.map(column => column.key)
}

const addOrRemoveColumn = (columnKey: string) => {
	if (selectedColumnKeys.value.includes(columnKey)) {
		selectedColumnKeys.value = selectedColumnKeys.value.filter(key => key !== columnKey)
	} else {
		selectedColumnKeys.value = [ ...selectedColumnKeys.value, columnKey ]
	}
}

const addOrRemoveFilter = (filterId: string) => {
	if (activeMultiFilterIds.value.includes(filterId)) {
		activeMultiFilterIds.value = activeMultiFilterIds.value.filter(id => id !== filterId)
	} else {
		activeMultiFilterIds.value = [ ...activeMultiFilterIds.value, filterId ]
	}
}

const addOrRemovePresetOptions = (optionId: string, filter?: PresetFilter) => {
	activeMultiFilterIds.value = activeMultiFilterIds.value.filter((filterId: string) => filter?.options?.find((filter: PresetFilter) => filter.id === filterId))
	if (optionId === 'All') {
		clearPresetOptions(filter?.options || [])
		return
	}
	if (activeMultiFilterIds.value.includes(optionId)) {
		activeMultiFilterIds.value = activeMultiFilterIds.value.filter(id => id !== optionId)
		return
	}
	if (!filter?.multiSelect) {
		activeMultiFilterIds.value = [ ...activeMultiFilterIds.value.filter(id => !filter?.options?.find(option => option.id === id)), optionId ]
	} else {
		activeMultiFilterIds.value = [ ...activeMultiFilterIds.value, optionId ]
	}
}

const addOrRemovePreset = ({ filters, columnIds }: {filters?: TableFilter[], columnIds?: string[]}) => {
	if (showPresetActive({ filters })) {
		clearActiveFilters()
		resetColumnSelect()
	} else {
		clearActiveFilters()
		filters?.forEach((filter) => {
			activeMultiFilterIds.value = [ ...activeMultiFilterIds.value, filter.id ]
		})
		columnIds?.length ? selectedColumnKeys.value = columnIds : resetColumnSelect()
	}
}
const allFilterIds = computed(() => {
	return filters.value.map(({ id }) => id)
})
const showPresetActive = ({ filters: activeFilters }: {filters?: TableFilter[]}) => {
	// Early return if no filters are active
	if (!activeFilters) { return false }

	// Check if all active filters are in the preset list
	// Using a Set prevents duplicates and is more performant when checking for existence
	const presetFilterIds = new Set(activeMultiFilterIds.value?.filter(filterId => allFilterIds.value?.includes(filterId)))

	// Early return if the number of active filters is different from the number of preset filters
	if (activeFilters.length !== presetFilterIds.size) { return false }

	// Check if every active filter is in the preset list
	return activeFilters.every(filter => presetFilterIds.has(filter.id))
}

const showPresetOptionSelected = (optionId: string, options?: TableFilter[]) => {
	return activeMultiFilterIds.value?.includes(optionId) || (!showPresetOptionReset(options || []) && optionId === 'All')
}

const showPresetOptionReset = (options: TableFilter[]) => {
	return options
		.map(({ id }) => activeMultiFilterIds.value.includes(id))
		.some(Boolean)
}

const showPresetReset = computed(() => {
	return !multiFilterSelect.value && (activeFilterKey.value || activeMultiFilterIds.value.length)
})

const showMultiFilterReset = () => {
	return !!activeMultiFilterIds.value?.find((filter: string) => filters.value.find(f => f.id === filter))
}
const clearActiveMultiFilters = () => {
	const clearedFilters = activeMultiFilterIds.value.filter((filter) => {
		return !filters.value.find(f => f.id === filter)
	})
	activeMultiFilterIds.value = clearedFilters
}

const clearPresetOptions = (options: TableFilter[]) => {
	const clearedFilters = activeMultiFilterIds.value.filter((filter) => {
		return !options.find(f => f.id === filter)
	})
	activeMultiFilterIds.value = clearedFilters
}
const clearActiveFilters = () => {
	activeMultiFilterIds.value = []
}

clearActiveFilters()

const pageInputValid = computed(() =>
	pageInput.value > 0 && pageInput.value !== currentPage.value && pageInput.value <= totalPages.value
)

watch(pageInputBlurred, () => {
	if (pageInput.value !== currentPage.value && pageInputBlurred.value) {
		if (pageInputValid.value) {
			currentPage.value = pageInput.value
		} else {
			resetPageInput()
		}
	}
})

const resetPageInput = () => {
	if (totalPages.value === 0 || pageInput.value < 1 || !pageInputValid.value) {
		pageInput.value = currentPage.value
	}
}

const handlePageInputEnter = () => {
	if (pageInputValid.value) {
		currentPage.value = pageInput.value
	} else {
		resetPageInput()
	}
}

const nextPage = () => {
	if (currentPage.value < totalPages.value) {
		currentPage.value++
	}
}

const previousPage = () => {
	if (currentPage.value > 1) {
		currentPage.value--
	}
}

const emitOpenCreateModal = () => emit('open-create-modal')

const setQueryStringFilters = () => {
	if (!route.query.filters) {
		activeMultiFilterIds.value = []
		return
	}
	if (route.query.filters) {
		if (Array.isArray(route.query.filters)) {
			activeMultiFilterIds.value = route.query.filters as string[] || []
		} else if (route.query.filters) {
			activeMultiFilterIds.value = [ route.query.filters ]
		}
	}
}

watch(route, () => {
	setQueryStringFilters()
}, { immediate: true })

onMounted(() => {
	activeMultiFilterIds.value = defaultFilters.value?.map(({ id }) => id) || []
	setFilteredTableData()
})
</script>

<style scoped>
th {
	resize: horizontal;
	overflow: auto;
}

/* WebKit Browsers */
/* width */
::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	background: transparent;
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #6A6A6A;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #ADADAD;
}

/* Remove scrollbar buttons */
::-webkit-scrollbar-button {
	display: none;
}

/* Firefox Browsers */
* {
	scrollbar-width: thin;
	scrollbar-color: #6A6A6A transparent;
}

/* Hide the spinner (number input arrows) */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
</style>
