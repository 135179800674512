<template>
	<svg
		id="Layer_1"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 10 20"
		style="enable-background:new 0 0 10 20;"
		xml:space="preserve"
	>
		<path
			class="st0"
			d="M5,3.8c-0.6,0-1.4,0.3-1.9,0.7c-1,1-1,2.6,0,3.5c0.5,0.5,1,0.8,2,1h0c1.1,0.3,2.3,0.9,3,1.5
	c1.8,1.8,1.8,4.6,0,6.4c-0.6,0.6-1.4,1-2.2,1.2l0,0.6c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.4,0.3-0.7,0.3c-0.6,0-1-0.4-1-1l0-0.6
	c-0.8-0.2-1.6-0.6-2.2-1.2c-0.6-0.6-1.1-1.5-1.2-2.4l0,0c-0.1-0.5,0.3-1.1,0.8-1.2h0c0.5-0.1,1.1,0.3,1.2,0.8
	c0.1,0.5,0.3,0.9,0.7,1.3c1,1,2.6,1,3.5,0s1-2.6,0-3.5c-0.1-0.1-0.4-0.3-0.8-0.5c-0.4-0.2-0.8-0.3-1.2-0.4h0c-0.9-0.2-2-0.6-3-1.5
	C0,7.7,0,4.9,1.7,3.1c0.6-0.6,1.4-1,2.2-1.2V1.3c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1v0.6c0.8,0.2,1.6,0.6,2.2,1.2
	c0.9,0.9,1.3,2,1.3,3.2v0c0,0.6-0.4,1-1,1c-0.6,0-1-0.4-1-1c0,0,0,0,0,0c0-0.7-0.3-1.3-0.7-1.8C6.2,4.1,5.6,3.8,5,3.8l-0.2,0"
		/>
	</svg>
</template>

<style>
.st0 {
	fill: currentColor;
	stroke: currentColor;
	stroke-width: 0.25;
}
</style>
