<template>
	<div class="flex flex-col w-[15%] h-screen gap-0 overflow-y-hidden border-x bg-mx-gray-100 dark:bg-mx-green-900 border-mx-gray-200 dark:border-mx-green-700">
		<div
			v-for="step in steps"
			:key="step.id"
			class="flex items-center justify-center w-full h-12 px-4 first-of-type:mt-12 dark:text-mx-gray-300"
		>
			<button
				:class="[
					'flex items-center justify-center w-full h-10 rounded-lg font-semibold transition-colors duration-50 border-mx-orange',
					isDisabled(step.id) ? 'cursor-not-allowed opacity-50 hover:bg-mx-gray-200 dark:hover:bg-mx-green-800/60 dark:text-mx-gray-400' : 'hover:bg-white/80 dark:hover:bg-mx-green-700',
					{ 'bg-white dark:bg-mx-green-800 dark:text-mx-gray-200 border-l-4 shadow-sm' : step.id === currentStep }
				]"
				:disabled="isDisabled(step.id)"
				@click="handleStepClick(step)"
			>
				{{ step.label }}
			</button>
		</div>
	</div>
</template>

<script setup lang="ts">
import type { ActionWorkflowStep } from '@/components/table/types'
import { useAccounts } from '@/composables/useAccounts'
const { batchActionSuccess } = useAccounts()
const props = defineProps({
	steps: {
		type: Array as PropType<ActionWorkflowStep[]>,
		required: true
	},
	currentStep: {
		type: Number,
		required: true
	},
	completedSteps: {
		type: Array,
		required: true
	}
})

const emit = defineEmits([ 'set-step' ])

const { currentStep, completedSteps } = toRefs(props)

const handleStepClick = (step: ActionWorkflowStep) => {
	emit('set-step', step.id)
}

const isDisabled = (stepId: number) => {
	return (stepId > currentStep.value && !completedSteps.value.includes(stepId)) || batchActionSuccess.value
}
</script>
