<template>
	<div
		:class="[
			{ 'relative top-0 left-0 z-20': isExpanded },
			'border border-alert-yellow/60 mb-4 md:mt-6 lg:mt-2 px-2 lg:mb-0 flex justify-center items-center bg-alert-yellow shadow-md cursor-pointer min-h-20 pt-1 mx-6 lg:mx-8 rounded-lg'
		]"
		@click="toggleExpand"
	>
		<slot name="default">
			<div class="max-w-md text-sm font-bold text-center md:text-base md:max-w-4xl lg:text-lg">
				<div class="flex justify-center space-x-2">
					<div :class="{ 'line-clamp-1': !isExpanded }">
						{{ title }}
					</div>
				</div>
				<div
					:class="[
						{ 'line-clamp-3': !isExpanded },
						'text-xs md:text-sm'
					]"
				>
					{{ message }}
				</div>
				<div class="flex justify-center w-full">
					<ChevronDownIcon
						:class="[
							{ 'transform rotate-180': isExpanded },
							'w-5 h-5'
						]"
					/>
				</div>
			</div>
		</slot>
	</div>
</template>

<script setup lang="ts">
import type { AlertProps } from '@/types/alert'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'

const props = defineProps({
	alert: {
		type: Object as PropType<AlertProps>,
		required: true
	}
})

const { title, message } = toRefs(props.alert)

const isExpanded = ref(false)

const toggleExpand = () => {
	isExpanded.value = !isExpanded.value
}
</script>
