import { useToast } from '@/composables/useToast'
import { useErrorReporter } from '@/composables/useErrorReporter'

export function copyToClipboard () {
	const copyHyperlinkToClipboard = async (data: { linkUrl: string, displayText: string}) => {
		const { addToast } = useToast()
		const { bugsnagReport } = useErrorReporter()

		const { linkUrl, displayText } = data
		const markdown: HTMLAnchorElement = document.createElement('a')

		markdown.href = linkUrl
		markdown.textContent = displayText

		const linkHtmlString = markdown.outerHTML

		const blobHtml = new Blob([ linkHtmlString ], { type: 'text/html' })
		const blobText = new Blob([ displayText ], { type: 'text/plain' })

		const clipboardItem = new ClipboardItem({ 'text/html': blobHtml, 'text/plain': blobText })

		try {
			await navigator.clipboard.write([ clipboardItem ])
			addToast({
				title: 'Link Copied to Clipboard!',
				links: [ { text: displayText, to: linkUrl } ],
				notificationType: 'success'
			})
		} catch (err) {
			bugsnagReport({
				error: new Error(`Failed to copy link to clipboard ${displayText}`),
				showToast: true,
				toast: {
					title: 'Failed to Copy Link to Clipboard',
					message: `${displayText}`,
					notificationType: 'error'
				}
			})
		}
	}
	return {
		copyHyperlinkToClipboard
	}
}
