const formatPhone = (phone: string | undefined) => {
	if (!phone) { return '' }
	const phoneWithOnlyNumbers = phone.slice(-10).replace(/\D/g, '')

	return `(${phoneWithOnlyNumbers?.slice(0, 3)}) ${phoneWithOnlyNumbers?.slice(3, 6)}-${phoneWithOnlyNumbers?.slice(6)}`
}

const trimPhone = (phone: string) => { // Remove all non-numeric characters from phone number
	return phone.replace(/\D/g, '')
}

export { formatPhone, trimPhone }
