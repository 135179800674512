<template>
	<div
		id="account-header"
		class="relative px-4 pt-6 overflow-hidden bg-white lg:px-8 dark:bg-mx-green-800 isolate"
	>
		<!--
		<GenerativeAbstractBackground polygon-path="polygon(20% 55%, 75% 72%, 4% 14%, 83% 18%, 4% 30%, 0% 39%, 93% 39%, 60% 49%, 42% 60%, 1% 93%, 88% 68%, 10% 94%, 58% 48%, 74% 15%, 94% 41%, 20% 80%)" />
		-->
		<NuxtLink
			:to="`/accounts/${accountId}`"
			:class="[
				isPriorityInstall ? 'text-mx-yellow' : 'text-mx-gray-400 hover:text-mx-orange dark:hover:text-mx-orange',
				'font-semibold'
			]"
			target="_blank"
		>
			{{ `#${accountId}` }}
		</NuxtLink>
		<div
			v-if="!pending"
			class="flex justify-between"
		>
			<div
				:class="[
					isPriorityInstall ? 'text-mx-yellow' : 'dark:text-mx-gray-50',
					'flex items-center space-x-2'
				]"
			>
				<h2 class="h-8 text-2xl font-semibold">
					{{ fullName }}
				</h2>
				<IconWrenches
					v-if="isPriorityInstall"
					class="h-6 text-mx-yellow"
				/>
				<span
					v-if="isPriorityInstall"
					class="text-mx-yellow"
				>
					Priority Install
				</span>
				<button
					class="px-0.5 py-0.5 -mt-1 transform rounded cursor-pointer text-mx-gray-400 group-hover/row:dark:text-mx-gray-400 group/copy group-hover/row:text-mx-gray-400 dark:hover:bg-mx-gray-500 hover:bg-mx-gray-300"
					@click="copyProfileToClipboard"
				>
					<DocumentDuplicateIcon class="h-4 group-hover/copy:text-white group-hover/copy:dark:text-mx-gray-300" />
				</button>
			</div>
			<div class="flex space-x-2">
				<TicketingButtonCreate
					v-if="accountId"
					:account-id="accountId"
					:account-name="fullName"
				/>
				<LinkButton
					v-if="canImpersonate"
					:to="`/accounts/${accountId}/temporary-password`"
					text="Create Temporary Password"
				/>
			</div>
		</div>
		<div
			v-else
			class="flex h-8 px-4 py-2 space-x-4 animate-pulse"
		>
			<div class="w-32 h-4 rounded-full bg-mx-gray-200 dark:bg-mx-green-700" />
		</div>
		<div class="flex flex-col my-4 dark:text-mx-gray-300">
			<div
				v-if="!pending"
				class="flex flex-wrap justify-start mb-4"
			>
				<span
					v-for="(card, index) in cards"
					:key="card"
					class="px-3 pt-1 pb-1 mb-2 mr-4 bg-white border rounded-lg h-9 border-mx-gray-300 border-1 dark:bg-mx-green-800 dark:border-mx-green-700"
				>
					<span
						v-if="!index"
						class="inline-block w-2.5 h-2.5 mr-1 rounded-full"
						:class="card === 'Active' ? 'bg-green-500' : 'bg-red-500'"
					/>
					{{ card }}
				</span>
				<div
					v-if="!hasHadRecentActivationNotice"
					class="flex"
				>
					<FormButton
						v-if="showActivationReminderButton"
						:loading="loading"
						@click="openModal = true"
					>
						Send Activation Reminder
					</FormButton>
				</div>
			</div>
			<div
				v-else
				class="h-[60px] animate-pulse py-2 px-4 flex space-x-4"
			>
				<div class="w-32 h-4 rounded-full bg-mx-gray-200 dark:bg-mx-green-700" />
				<div class="w-32 h-4 rounded-full bg-mx-gray-200 dark:bg-mx-green-700" />
			</div>
			<div class="flex flex-col items-center justify-between gap-4 max-w-7xl lg:space-y-0 lg:flex-row lg:flex-wrap">
				<div class="flex">
					<IconPin class="h-6 text-mx-gray-400 dark:text-mx-gray-300" />
					<span
						v-if="!pending"
						class="ml-2"
					>
						{{ formattedAddress }}
					</span>
					<span
						v-else
						class="px-4 py-1.5 animate-pulse"
					>
						<div class="w-64 h-2.5 bg-mx-gray-200 rounded-full dark:bg-mx-green-700" />
					</span>
				</div>
				<div class="flex">
					<IconPhoneSimple class="h-6 text-mx-gray-400 dark:text-mx-gray-300" />
					<span
						v-if="!pending && user?.phone"
						class="ml-2"
					>
						<a :href="`tel:${user.phone}`">
							{{ formatPhone(user?.phone) }}
						</a>
					</span>
					<span
						v-else
						class="px-4 py-1.5 animate-pulse"
					>
						<div class="w-64 h-2.5 bg-mx-gray-200 rounded-full dark:bg-mx-green-700" />
					</span>
				</div>
				<div class="flex">
					<IconMail class="h-6 text-mx-gray-400 dark:text-mx-gray-300" />
					<span
						v-if="!pending"
						class="ml-2"
					>
						{{ user?.email }}
					</span>
					<span
						v-else
						class="px-4 py-1.5 animate-pulse"
					>
						<div class="w-64 h-2.5 bg-mx-gray-200 rounded-full dark:bg-mx-green-700" />
					</span>
				</div>
				<div class="py-2 lg:py-0">
					<FormLabeledSwitch
						v-if="!pending"
						id="ignore-overdue-toggle"
						v-model="ignoreOverdue"
						on-label="Ignore Overdue Emails"
						off-label="Send Overdue Emails"
						button-width="w-80"
						button-height="h-7"
						font-size="text-xs"
						track-color="bg-mx-gray-100 dark:bg-mx-green-900"
					/>
					<span
						v-else
						class="py-4 animate-pulse"
					>
						<div class="rounded w-80 h-7 bg-mx-gray-200 dark:bg-mx-green-700" />
					</span>
				</div>
			</div>
		</div>
		<!-- TODO: make this better, currently only passing account id on static page which overrides the nav so that it doesn't route -->
		<AccountNavigation
			id="account-navigation"
			:account-id="staticPage ? accountId : ''"
			:static-page="staticPage"
		/>
		<ModalConfirmSave
			:open-modal="openModal"
			title="Send Activation Reminder"
			description="The customer will be sent an email with a link to activate their account."
			warning="Are you sure!?"
			@confirm="handleConfirm"
			@cancel="openModal = false"
		/>
		<ModalConfirmSave
			:open-modal="openIgnoreOverdueModal"
			:title="ignoreOverdueTitle"
			:description="ignoreOverdueDescription"
			@confirm="toggleIgnoreOverdue"
			@cancel="handleCancelIgnoreOverdue"
		/>
	</div>
</template>

<script setup lang="ts">
import type { MappedAccountData } from '@/components/account/types'
import { formatPhone } from '@/utils/formatPhone'
import { API_BASE, API_ENDPOINT_PORTAL_SEND_ACTIVATION_REMINDER } from '@/constants/index.js'
import { API_ENDPOINT_PORTAL_IGNORE_OVERDUE } from '@/constants/api/editAccountDetails'

import { useToast } from '@/composables/useToast'
import { useErrorReporter } from '@/composables/useErrorReporter'
import { useNotes } from '@/composables/useNotes'
import { HOUR_IN_MS, HOURS_IN_DAY } from '@/constants/time'
import { DocumentDuplicateIcon } from '@heroicons/vue/20/solid'
import { PORTAL_BASE } from '@/constants/index.js'
import { copyToClipboard } from '@/composables/copyLinkToClipboard'
import { useAuth } from '@/composables/useAuth'

const rightNow = new Date()
const { lastActivationInviteNote, getNotes } = useNotes()
const { copyHyperlinkToClipboard } = copyToClipboard()

const hasHadRecentActivationNotice = computed(() => {
	if (typeof lastActivationInviteNote?.value?.created.getTime === 'undefined') {
		return false
	}
	const hoursSinceLastSend = Math.floor((rightNow.getTime() - lastActivationInviteNote.value?.created.getTime()) / HOUR_IN_MS)
	return hoursSinceLastSend < HOURS_IN_DAY
})

const { addToast } = useToast()
const { bugsnagReport } = useErrorReporter()

const props = defineProps({
	user: {
		type: Object as PropType<MappedAccountData | null>,
		default: null
	},
	pending: {
		type: Boolean,
		default: false
	},
	staticPage: { // use for mini profile preview without routing
		type: Boolean,
		default: false
	}
})

const emit = defineEmits([ 'refresh' ])
const { user } = toRefs(props)

const loading = ref(false)
const openModal = ref(false)

const openIgnoreOverdueModal = ref(false)
const ignoreOverdue = ref(false)
const userLoaded = ref(false)
const showIgnoreOverdueModal = computed(() => {
	return ignoreOverdue.value !== !!user.value?.ignoreOverdue
})

watch(user, () => {
	ignoreOverdue.value = !!user.value?.ignoreOverdue
	userLoaded.value = true
}, { immediate: true, deep: true })

watch(ignoreOverdue, () => {
	if (showIgnoreOverdueModal.value && userLoaded.value) {
		openIgnoreOverdueModal.value = true
	}
})

const ignoreOverdueTitle = computed(() => {
	if (ignoreOverdue.value && showIgnoreOverdueModal.value) {
		return 'Stop sending overdue emails?'
	} else if (!ignoreOverdue.value && showIgnoreOverdueModal.value) {
		return 'Start sending overdue emails?'
	}
})

const ignoreOverdueDescription = computed(() => {
	if (ignoreOverdue.value && showIgnoreOverdueModal.value) {
		return 'Are you sure you want to ignore overdue emails to this account?'
	} else if (!ignoreOverdue.value && showIgnoreOverdueModal.value) {
		return 'Are you sure you want to send overdue emails to this account?'
	}
})

const handleCancelIgnoreOverdue = () => {
	ignoreOverdue.value = !!user.value?.ignoreOverdue
	openIgnoreOverdueModal.value = false
}

const toggleIgnoreOverdue = async () => {
	if (!user.value) { return }
	loading.value = true

	try {
		await $fetch(`${API_BASE}${API_ENDPOINT_PORTAL_IGNORE_OVERDUE.replace('{account-id}', user.value.accountId.toString())}`, {
			method: 'PUT',
			credentials: 'include',
			onResponse ({ response }) {
				if (!response?.ok) {
					throw new Error(response._data?.error || response._data?.errors?.[0]?.title)
				}
				addToast({
					title: 'Account Updated',
					message: `Past due emails will be ${ignoreOverdue.value ? 'disabled' : 'enabled'} for this account`,
					notificationType: 'success'
				})
			}
		})
	} catch (e) {
		bugsnagReport({
			error: e instanceof Error ? e : new Error('toggleIgnoreOverdue - catch: ' + (e as Error).toString()),
			showToast: true,
			toast: {
				title: 'Error',
				message: e instanceof Error ? e.message : 'Error updating account',
				notificationType: 'error'
			},
			user: { id: user.value.accountId.toString(), name: fullName.value, emailId: user.value?.emailId.toString() || '' }
		})
	} finally {
		loading.value = false
		openIgnoreOverdueModal.value = false
		userLoaded.value = false
		emit('refresh')
	}
}

const showActivationReminderButton = computed(() => {
	return user.value?.accountStatus === 'Pending Acceptance' && !loading.value
})

const formattedAddress = computed(() => {
	if (!user.value?.address) { return '' }
	return `${user.value?.address}, ${user.value?.city}, ${user.value?.state} ${user.value?.zip}`
})

const cards = computed(() => {
	if (!user.value) { return [] }
	return [ user.value?.accountStatus, user.value?.area ]
})
const fullName = computed(() => {
	if (!user.value) { return '' }
	return `${user.value?.firstName || ''} ${user.value?.lastName || ''}`
})

const accountId = computed(() => {
	return user.value?.accountId.toString() || ''
})

const isPriorityInstall = computed(() => {
	return user.value?.serviceDetails?.priorityInstall && !user.value?.serviceDetails?.insideInstalled
})

const handleConfirm = async () => {
	await handleSendActivationReminder()
	openModal.value = false
}

const handleSendActivationReminder = async () => {
	if (!accountId.value) { return }
	loading.value = true

	try {
		await $fetch(`${API_BASE}${API_ENDPOINT_PORTAL_SEND_ACTIVATION_REMINDER.replace('{account-id}', accountId.value)}`, {
			method: 'POST',
			credentials: 'include',
			onResponse ({ response }) {
				if (!response?.ok) {
					throw new Error(response._data?.error || response._data?.errors?.[0]?.title)
				}
				addToast({
					title: 'Success',
					message: 'An activation reminder has been sent to the user',
					notificationType: 'success'
				})
				getNotes(accountId.value, true)
			}
		})
	} catch (e) {
		bugsnagReport({
			error: e instanceof Error ? e : new Error('getInvoices - catch: ' + (e as Error).toString()),
			showToast: true,
			toast: {
				title: 'Error',
				message: e instanceof Error ? e.message : 'Error sending activation reminder',
				notificationType: 'error'
			},
			user: { id: accountId.value, name: fullName.value, emailId: user.value?.email || '' }
		})
	} finally {
		loading.value = false
	}
}

const copyProfileToClipboard = () => {
	const linkUrl = `${PORTAL_BASE}/accounts/${accountId.value}`
	const displayText = `${accountId.value} | ${fullName.value} | ${user.value?.address} | ${user.value?.area} | tel:${formatPhone(user.value?.phone)}`
	copyHyperlinkToClipboard({ linkUrl, displayText })
}

const { userData } = useAuth()

const canImpersonate = computed(() => {
	return userData.value?.permissions?.includes('impersonate.user')
})
</script>
