import type { AccountStatus, InConversionStatus, TaskStatus } from '@/types/account-statuses'

const LEAD = 'Lead'
const PROSPECT = 'Prospect'
const OPPORTUNITY = 'Opportunity'
const PENDING_INSTALL = 'Pending Install'
const PENDING_ACCEPTANCE = 'Pending Acceptance'
const ACTIVE = 'Active'
const INACTIVE = 'Inactive'
const IGNORE = 'Ignore'
const FAILED_INSTALL = 'Failed Install'

const COMPLETED = 'completed'
const NEEDED = 'needed'
const SCHEDULED = 'scheduled'

const TASK_STATUSES = [ COMPLETED, NEEDED, SCHEDULED ] as const

const TASK_STATUS_COLORS: Record<TaskStatus, string> = {
	[COMPLETED]: '#006DFF', // 50% opacity
	[NEEDED]: '#FB2702', // 50% opacity
	[SCHEDULED]: '#E9A337' // 75% opacity
}

// The "as const" assertion ensures that each status is treated as a literal type.
const ACCOUNT_STATUSES = [
	LEAD,
	PROSPECT,
	OPPORTUNITY,
	PENDING_INSTALL,
	PENDING_ACCEPTANCE,
	ACTIVE,
	INACTIVE,
	IGNORE,
	FAILED_INSTALL
] as const

const IN_CONVERSION_STATUSES = [
	PROSPECT,
	OPPORTUNITY,
	PENDING_INSTALL,
	PENDING_ACCEPTANCE
] as const

const ACCOUNT_STATUS_COLORS: Record<AccountStatus, string> = {
	[LEAD]: '#006DFF', // New
	[PROSPECT]: '#3E89ED', // In progress
	[OPPORTUNITY]: '#B336FF', // In progress with a pending action
	[PENDING_INSTALL]: '#FB0278', // Increasing urgency (pending install)
	[PENDING_ACCEPTANCE]: '#FFCC80', // More urgent (pending acceptance)
	[ACTIVE]: '#2ACA10', // Finished
	[INACTIVE]: '#000000',
	[IGNORE]: '#555555',
	[FAILED_INSTALL]: '#800020'
}

export {
	LEAD,
	PROSPECT,
	OPPORTUNITY,
	PENDING_INSTALL,
	PENDING_ACCEPTANCE,
	ACTIVE,
	INACTIVE,
	IGNORE,
	FAILED_INSTALL,
	ACCOUNT_STATUSES,
	ACCOUNT_STATUS_COLORS,
	IN_CONVERSION_STATUSES,
	COMPLETED,
	NEEDED,
	SCHEDULED,
	TASK_STATUSES,
	TASK_STATUS_COLORS
}

export type { AccountStatus, InConversionStatus }
