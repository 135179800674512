interface AlertApiResponse {
	id: number
	title: string
	message: string
	active: boolean
	createTime: string
	updated: string
	deletedAt: string
}

interface MappedAlert {
	id: string
	title: string
	message: string
	active: boolean
	created: string
	updated: string
	deleted: string
}

interface AlertProps {
	message: string
	title: string
	active: boolean
}

export const isAlertResponseValid = (obj: unknown): obj is AlertProps[] => (
	Array.isArray(obj) && obj.every(item =>
		typeof item.message === 'string' &&
		typeof item.title === 'string' &&
		typeof item.active === 'boolean'
	)
)

export type { AlertProps, AlertApiResponse, MappedAlert }
