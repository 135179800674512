<template>
	<div>
		<Header id="header" />
		<NavSideBar
			id="nav"
			v-model="sideBarCollapsed"
			class="fixed top-0 hidden h-full w-60 md:block"
		/>

		<div
			class="z-10 h-auto min-h-screen pt-8 pb-0 overflow-x-hidden transition-all lg:pt-0 bg-mx-gray-100 dark:bg-mx-green-900"
			:class="sideBarCollapsed ? 'md:ml-[70px]' : 'md:ml-60'"
		>
			<AccountHeader
				:pending="loading"
				:user="accountData"
				@refresh="refetchAccount"
			/>
			<slot name="sub-nav">
				<InternalAccountBillingNavigation v-if="showBillingNav" />
			</slot>
			<slot />
		</div>
		<slot name="invoice-slider" />
	</div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import { useAccount } from '@/composables/useAccount'

const route = useRoute()

const { accountData, getAccount, refetchAccount, loading } = useAccount()

onMounted(() => {
	getAccount(route.params?.accountId as string)
})

watch(() => route.params?.accountId, (newValue) => {
	getAccount(newValue as string)
})

const sideBarCollapsed = ref(false)

const showBillingNav = computed(() => {
	const routeName = route?.name as string
	return !!routeName?.includes('internal-accounts-accountId-billing')
})
</script>
