<template>
	<NavMobileSlideOver
		justify-left
		background-color="bg-white dark:bg-mx-green-900"
		show-background-overlay
		class="overflow-y-scroll"
	>
		<template #header>
			<BrandingIcon class="h-8 mx-6 mt-3 w-9" />
		</template>
		<template #content>
			<div class="mx-6">
				<NavListSection
					v-for="section in navigation"
					:key="section.title"
					:nav-section="section"
					@nav-link-clicked="handleNavigationClick"
				/>
				<NavLanguageToggle v-if="isAccountPortal" />
				<NavColorModeToggle />
			</div>
		</template>
	</NavMobileSlideOver>
</template>

<script setup lang="ts">
import { getNavLinks } from '@/composables/getNavLinks'
import { isAccountPortal } from '@/composables/useIsAccountPortal'

const { navigation } = getNavLinks()

const emit = defineEmits([ 'nav-link-clicked' ])
const handleNavigationClick = () => {
	emit('nav-link-clicked')
}
</script>
