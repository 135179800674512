<template>
	<div>
		<BatchActionWorkflowStepHeader>
			<template #title>
				Confirmation: Step {{ stepNumber }} of {{ totalSteps }}
			</template>
			<template #description>
				Confirm your selected batch action on the selected accounts.
			</template>
			<template #content>
				<div class="pb-4 text-left">
					<h3 class="flex items-start text-lg font-semibold dark:text-mx-gray-300">
						<span class="w-24">
							Action:
						</span>
						<strong class="font-bold text-mx-orange">
							{{ action.label }}
						</strong>
					</h3>
					<h4 class="flex items-center text-lg font-semibold dark:text-mx-gray-300">
						<span class="w-24">
							Method:
						</span>
						<strong class="font-bold text-mx-orange after:content-['|'] after:text-mx-gray-400 dark:after:text-mx-green-700 after:pl-4 after:font-semibold">
							{{ activeActionOption.label }}
						</strong>
						<span
							class="pl-4 text-sm text-mx-gray-500 dark:text-mx-gray-400"
							v-html="activeActionOption.description"
						/>
					</h4>
				</div>
				<div class="flex items-center justify-start w-full py-4 rounded-lg bg-mx-orange/20">
					<div class="flex items-start gap-4">
						<ExclamationCircleIcon class="my-auto ml-4 w-7 h-7 text-mx-orange" />
						<div class="flex flex-col gap-1">
							<div class="text-sm font-semibold text-mx-orange">
								Notice
							</div>
							<div class="text-sm">
								Accounts that have been notified recently (within 1 week) will not recieve this notification.
							</div>
						</div>
					</div>
				</div>
			</template>
		</BatchActionWorkflowStepHeader>
		<TableAccountsBatchAction
			v-if="!batchActionLoading && !batchActionSuccess && !batchActionError"
			:table-data="tableData"
			:default-columns="defaultColumns"
			:action="action"
			:batch-select="false"
		>
			<template #default>
				<div class="flex items-center justify-start w-full pb-4 bg-mx-gray-100 dark:bg-mx-green-900">
					<div class="flex items-center gap-4">
						<FormButton
							id="submit-button"
							button-text="Submit"
							:variant="disabled ? 'custom' : 'primary'"
							button-width="w-32"
							:disabled="disabled"
							submit
							:class="{ 'border-2 border-transparent dark:border-mx-green-800 cursor-not-allowed bg-mx-gray-200 dark:bg-mx-green-800' : disabled }"
							@submit="handleSubmit"
						/>
						<FormButton
							id="cancel-button"
							button-text="Cancel"
							variant="secondary-background"
							button-width="w-32"
							@click="handleClose"
						/>
					</div>
				</div>
			</template>
		</TableAccountsBatchAction>
		<div
			v-if="batchActionLoading"
			class="flex flex-col justify-center w-full h-full text-center text-mx-gray-400 dark:text-mx-gray-500"
		>
			<div class="max-w-sm m-auto">
				<LoadingSpinner class="w-full" />
				<div class="pt-12 text-lg">
					Loading...
				</div>
			</div>
		</div>
		<div
			v-if="!batchActionLoading && (batchActionSuccess || batchActionError)"
			class="flex flex-col justify-center w-full h-full"
		>
			<div class="flex flex-col items-center justify-center w-full gap-4 font-semibold h-96">
				<div class="pb-8 text-2xl lg:text-4xl">
					{{ batchActionSuccess ? 'Action Completed!' : 'Action Failed' }}
				</div>
				<div
					v-if="skippedAccounts.length"
					class="pb-6"
				>
					<div class="text-lg">
						{{ skippedAccounts.length }} accounts were skipped.
					</div>
					<div class="text-sm">
						These accounts have been notified recently (within 1 week) and will not recieve this notification.
					</div>
					<ul class="flex flex-wrap gap-2 pt-2">
						<NuxtLink
							v-for="(accountId, index) in skippedAccounts"
							:key="index"
							:to="`/accounts/${accountId}`"
							target="_blank"
							class="text-mx-orange hover:underline decoration-2 decoration-mx-orange hover after:content-[','] last-of-type:after:content-[''] after:ml-0.5 after:text-black dark:after:text-mx-gray-300"
						>
							{{ accountId }}
						</NuxtLink>
					</ul>
				</div>
				<FormButton
					id="done-button"
					button-text="Done"
					button-width="w-32"
					@click="handleReset"
				/>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import type { TableRow, TableColumn, BatchAction, BatchActionOption } from '@/components/table/types'
import { ExclamationCircleIcon } from '@heroicons/vue/24/outline'
import { useAccounts } from '@/composables/useAccounts'
const {
	skippedAccounts,
	batchActionError,
	batchActionLoading,
	batchActionSuccess,
	clearBatchActionState
} = useAccounts()

const props = defineProps({
	stepNumber: {
		type: Number,
		required: true
	},
	totalSteps: {
		type: Number,
		required: true
	},
	tableData: {
		type: Object as PropType<TableRow[]>,
		required: true
	},
	defaultColumns: {
		type: Array as PropType<TableColumn[]>,
		required: true
	},
	action: {
		type: Object as PropType<BatchAction>,
		required: true
	},
	activeActionOption: {
		type: Object as PropType<BatchActionOption>,
		default: () => ({
			id: '',
			label: '',
			description: '',
			endpoint: {
				url: '',
				params: {}
			}
		})
	}
})

const { activeActionOption } = toRefs(props)

const emit = defineEmits([ 'close', 'submit', 'reset' ])

const disabled = computed(() => {
	return !activeActionOption.value.id || batchActionLoading.value
})

const handleSubmit = () => {
	emit('submit')
}

const handleClose = () => {
	clearBatchActionState()
	emit('close')
}

const handleReset = () => {
	clearBatchActionState()
	emit('reset')
}
</script>
