import dashboard from './locales/pages/dashboard.json'
import contact from './locales/pages/contact.json'
import myDetails from './locales/pages/my-details.json'
import paymentMethods from './locales/pages/payment-methods.json'

import nav from './locales/components/nav.json'
import installationEvents from './locales/components/installation-events.json'
import contactForm from './locales/components/contact-form.json'
import phoneVerificationModal from './locales/components/phone-verification-modal.json'
import emailVerificationModal from './locales/components/email-verification-modal.json'
import confirmPaymentModal from './locales/components/confirm-payment-modal.json'
import invoicePaymentModal from './locales/components/invoice-payment-modal.json'
import accountSelector from './locales/components/account-selector.json'
import buttons from './locales/components/buttons.json'
import accountNotFound from './locales/components/account-not-found.json'

const components = [
	dashboard,
	contact,
	myDetails,
	nav,
	installationEvents,
	contact,
	contactForm,
	myDetails,
	paymentMethods,
	phoneVerificationModal,
	emailVerificationModal,
	confirmPaymentModal,
	invoicePaymentModal,
	accountSelector,
	buttons,
	accountNotFound
]
const en = Object.assign({},
	...components.map(({ en }) => en)
)
const es = Object.assign({},
	...components.map(({ es }) => es)
)

export default defineI18nConfig(() => ({
	legacy: false,
	globalInjection: true,
	missingWarn: false,
	messages: {
		en,
		es
	}
}))
