import { API_BASE, API_ENDPOINT_PORTAL_ACCOUNT_STATUSES } from '@/constants/index.js'
import { API_ENDPOINT_PORTAL_ACCOUNTS_UPDATE_STATUS } from '@/constants/api/editAccountDetails'
import { useErrorReporter } from '@/composables/useErrorReporter'
import { ref, computed } from 'vue'
import type { AccountStatusObject, AccountStatusBody } from '@/types/account-statuses'
import { useToast } from '@/composables/useToast'
import { useAccount } from '@/composables/useAccount'

const loading = ref(true)
const accountStatuses = ref<AccountStatusObject[]>([])
const disabledStatuses = [ 'Lead', 'Prospect', 'Opportunity', 'Pending Acceptance', 'Active' ]
const accountStatusOptions = computed(() => accountStatuses.value.map(accountStatus => ({
	id: accountStatus.id,
	name: accountStatus.status
})))
const accountStatusFilterOptions = computed(() => {
	const statusOptions = accountStatuses.value.map((status) => {
		return {
			id: `accountStatus${status.status}`,
			columnKey: 'accountStatus',
			label: status.status,
			matches: status.status
		}
	})
	return statusOptions.sort((a, b) => a.label.localeCompare(b.label))
})

export const useAccountStatus = () => {
	const { bugsnagReport } = useErrorReporter()
	const { addToast } = useToast()
	const { refetchAccount } = useAccount()

	const getAccountStatuses = async () => {
		if (accountStatuses.value.length) {
			return
		}
		loading.value = true
		await $fetch(`${API_BASE}${API_ENDPOINT_PORTAL_ACCOUNT_STATUSES}`, {
			method: 'GET',
			credentials: 'include',
			onResponse ({ response }) {
				if (!response?.ok) {
					bugsnagReport({
						error: response._data?.error || response._data?.errors?.[0] || 'Error fetching Account Status data',
						showToast: true,
						toast: {
							title: 'Error fetching account statuses',
							message: response._data?.error || response._data?.errors?.[0]
						}
					})
				}
				accountStatuses.value = response?._data
			}
		}).catch((e) => {
			bugsnagReport({
				error: e,
				showToast: true,
				toast: {
					title: 'Error fetching account status data',
					message: e
				}
			})
		}).finally(() => {
			loading.value = false
		})
	}

	const updateAccountStatus = async (accountStatusBody: AccountStatusBody) => {
		const { accountId, status, reason } = accountStatusBody

		if (!reason) {
			bugsnagReport({
				error: new Error('Reason is required'),
				showToast: true,
				toast: {
					title: 'Error updating account status',
					message: 'Reason is required'
				}
			})
			return
		}

		if (disabledStatuses.includes(status)) {
			bugsnagReport({
				error: new Error('Cannot update to disabled status'),
				showToast: true,
				toast: {
					title: 'Error updating account status',
					message: `Cannot update to disabled status: ${status}`
				}
			})
			return
		}

		loading.value = true

		await $fetch(`${API_BASE}${API_ENDPOINT_PORTAL_ACCOUNTS_UPDATE_STATUS.replace('{account-id}', accountId.toString())}`, {
			method: 'PUT',
			credentials: 'include',
			body: {
				accountId,
				status,
				reason
			},
			onResponse ({ response }) {
				if (!response?.ok) {
					bugsnagReport({
						error: response._data?.error || response._data?.errors?.[0] || 'Error updating account status',
						showToast: true,
						toast: {
							title: 'Error updating account status',
							message: response._data?.error || response._data?.errors?.[0]
						}
					})
				}
				addToast({
					notificationType: 'success',
					title: 'Account Status Updated',
					message: `Account status updated to ${status}`
				})
				refetchAccount()
			}
		}).catch((e) => {
			bugsnagReport({
				error: e,
				showToast: true,
				toast: {
					title: 'Error updating account status',
					message: e
				}
			})
		}).finally(() => {
			loading.value = false
		})
	}

	return {
		loading,
		accountStatuses,
		disabledStatuses,
		accountStatusOptions,
		accountStatusFilterOptions,
		getAccountStatuses,
		updateAccountStatus
	}
}
