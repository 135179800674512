import { API_BASE, API_ENDPOINT_PORTAL_ACCOUNTS_MACS } from '@/constants/index.js'
import { useErrorReporter } from '@/composables/useErrorReporter'
import type { MacResponse, MappedMac, DeviceStatusResponse } from '@/components/internal/account/equipment/types'
import { mapMacResponse, addStatusToMac } from '@/utils/mappers/MacsMap'
import { useAuth } from '@/composables/useAuth'
import { useNotes } from '@/composables/useNotes'
const { bugsnagReport } = useErrorReporter()

const macs = ref<MappedMac[]>([])
const loading = ref(true)
const showEmptyState = ref(false)
const lastAccountId = ref('')
const { fullName, email, emailId } = useAuth()

export function useMacs () {
	const getMacs = async (accountId: string, refetch?: boolean) => {
		if (accountId === lastAccountId.value && macs.value.length > 0 && !refetch) {
			return
		}

		lastAccountId.value = accountId
		loading.value = true

		const fullPath = `${API_BASE}${API_ENDPOINT_PORTAL_ACCOUNTS_MACS.replace('{account-id}', accountId)}`
		await $fetch(fullPath, {
			method: 'GET',
			credentials: 'include',
			onResponse ({ response }) {
				macs.value = []
				if (response.ok) {
					loading.value = false
					if (response._data?.data?.length) {
						macs.value = response._data.data.map((note: MacResponse) => mapMacResponse(note))
						showEmptyState.value = false
					} else {
						showEmptyState.value = true
					}
				} else {
					bugsnagReport({
						error: new Error(`getNotes: Failed with status ${response.status}`),
						showToast: true,
						metaData: response._data?.errors,
						user: { id: accountId, emailId: emailId.value, email: email.value, name: fullName.value }
					})
				}
			}
		}).catch((e) => {
			bugsnagReport({
				error: e instanceof Error ? e : new Error('getMacs - catch: ' + (e as Error).toString()),
				showToast: true,
				user: { id: accountId, emailId: emailId.value, email: email.value, name: fullName.value }
			})
		}).finally(async () => {
			loading.value = false
			if (macs?.value.length) {
				await Promise.all(macs.value.map(async (mac, index) => {
					if (!mac.macAddress) {
						return
					}
					await getMacDeviceStatus(mac.macAddress, index)
				}))
			}
		})
	}

	const getMacDeviceStatus = async (macAddress: string, index: number = 0) => {
		if (!macAddress) {
			bugsnagReport({
				error: new Error('getMacDeviceStatus: No macAddress provided'),
				showToast: true,
				user: { id: lastAccountId.value, emailId: emailId.value, email: email.value, name: fullName.value }
			})
			return
		}
		if (!macs.value[index]) {
			bugsnagReport({
				error: new Error('getMacDeviceStatus: No macs found at index'),
				showToast: true,
				user: { id: lastAccountId.value, emailId: emailId.value, email: email.value, name: fullName.value }
			})
			return
		}

		loading.value = true

		await $fetch(`/api/device/${macAddress}`, {
			method: 'GET',
			credentials: 'include',
			onResponse ({ response }) {
				if (response.ok) {
					const deviceStatus = response._data as DeviceStatusResponse
					macs.value[index] = addStatusToMac(macs.value[index], deviceStatus)
				} else {
					const errorMessage = response._data?.errors?.[0]?.title ?? response._data?.message ?? 'Failed to get device status'
					bugsnagReport({
						error: new Error(`getMacDeviceStatus: Failed with status ${response.status}`),
						showToast: true,
						toast: {
							title: `Error getting device status for MAC ${macAddress}`,
							message: errorMessage
						},
						metaData: {
							errorMessage
						},
						user: { id: lastAccountId.value, emailId: emailId.value, email: email.value, name: fullName.value }
					})
				}
			}
		}).catch((e) => {
			bugsnagReport({
				error: e instanceof Error ? e : new Error('getMacDeviceStatus - catch: ' + (e as Error).toString()),
				showToast: true,
				toast: {
					title: 'Error getting equipment',
					message: `Failed to get device status for MAC ${macAddress}`
				},
				user: { id: lastAccountId.value, emailId: emailId.value, email: email.value, name: fullName.value }
			})
		}).finally(() => {
			loading.value = false
		})
	}

	const setMac = async (data: { deviceName?: string, macAddress?: string, accountId: string, method?: 'POST' | 'PUT' | 'DELETE', macId?: string }) => {
		const { deviceName, macAddress, accountId, method, macId } = data

		if (!accountId) {
			bugsnagReport({
				error: new Error('setMac: No accountId provided'),
				showToast: true,
				toast: {
					notificationType: 'error',
					title: 'Error saving equipment',
					message: 'No account ID provided'
				}
			})
			return
		}

		const { getNotes } = useNotes()
		loading.value = true
		const fullPath = method === 'PUT' || method === 'DELETE'
			? `${API_BASE}${API_ENDPOINT_PORTAL_ACCOUNTS_MACS.replace('{account-id}', accountId)}/${macId}`
			: `${API_BASE}${API_ENDPOINT_PORTAL_ACCOUNTS_MACS.replace('{account-id}', accountId)}`
		const body = method === 'DELETE'
			? {}
			: {
				deviceName,
				macAddress
			}
		await $fetch(fullPath, {
			method: method ?? 'POST',
			credentials: 'include',
			body: JSON.stringify(body),
			onResponse ({ response }) {
				if (!response.ok) {
					throw new Error(`setMac: No data or failed with status ${response.status}`)
				}
				getMacs(accountId.toString(), true)
			}
		})
			.catch((e) => {
				bugsnagReport({
					error: e instanceof Error ? e : new Error('setMac - catch: ' + (e as Error).toString()),
					showToast: true,
					toast: {
						title: 'Error saving equipment',
						message: e.message
					},
					user: { id: accountId, emailId: email.value, name: fullName.value }
				})
			})
			.finally(() => {
				loading.value = false
				getNotes(accountId, true)
			})
	}
	return {
		getMacs,
		setMac,
		macs,
		loading,
		showEmptyState
	}
}
