import { useErrorReporter } from '@/composables/useErrorReporter'

export default defineNuxtPlugin((nuxtApp) => {
	const { bugsnagReport } = useErrorReporter()
	nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
		bugsnagReport({
			error: error instanceof Error ? error : new Error(error),
			metaData: {
				errorInfo: info,
				errorInstance: instance
			}
		})
	}

	nuxtApp.hook('vue:error', (error, instance, info) => {
		bugsnagReport({
			error: error instanceof Error ? error : new Error(error),
			metaData: {
				errorInfo: info,
				errorInstance: instance
			}
		})
	})
})
