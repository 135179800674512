<template>
	<div :class="wrapperClasses">
		<span class="sr-only">
			{{ screenReaderText }}
		</span>
		<div class="relative flex justify-center">
			<slot name="input">
				<input
					:id="id"
					type="checkbox"
					:class="[
						'border rounded-sm shadow-inner appearance-none checked:shadow-sm peer shrink-0 checked:border-0 focus:outline-none focus:ring-offset-0 focus:ring-2 focus:ring-mx-orange/60 disabled:border-mx-gray-300 disabled:bg-mx-gray-400',
						inputColor,
						inputSizeClasses,
						checkboxBorderColor
					]"
					:checked="checked"
					:required="required"
					@change="emit('change', $event)"
				>
				<slot name="icon">
					<svg
						:class="[
							'absolute hidden text-white transform -translate-x-1/2 -translate-y-1/2 pointer-events-none top-1/2 left-1/2 peer-checked:block',
							iconSizeClasses
						]"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 24 24"
						fill="none"
						stroke="currentColor"
						stroke-width="4"
						stroke-linecap="round"
						stroke-linejoin="round"
					>
						<polyline points="20 6 9 17 4 12" />
					</svg>
				</slot>
			</slot>
		</div>
		<label
			v-if="!hideLabel"
			:for="id"
			:class="[
				'ml-2 text-sm',
				{ 'after:content-[\'*\'] after:ml-0.5 after:text-mx-red' : required },
				labelVariant === 'danger' ? 'text-mx-red' : 'text-black dark:text-mx-gray-300',
				labelClasses
			]"
		>
			<slot name="label">
				{{ labelText }}
			</slot>
		</label>
	</div>
</template>

<script setup lang="ts">
const props = defineProps({
	id: {
		type: String,
		required: true
	},
	screenReaderText: {
		type: String,
		default: 'Select Checkbox'
	},
	checked: {
		type: Boolean,
		default: false
	},
	required: {
		type: Boolean,
		default: false
	},
	size: {
		type: String,
		default: 'sm',
		validator: (value: string) => [ 'sm', 'md', 'lg' ].includes(value)
	},
	inputSize: {
		type: String,
		default: ''
	},
	variant: {
		type: String,
		default: 'primary',
		validator: (value: string) => [ 'primary', 'secondary' ].includes(value)
	},
	iconSize: {
		type: String,
		default: ''
	},
	labelText: {
		type: String,
		default: 'Add Label Text'
	},
	labelClasses: {
		type: String,
		default: 'after:text-lg after:font-semibold'
	},
	hideLabel: {
		type: Boolean,
		default: false
	},
	labelVariant: {
		type: String,
		default: 'default',
		validator: (value: string) => [ 'default', 'danger' ].includes(value)
	},
	wrapperClasses: {
		type: String,
		default: 'flex items-center justify-center'
	},
	checkboxBorderColor: {
		type: String,
		default: 'border-mx-gray-300 dark:border-mx-green-900'
	}
})

const emit = defineEmits([ 'change' ])

const { size, inputSize, iconSize, variant } = toRefs(props)

const inputSizeClasses = computed(() => {
	if (inputSize.value) {
		return inputSize.value
	}
	switch (size.value) {
	case 'sm':
		return 'w-3.5 h-3.5'
	case 'md':
		return 'w-4 h-4'
	case 'lg':
		return 'w-5 h-5'
	default:
		return 'w-3.5 h-3.5'
	}
})

const iconSizeClasses = computed(() => {
	if (iconSize.value) {
		return iconSize.value
	}
	switch (size.value) {
	case 'sm':
		return 'w-3 h-3'
	case 'md':
		return 'w-3.5 h-3.5'
	case 'lg':
		return 'w-4 h-4'
	default:
		return 'w-3 h-3'
	}
})

const inputColor = computed(() => {
	switch (variant.value) {
	case 'primary':
		return 'bg-gray-100 hover:bg-mx-gray-200 dark:hover:bg-mx-green-600 dark:bg-mx-green-800 checked:bg-mx-orange dark:checked:bg-mx-orange/80 hover:checked:bg-mx-orange/80 dark:hover:checked:bg-mx-orange'
	case 'secondary':
		return 'bg-white hover:bg-mx-gray-100 dark:hover:bg-white/15 dark:bg-mx-green-600 checked:bg-mx-orange dark:checked:bg-mx-orange/80 hover:checked:bg-mx-orange/80 dark:hover:checked:bg-mx-orange'
	default:
		return 'bg-gray-100 hover:bg-mx-gray-200 dark:hover:bg-mx-green-600 dark:bg-mx-green-800 checked:bg-mx-orange dark:checked:bg-mx-orange/80 hover:checked:bg-mx-orange/80 dark:hover:checked:bg-mx-orange'
	}
})
</script>
