import type { AccountApiResponse, MappedAccountData, PhoneNumberApiResponse, AdditionalPhone, SignupPackage, SignupPackageResponse } from '@/components/account/types'
import { formatDate, MDY2 } from '@/utils/formatDate'

const mapAccount = (account: AccountApiResponse): MappedAccountData => {
	const {
		accountId,
		// accountName,
		// accountType,
		accountStatus,
		serviceDetails,
		activatedTime,
		accountCreatedTime,
		// taxExempt,
		address,
		owner,
		zone,
		prospectConversionSteps,
		prospectInterestBillablesSnapshot,
		portalAccountServices: accountServices,
		moveInDate,
		ignoreOverdue,
		stickyNote,
		accountContract,
		equipment,
		isDelinquent,
		hasInvoices
	} = account
	return {
		accountId,
		contactId: owner?.contactId || 0,
		title: owner?.title || '',
		firstName: owner?.first || '',
		middleName: owner?.middle || '',
		lastName: owner?.last || '',
		suffix: owner?.suffix || '',
		address: address?.address1 || '',
		city: address?.city?.name || '',
		state: address?.state.name || '',
		zip: address?.postalCode || '',
		phone: findPrimaryPhone(owner.phones),
		email: owner?.email || '',
		isActive: accountStatus?.status === 'Active',
		area: zone?.name || '',
		activatedTime: activatedTime ? new Date(activatedTime) : null,
		createdTime: accountCreatedTime ? new Date(accountCreatedTime) : null,
		serviceDetails,
		accountStatus: accountStatus?.status,
		prospectConversionSteps,
		additionalPhones: mapAdditionalPhones(owner.phones),
		signupPackage: mapSignupPackage(prospectInterestBillablesSnapshot?.[0]),
		accountServices,
		zoneId: zone?.id || '',
		moveInDate,
		hasLogin: owner?.hasLogin,
		coordinates: {
			latitude: address?.latitude,
			longitude: address?.longitude
		},
		emailId: owner?.emailId,
		ignoreOverdue,
		stickyNote,
		contractId: accountContract?.contractId,
		contractDateRange: accountContract?.contractStartDate ? `${formatDate(accountContract?.contractStartDate, MDY2)} - ${formatDate(accountContract?.contractEndDate, MDY2)}` : 'N/A',
		contractActive: accountContract?.contractActive,
		contractInstalledPrice: accountContract?.contractInstallPrice,
		staticIpsCount: accountContract?.staticIpsCount,
		voipNumbersCount: accountContract?.voipNumbersCount,
		equipment,
		isDelinquent,
		hasInvoices
	}
}

const findPrimaryPhone = (phones: PhoneNumberApiResponse[]): string => {
	return phones?.find((phone: PhoneNumberApiResponse) => phone.isPrimary)?.number || ''
}

const mapAdditionalPhones = (additionalPhones: PhoneNumberApiResponse[]): AdditionalPhone[] => {
	return additionalPhones.filter(phone => !phone.isPrimary).map((phone): AdditionalPhone => {
		return {
			id: phone?.phoneId.toString(),
			isPrimary: !!phone.isPrimary,
			number: phone.number,
			label: phone.label
		}
	})
}

const mapSignupPackage = (signupPackage: any): SignupPackage | null => {
	if (!signupPackage) {
		return null
	}
	const { billableId: id, billablePackageSpeed: speed, billablePrice: price, billableUnit: unit }: SignupPackageResponse = signupPackage
	return { id, speed, price, unit }
}

export { mapAccount, mapAdditionalPhones }
