import type { AccountApiResponse, PhoneNumberApiResponse, AccountConstructionEstimate } from '@/components/account/types'
import type { TableFilter, TableRow } from '@/components/table/types'
import { formatPhone } from '@/utils/formatPhone'
import type { MappedZone } from '@/components/zones/types'
import type { MapAccountsListResponseCustomer, MappedCustomer } from '@/composables/useAccounts'
import {
	LEAD,
	PROSPECT,
	OPPORTUNITY,
	PENDING_INSTALL,
	PENDING_ACCEPTANCE,
	ACTIVE,
	INACTIVE,
	IGNORE,
	ACCOUNT_STATUS_COLORS
} from '@/constants/accounts/statuses'
import type { AccountStatus } from '@/types/account-statuses'

const mapAccountsTable = (accounts: AccountApiResponse[]): TableRow[] => {
	return accounts?.map(({
		accountId,
		accountStatus,
		owner,
		address,
		serviceDetails,
		zone,
		moveInDate,
		accountCreatedTime,
		ignoreOverdue,
		stickyNote,
		activatedTime,
		equipment,
		isDelinquent
	}) => {
		return {
			id: accountId?.toString(),
			name: `${owner?.first || ''} ${owner?.last || ''}`,
			address: `${address?.address1}` || '',
			email: owner?.email || '',
			phone: getPrimaryPhone(owner?.phones) || '',
			accountStatus: accountStatus || '',
			neighborhood: zone?.name || '',
			priorityInstall: serviceDetails?.priorityInstall?.toString() || 'false',
			cableSpliced: serviceDetails?.cableSpliced?.toString() || 'false',
			cablePulled: serviceDetails?.cablePulled?.toString() || 'false',
			cableTerminated: serviceDetails?.cableTerminated?.toString() || 'false',
			insideInstalled: serviceDetails?.insideInstalled?.toString() || 'false',
			insideScheduledDate: formatDate(serviceDetails?.insideScheduledDate),
			insideScheduledTime: serviceDetails?.insideScheduledTime || '',
			outsideInstalled: serviceDetails?.outsideInstalled?.toString() || 'false',
			siteSurveyed: serviceDetails?.surveyed?.toString() || 'false',
			surveyScheduledDate: formatDate(serviceDetails?.surveyScheduledDate),
			surveyScheduledTime: serviceDetails?.surveyScheduledTime || '',
			surveyStatus: getSurveyStatus(serviceDetails),
			insideInstallStatus: getInsideInstallStatus(serviceDetails),
			moveInDate: formatDate(moveInDate),
			accountCreatedTime: formatDate(accountCreatedTime),
			hasLogin: owner?.hasLogin?.toString() || 'false',
			ignoreOverdue: formatDate(ignoreOverdue),
			rowClasses: highlightPriorityInstall(accountStatus, serviceDetails) ? 'text-[#E9A337]' : '',
			stickyNote: stickyNote || '',
			activatedTime: formatDate(activatedTime),
			mainlineCompleted: (zone?.constructionEstimates?.find((estimate: AccountConstructionEstimate) => estimate?.title === 'mainline')?.text === 'completed').toString() || 'false',
			hasEquipment: equipment?.length ? 'true' : 'false',
			navigateTo: `/accounts/${accountId?.toString()}`,
			isDelinquent: isDelinquent?.toString() || 'false'
		}
	})
}

const highlightPriorityInstall = (accountStatus: AccountStatus, serviceDetails: AccountApiResponse['serviceDetails']): boolean => {
	return serviceDetails?.priorityInstall && !serviceDetails?.insideInstalled && accountStatus?.toLowerCase() !== INACTIVE.toLowerCase()
}

const getSurveyStatus = (serviceDetails: AccountApiResponse['serviceDetails']): string => {
	if (serviceDetails?.surveyed) { return 'completed' }
	if (serviceDetails?.surveyScheduledDate) { return 'scheduled' }
	return 'needed'
}

const getInsideInstallStatus = (serviceDetails: AccountApiResponse['serviceDetails']): string => {
	if (serviceDetails?.insideInstalled) { return 'completed' }
	if (serviceDetails?.insideScheduledDate) { return 'scheduled' }
	return 'needed'
}

const getPrimaryPhone = (phones: PhoneNumberApiResponse[]): string => {
	const primaryPhone = phones?.find(phone => phone?.isPrimary)
	return formatPhone(primaryPhone?.number) || ''
}

const mapZonesToNeighborhoodOptions = (zones: MappedZone[]): TableFilter[] => {
	if (!zones.length) { return [] }
	const parentZones = zones?.filter(zone => zone?.parentZoneId === '0')?.map(zone => ({ id: zone.id, name: zone?.name, count: zone?.addressCount }))
	const parentNeighborHoods: TableFilter[] = Array.from(new Set(parentZones))?.map(parentZone => ({
		id: parentZone.name || '',
		value: parentZone.id || '',
		label: `${parentZone.name} (${parentZone.count})` || '',
		columnKey: 'neighborhood',
		matches: parentZone.name || '',
		children: []
	}))
	const childZones = zones?.filter(zone => zone?.parentZoneId !== '0').map(zone => ({ id: zone.id, name: zone?.name, count: zone?.addressCount, parentId: zone?.parentZoneId }))
	childZones?.forEach((zone) => {
		const parentZone = parentNeighborHoods.find(parent => parent.value === zone?.parentId)
		if (parentZone?.children) {
			parentZone.children.push({
				id: zone.name || '',
				value: zone.id || '',
				label: `${zone.name} (${zone.count})` || '',
				columnKey: 'neighborhood',
				matches: zone.name || ''
			})
		}
	})
	return parentNeighborHoods?.sort((a, b) => a.label.localeCompare(b.label))
}

const mapCustomerMapPoints = (response: MapAccountsListResponseCustomer[]) : MappedCustomer[] => {
	const accounts = response?.map(({
		accountId,
		accountStatus,
		latitude,
		longitude,
		zoneId,
		siteSurveyStatus,
		insideInstallStatus,
		outsideInstallStatus,
		cableStatuses,
		templineStatus,
		hasEquipment
	}) => {
		return {
			id: accountId?.toString(),
			accountStatus: (accountStatus || '') as AccountStatus,
			position: { lat: parseFloat(latitude), lng: parseFloat(longitude) },
			color: ACCOUNT_STATUS_COLORS[accountStatus as keyof typeof ACCOUNT_STATUS_COLORS],
			zIndex: getAccountZIndex(accountStatus),
			zoneId: zoneId?.toString(),
			siteSurveyStatus,
			insideInstallStatus,
			outsideInstallStatus,
			cablePulled: cableStatuses.pulled,
			cableSpliced: cableStatuses.spliced,
			cableTerminated: cableStatuses.terminated,
			templineStatus,
			hasEquipment
		}
	})
	return accounts
}

const getAccountZIndex = (accountStatus: string): number => { // TODO: Refactor these when we know what the priority is
	switch (accountStatus) {
	case LEAD:
		// Z-index 1 for lead status
		return 1
	case PROSPECT:
		// Z-index 2 for prospect status
		return 2
	case OPPORTUNITY:
		// Z-index 3 for opportunity status
		return 3
	case PENDING_INSTALL:
		// Z-index 4 for pending install status
		return 4
	case PENDING_ACCEPTANCE:
		// Z-index 5 for pending acceptance status
		return 5
	case ACTIVE:
		// Z-index 6 for active status
		return 6
	case INACTIVE:
		// Z-index 7 for inactive status
		return 7
	case IGNORE:
		// Z-index 8 for ignore status
		return 8
	default:
		// Z-index 9 for unknown status
		return 9
	}
}

export { mapAccountsTable, mapZonesToNeighborhoodOptions, mapCustomerMapPoints }
