<template>
	<Modal
		:is-open="openModal"
		:confirm-disabled="confirmDisabled"
		@on-confirm="handleCreateTicket"
		@on-close="closeModal"
	>
		<template #body>
			<form class="py-4 space-y-6 dark:text-mx-gray-400">
				<div class="space-y-4">
					<section>
						<h3 class="text-lg font-semibold dark:text-mx-gray-300">
							Assignee
						</h3>
						<div class="flex flex-col gap-4 sm:flex-row">
							<div class="flex-1">
								<label
									for="accountserviceRep"
									class="block text-sm font-medium text-mx-green-700 dark:text-mx-gray-400"
								>
									Account Service Representative
								</label>
								<select
									id="accountserviceRep"
									v-model="formData.assignee.id"
									class="block w-full py-2 pl-3 mt-1 text-base border rounded-md border-mx-gray-300 dark:text-mx-gray-400 dark:bg-mx-green-800 dark:border-mx-green-700 focus:outline-none focus:ring-mx-orange focus:border-mx-orange sm:text-sm"
								>
									<option
										value=""
										selected
									>
										Unassigned
									</option>
									<option
										v-for="option in accountServiceRepOptions"
										:key="option.id"
										:value="option.id"
									>
										{{ option.name }}
									</option>
								</select>
							</div>
							<div class="flex-1">
								<label
									for="account"
									class="block text-sm font-medium text-mx-green-700 dark:text-mx-gray-400"
								>
									Account
								</label>
								<input
									id="account"
									:value="formData.accountName"
									readonly
									tabindex="-1"
									class="block w-full py-2 pl-3 mt-1 text-base bg-transparent border-none rounded-md border-mx-gray-300 text-mx-gray-400 dark:bg-transparent dark:border-mx-green-700 focus:outline-none focus:ring-transparent focus:border-transparent sm:text-sm"
									@click.prevent="$event.preventDefault()"
								>
							</div>
						</div>
					</section>

					<section>
						<h3 class="text-lg font-semibold dark:text-mx-gray-300">
							Ticket Details
						</h3>
						<div class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
							<div class="pt-0 sm:col-span-2">
								<label
									for="subject"
									class="block text-sm font-medium text-mx-green-700 after:content-['*'] after:font-bold after:text-red-500 dark:text-mx-gray-400"
								>
									Subject
								</label>
								<input
									id="subject"
									v-model="formData.title"
									type="text"
									name="subject"
									class="block w-full px-3 py-2 mt-1 border rounded-md shadow-sm border-mx-gray-300 dark:text-mx-gray-400 dark:bg-mx-green-800 dark:border-mx-green-700 sm:text-sm focus:outline-none focus:ring-mx-orange focus:border-mx-orange"
									required
								>
							</div>
							<div class="sm:col-span-2">
								<FormTextArea
									id="content"
									v-model="ticketContent"
									label="Content"
									label-classes="text-sm font-medium text-mx-green-700 dark:text-mx-gray-400"
									markdown
									required
								/>
							</div>
							<div>
								<label
									for="category"
									class="block text-sm font-medium text-mx-green-700 after:content-['*'] after:font-bold after:text-red-500 dark:text-mx-gray-400"
								>
									Category
								</label>
								<select
									id="category"
									v-model="formData.category"
									name="category"
									class="block w-full py-2 pl-3 mt-1 text-base border rounded-md border-mx-gray-300 dark:text-mx-gray-400 dark:bg-mx-green-800 dark:border-mx-green-700 focus:outline-none focus:ring-mx-orange focus:border-mx-orange sm:text-sm"
									required
								>
									<option
										v-for="(option, key) in TICKET_CATEGORY_OPTIONS"
										:key="key"
										:value="option"
									>
										{{ option }}
									</option>
								</select>
							</div>
							<div>
								<label
									for="priority"
									class="block text-sm font-medium after:content-['*'] after:font-bold after:text-red-500 text-mx-green-700 dark:text-mx-gray-400"
								>
									Priority
								</label>
								<select
									id="priority"
									v-model="formData.priority"
									name="priority"
									required
									class="block w-full py-2 pl-3 mt-1 text-base border rounded-md border-mx-gray-300 dark:text-mx-gray-400 dark:bg-mx-green-800 dark:border-mx-green-700 focus:outline-none focus:ring-mx-orange focus:border-mx-orange sm:text-sm"
								>
									<option
										v-for="(option, key) in TICKET_PRIORITY_OPTIONS"
										:key="key"
										:value="option"
									>
										{{ option }}
									</option>
								</select>
							</div>
							<div>
								<label
									for="ticket-tier"
									class="block text-sm font-medium after:content-['*'] after:font-bold after:text-red-500 text-mx-green-700 dark:text-mx-gray-400"
								>
									Tier
								</label>
								<select
									id="ticket-tier"
									v-model="formData.tier"
									class="w-full px-3 py-2 capitalize border rounded-lg border-mx-gray-300 dark:border-mx-green-700 dark:bg-mx-green-800"
								>
									<option
										v-for="(option, key) in TICKET_TIER_OPTIONS"
										:key="key"
										:value="option"
									>
										{{ option }}
									</option>
								</select>
							</div>
							<div>
								<label
									for="dueDate"
									class="block text-sm after:content-['*'] after:font-bold after:text-red-500 font-medium text-mx-green-700 dark:text-mx-gray-400"
								>
									Due Date
								</label>
								<input
									id="dueDate"
									v-model="formData.dueDate"
									required
									type="date"
									name="dueDate"
									class="block w-full px-3 py-2 mt-1 text-base border rounded-md border-mx-gray-300 dark:dark-input-icon dark:text-mx-gray-400 dark:bg-mx-green-800 dark:border-mx-green-700 focus:outline-none focus:ring-mx-orange focus:border-mx-orange sm:text-sm"
								>
							</div>
						</div>
					</section>
				</div>
			</form>
		</template>
	</Modal>
</template>

<script setup lang="ts">
import { TICKET_CATEGORY_OPTIONS, TICKET_PRIORITY_OPTIONS, TICKET_STATUS_OPTIONS, TICKET_TIER_OPTIONS } from '@/constants/ticketing'
import { useTicketing } from '@/composables/useTicketing'
import type { Author } from '@/types/ticketing'
import { usePermissions } from '@/composables/usePermissions'
import { useAuth } from '@/composables/useAuth'
import { formatIsoStringWithTimeZoneOffset } from '@/utils/formatDate'
import { marked } from 'marked'
import type { TicketCreate } from '@/types/ticketing'

const { fetchPermissions, accountServiceRepOptions } = usePermissions()
const { firstName, lastName, contactId } = useAuth()

const props = defineProps({
	openModal: {
		type: Boolean,
		default: true
	},
	accountId: {
		type: String,
		default: ''
	},
	accountName: {
		type: String,
		default: ''
	}
})

const emit = defineEmits([
	'onClose'
])

onMounted(() => {
	fetchPermissions('notes.create')
})
const { openModal, accountId, accountName } = toRefs(props)

const emptyAuthor: Author = {
	firstName: '',
	lastName: '',
	id: null
}

const emptyTicket = {
	author: { ...emptyAuthor },
	priority: TICKET_PRIORITY_OPTIONS.MEDIUM,
	status: TICKET_STATUS_OPTIONS.OPEN,
	category: TICKET_CATEGORY_OPTIONS.GENERAL,
	title: '',
	content: '',
	assignee: { ...emptyAuthor },
	accountId: accountId.value,
	accountName: accountName.value,
	dueDate: '',
	tier: TICKET_TIER_OPTIONS.TIER1
}

const formData = ref({ ...emptyTicket })
const ticketContent = ref('')

const formatTextToHTML = (text: string): string => {
	// Use marked to convert Markdown to HTML
	const formattedText = marked(text)
	return typeof formattedText === 'string' ? formattedText : ''
}

watch(ticketContent, (newVal) => {
	formData.value.content = formatTextToHTML(newVal)
}, { immediate: true })

const confirmDisabled = computed(() => {
	return !formData.value.title || !formData.value.content || !formData.value.dueDate
})

const { createTicket } = useTicketing()

const clearTicket = () => {
	Object.assign(formData.value.assignee, emptyAuthor)
	Object.assign(formData.value.author, emptyAuthor)
	Object.assign(formData.value, emptyTicket)
}

const handleCreateTicket = async () => {
	formData.value.author.firstName = firstName.value
	formData.value.author.lastName = lastName.value
	formData.value.author.id = parseInt(contactId.value)
	formData.value.dueDate = formatIsoStringWithTimeZoneOffset(formData.value?.dueDate?.toString() || '')

	const createTicketPayload: TicketCreate = {
		...formData.value,
		content: ticketContent.value
	}
	await createTicket(createTicketPayload)
	closeModal()
}

const closeModal = () => {
	clearTicket()
	emit('onClose')
}
</script>
