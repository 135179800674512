<template>
	<NavSubNav
		:tabs="tabs"
		:static-page="staticPage"
	>
		<template #Ticketing>
			<div class="flex items-center">
				<ExclamationTriangleIcon
					v-if="hasOpenTickets"
					class="h-5 pr-1 text-mx-orange animate-ping-twice"
				/>
				Ticketing
			</div>
		</template>
	</NavSubNav>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import { useTicketing } from '@/composables/useTicketing'
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'

const route = useRoute()
const { hasOpenTickets, fetchTicketsOnAccount } = useTicketing()

const props = defineProps({
	accountId: {
		type: String,
		default: ''
	},
	staticPage: {
		type: Boolean,
		default: false
	}
})

const { accountId, staticPage } = toRefs(props)

const computedAccountId = computed(() => {
	return accountId.value ? accountId.value : route.params?.accountId?.toString()
})

const tabs = computed(() => {
	return [
		{ id: 'overview', name: 'Overview', href: `/accounts/${computedAccountId.value}`, route: 'internal-accounts-accountId', isDefault: true },
		{ id: 'installation', name: 'Installation', href: `/accounts/${computedAccountId.value}/installation`, route: 'internal-accounts-accountId-installation' },
		{ id: 'billing', name: 'Billing', href: `/accounts/${computedAccountId.value}/billing`, route: 'internal-accounts-accountId-billing' },
		{ id: 'ticketing', name: 'Ticketing', href: `/accounts/${computedAccountId.value}/ticketing`, route: 'internal-accounts-accountId-ticketing' },
		{ id: 'legacy', name: 'Legacy', href: `/accounts/${computedAccountId.value}/legacy`, route: 'internal-accounts-accountId-legacy' }
	]
})

onMounted(() => {
	if (staticPage.value) {
		return
	}
	fetchTicketsOnAccount(computedAccountId.value?.toString())
})
</script>
