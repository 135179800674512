import { default as index32GhfsDyf4Meta } from "/app/pages/account/billing/index.vue?macro=true";
import { default as _91invoiceId_93z5Xzhoyc9XMeta } from "/app/pages/account/billing/invoices/[invoiceId].vue?macro=true";
import { default as indexDRnDokqjvIMeta } from "/app/pages/account/billing/invoices/index.vue?macro=true";
import { default as indexshorHjfXGxMeta } from "/app/pages/account/billing/payment-methods/index.vue?macro=true";
import { default as contact2YVT2rmZVKMeta } from "/app/pages/account/contact.vue?macro=true";
import { default as indexQWKFaMHH2OMeta } from "/app/pages/account/index.vue?macro=true";
import { default as install_45processeoM61tRLGYMeta } from "/app/pages/account/install-process.vue?macro=true";
import { default as install_45statusi4ylx5TrPGMeta } from "/app/pages/account/install-status.vue?macro=true";
import { default as my_45detailszQmZLzXocwMeta } from "/app/pages/account/my-details.vue?macro=true";
import { default as no_45accounts53wvKllb1hMeta } from "/app/pages/account/no-accounts.vue?macro=true";
import { default as preinstall_45reviewy8ntLHbmO4Meta } from "/app/pages/account/preinstall-review.vue?macro=true";
import { default as indexWOd5N1f9lXMeta } from "/app/pages/internal/accounts/[accountId]/billing/index.vue?macro=true";
import { default as payment_45methodsoDJRy0PI5ZMeta } from "/app/pages/internal/accounts/[accountId]/billing/payment-methods.vue?macro=true";
import { default as servicesBuPg3wvqHyMeta } from "/app/pages/internal/accounts/[accountId]/billing/services.vue?macro=true";
import { default as transactionsNjeHNCiwdwMeta } from "/app/pages/internal/accounts/[accountId]/billing/transactions.vue?macro=true";
import { default as indexsZE6xS0Lf3Meta } from "/app/pages/internal/accounts/[accountId]/index.vue?macro=true";
import { default as installationZ8Hs5tjKxVMeta } from "/app/pages/internal/accounts/[accountId]/installation.vue?macro=true";
import { default as _91invoiceId_93aWuh1gy6kDMeta } from "/app/pages/internal/accounts/[accountId]/invoices/[invoiceId].vue?macro=true";
import { default as indexYxUBTWnZgxMeta } from "/app/pages/internal/accounts/[accountId]/invoices/index.vue?macro=true";
import { default as legacysCQhtsbfX6Meta } from "/app/pages/internal/accounts/[accountId]/legacy.vue?macro=true";
import { default as temporary_45passwordO8307sV55TMeta } from "/app/pages/internal/accounts/[accountId]/temporary-password.vue?macro=true";
import { default as _91ticketId_93fjY2Ldn9dlMeta } from "/app/pages/internal/accounts/[accountId]/ticketing/[ticketId].vue?macro=true";
import { default as indexUFzFYOrQ70Meta } from "/app/pages/internal/accounts/[accountId]/ticketing/index.vue?macro=true";
import { default as indexs9xic66l7LMeta } from "/app/pages/internal/accounts/index.vue?macro=true";
import { default as ticket_45inboxvCwnR4JjAwMeta } from "/app/pages/internal/beta/ticket-inbox.vue?macro=true";
import { default as delinquentmcO4X8QrTUMeta } from "/app/pages/internal/delinquent.vue?macro=true";
import { default as alertIBnaYUGzi5Meta } from "/app/pages/internal/settings/alert.vue?macro=true";
import { default as indexVUO9Gi4L4gMeta } from "/app/pages/internal/settings/index.vue?macro=true";
import { default as ticketinghnJaKzmnupMeta } from "/app/pages/internal/ticketing.vue?macro=true";
import { default as indexVj0HzWBy3bMeta } from "/app/pages/internal/zones/[zoneid]/index.vue?macro=true";
import { default as indexjiSweaNEsDMeta } from "/app/pages/internal/zones/index.vue?macro=true";
export default [
  {
    name: "account-billing",
    path: "/account/billing",
    component: () => import("/app/pages/account/billing/index.vue")
  },
  {
    name: "account-billing-invoices-invoiceId",
    path: "/account/billing/invoices/:invoiceId()",
    component: () => import("/app/pages/account/billing/invoices/[invoiceId].vue")
  },
  {
    name: "account-billing-invoices",
    path: "/account/billing/invoices",
    component: () => import("/app/pages/account/billing/invoices/index.vue")
  },
  {
    name: "account-billing-payment-methods",
    path: "/account/billing/payment-methods",
    component: () => import("/app/pages/account/billing/payment-methods/index.vue")
  },
  {
    name: "account-contact",
    path: "/account/contact",
    component: () => import("/app/pages/account/contact.vue")
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/app/pages/account/index.vue")
  },
  {
    name: "account-install-process",
    path: "/account/install-process",
    component: () => import("/app/pages/account/install-process.vue")
  },
  {
    name: "account-install-status",
    path: "/account/install-status",
    component: () => import("/app/pages/account/install-status.vue")
  },
  {
    name: "account-my-details",
    path: "/account/my-details",
    component: () => import("/app/pages/account/my-details.vue")
  },
  {
    name: "account-no-accounts",
    path: "/account/no-accounts",
    component: () => import("/app/pages/account/no-accounts.vue")
  },
  {
    name: "account-preinstall-review",
    path: "/account/preinstall-review",
    component: () => import("/app/pages/account/preinstall-review.vue")
  },
  {
    name: "internal-accounts-accountId-billing",
    path: "/internal/accounts/:accountId()/billing",
    meta: indexWOd5N1f9lXMeta || {},
    component: () => import("/app/pages/internal/accounts/[accountId]/billing/index.vue")
  },
  {
    name: "internal-accounts-accountId-billing-payment-methods",
    path: "/internal/accounts/:accountId()/billing/payment-methods",
    meta: payment_45methodsoDJRy0PI5ZMeta || {},
    component: () => import("/app/pages/internal/accounts/[accountId]/billing/payment-methods.vue")
  },
  {
    name: "internal-accounts-accountId-billing-services",
    path: "/internal/accounts/:accountId()/billing/services",
    meta: servicesBuPg3wvqHyMeta || {},
    component: () => import("/app/pages/internal/accounts/[accountId]/billing/services.vue")
  },
  {
    name: "internal-accounts-accountId-billing-transactions",
    path: "/internal/accounts/:accountId()/billing/transactions",
    meta: transactionsNjeHNCiwdwMeta || {},
    component: () => import("/app/pages/internal/accounts/[accountId]/billing/transactions.vue")
  },
  {
    name: "internal-accounts-accountId",
    path: "/internal/accounts/:accountId()",
    meta: indexsZE6xS0Lf3Meta || {},
    component: () => import("/app/pages/internal/accounts/[accountId]/index.vue")
  },
  {
    name: "internal-accounts-accountId-installation",
    path: "/internal/accounts/:accountId()/installation",
    meta: installationZ8Hs5tjKxVMeta || {},
    component: () => import("/app/pages/internal/accounts/[accountId]/installation.vue")
  },
  {
    name: "internal-accounts-accountId-invoices-invoiceId",
    path: "/internal/accounts/:accountId()/invoices/:invoiceId()",
    meta: _91invoiceId_93aWuh1gy6kDMeta || {},
    component: () => import("/app/pages/internal/accounts/[accountId]/invoices/[invoiceId].vue")
  },
  {
    name: "internal-accounts-accountId-invoices",
    path: "/internal/accounts/:accountId()/invoices",
    meta: indexYxUBTWnZgxMeta || {},
    component: () => import("/app/pages/internal/accounts/[accountId]/invoices/index.vue")
  },
  {
    name: "internal-accounts-accountId-legacy",
    path: "/internal/accounts/:accountId()/legacy",
    meta: legacysCQhtsbfX6Meta || {},
    component: () => import("/app/pages/internal/accounts/[accountId]/legacy.vue")
  },
  {
    name: "internal-accounts-accountId-temporary-password",
    path: "/internal/accounts/:accountId()/temporary-password",
    meta: temporary_45passwordO8307sV55TMeta || {},
    component: () => import("/app/pages/internal/accounts/[accountId]/temporary-password.vue")
  },
  {
    name: "internal-accounts-accountId-ticketing-ticketId",
    path: "/internal/accounts/:accountId()/ticketing/:ticketId()",
    meta: _91ticketId_93fjY2Ldn9dlMeta || {},
    component: () => import("/app/pages/internal/accounts/[accountId]/ticketing/[ticketId].vue")
  },
  {
    name: "internal-accounts-accountId-ticketing",
    path: "/internal/accounts/:accountId()/ticketing",
    meta: indexUFzFYOrQ70Meta || {},
    component: () => import("/app/pages/internal/accounts/[accountId]/ticketing/index.vue")
  },
  {
    name: "internal-accounts",
    path: "/internal/accounts",
    component: () => import("/app/pages/internal/accounts/index.vue")
  },
  {
    name: "internal-beta-ticket-inbox",
    path: "/internal/beta/ticket-inbox",
    component: () => import("/app/pages/internal/beta/ticket-inbox.vue")
  },
  {
    name: "internal-delinquent",
    path: "/internal/delinquent",
    component: () => import("/app/pages/internal/delinquent.vue")
  },
  {
    name: "internal-settings-alert",
    path: "/internal/settings/alert",
    meta: alertIBnaYUGzi5Meta || {},
    component: () => import("/app/pages/internal/settings/alert.vue")
  },
  {
    name: "internal-settings",
    path: "/internal/settings",
    meta: indexVUO9Gi4L4gMeta || {},
    component: () => import("/app/pages/internal/settings/index.vue")
  },
  {
    name: "internal-ticketing",
    path: "/internal/ticketing",
    meta: ticketinghnJaKzmnupMeta || {},
    component: () => import("/app/pages/internal/ticketing.vue")
  },
  {
    name: "internal-zones-zoneid",
    path: "/internal/zones/:zoneid()",
    component: () => import("/app/pages/internal/zones/[zoneid]/index.vue")
  },
  {
    name: "internal-zones",
    path: "/internal/zones",
    component: () => import("/app/pages/internal/zones/index.vue")
  }
]