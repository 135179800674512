<template>
	<div class="gap-6 border-b-2 py-6 lg:pt-[54px] border-mx-gray-300 dark:border-mx-green-700">
		<div class="flex items-center gap-4 divide-x-2 divide-mx-gray-400 dark:divide-mx-green-700">
			<h2 class="text-3xl font-bold text-mx-gray-800 dark:text-mx-gray-200 lg:whitespace-nowrap">
				<slot name="title">
					Step Header
				</slot>
			</h2>
			<h3 class="pl-4 text-lg font-semibold text-mx-gray-500 dark:text-mx-gray-400">
				<slot name="description">
					Step Description
				</slot>
			</h3>
		</div>
		<div
			v-if="$slots.content"
			class="pt-6"
		>
			<slot name="content" />
		</div>
	</div>
</template>
