import { isAccountPortal } from '@/composables/useIsAccountPortal'
import { useAuth } from '@/composables/useAuth'
import { BASE_URL, SHARED_COOKIE_DOMAIN, IN_CONVERSION_ROUTES } from '@/constants/index'
import { useErrorReporter } from '@/composables/useErrorReporter'

const { bugsnagReport } = useErrorReporter()

export default defineNuxtRouteMiddleware(async (to) => {
	if (process.server) { return }

	const { getAuth, role, showBilling, customerHasNoAccounts, email, fullAddress } = useAuth()
	await getAuth()
	// TODO: accountStatus stuff should be getting account at index 0 but kinda waiting on BE to clean up data

	// Bypass middleware for /no-accounts to prevent recursion
	if (to.path === '/no-accounts') {
		// If the user does have accounts, redirect to "/"
		if (!customerHasNoAccounts.value) {
			return navigateTo('/')
		}
		return
	}

	// Redirect to /no-accounts if the user has no accounts, and whitelist the /contact route
	if (isAccountPortal.value && customerHasNoAccounts.value && to.path !== '/contact') {
		bugsnagReport({
			error: new Error(`User has no accounts associated with ${email.value} - ${fullAddress.value}`),
			showToast: false
		})

		return navigateTo('/no-accounts')
	}

	if (!(role.value === 'internal' || (isAccountPortal.value && role.value === 'external'))) {
		bugsnagReport({
			error: new Error('Middleware Auth Rejected')
		})
		const redirectedFrom = useCookie('redirected-from', {
			domain: SHARED_COOKIE_DOMAIN
		})
		redirectedFrom.value = to.fullPath

		return navigateTo(`${BASE_URL}/login`, { external: true })
	}

	// if internal employee we do not need to check the cookie to route install status stuff
	if (role.value === 'internal') { return }

	// Restrict non-active users to specific routes
	if (!IN_CONVERSION_ROUTES.includes(to.fullPath) && !showBilling.value) {
		return navigateTo('/install-status')
	}
})
