import { API_BASE, API_ENDPOINT_MAINLINE_MAP } from '@/constants/index.js'
import { useErrorReporter } from '@/composables/useErrorReporter'
import type { GeoJsonProperties, FeatureCollection, Geometry } from 'geojson'
import { formatDate, MDY2T } from '@/utils/formatDate'

const mainlineGeoJson = ref<FeatureCollection<Geometry, GeoJsonProperties>>()
const loading = ref(false)
const lastUpdated = ref('')

export const useMainlineMap = () => {
	const { bugsnagReport } = useErrorReporter()
	const getMainlines = async () => {
		if (loading.value || mainlineGeoJson.value?.features?.length) {
			return
		}

		loading.value = true
		await $fetch(`${API_BASE}${API_ENDPOINT_MAINLINE_MAP}`, {
			method: 'GET',
			credentials: 'include',
			onResponse ({ response }) {
				if (!response?.ok) {
					throw new Error(response._data?.errors?.[0]?.title)
				}
				mainlineGeoJson.value = response._data
				const lastModifiedReturn = response.headers.entries().find(([ key ]) => key === 'last-modified')?.[1]
				lastUpdated.value = formatDate(lastModifiedReturn, MDY2T) || 'Not Found'
			}
		}).catch((e) => {
			bugsnagReport({
				error: e instanceof Error ? e : new Error('Error fetching mainline data; ' + (e as Error).toString()),
				showToast: true,
				toast: {
					title: 'Error fetching mainline data',
					message: e?.message
				}
			})
		}).finally(() => {
			loading.value = false
		})
	}

	return {
		getMainlines,
		mainlineGeoJson,
		loading,
		lastUpdated
	}
}
