import type { SortOrder, SortMap } from '@/utils/sortMethods/types'

const sortNumbers = (a: number, b: number, sortOrder: SortOrder) => {
	if (a < b) {
		return sortOrder === 'ASC' ? -1 : 1
	}
	if (a > b) {
		return sortOrder === 'ASC' ? 1 : -1
	}
	return 0
}

const sortStrings = (a: string, b: string, sortOrder: SortOrder) => {
	if (a.toLowerCase() < b.toLowerCase()) {
		return sortOrder === 'ASC' ? -1 : 1
	}
	if (a.toLowerCase() > b.toLowerCase()) {
		return sortOrder === 'ASC' ? 1 : -1
	}
	return 0
}

const sortDateObjects = (a: Date, b: Date, sortOrder: SortOrder) => {
	if (a < b) {
		return sortOrder === 'ASC' ? -1 : 1
	}
	if (a > b) {
		return sortOrder === 'ASC' ? 1 : -1
	}
	return 0
}

const sortDates = (a: string, b: string, sortOrder: SortOrder) => {
	const dateA = new Date(a).getTime() || 0
	const dateB = new Date(b).getTime() || 0
	if (dateA < dateB) {
		return sortOrder === 'ASC' ? -1 : 1
	}
	if (dateA > dateB) {
		return sortOrder === 'ASC' ? 1 : -1
	}
	return 0
}
const sortMapped = (a: string, b: string, sortOrder: SortOrder, map: SortMap) => {
	const valA = map[a.toLowerCase() as keyof typeof map] || 0
	const valB = map[b.toLowerCase() as keyof typeof map] || 0
	if (valA < valB) {
		return sortOrder === 'ASC' ? -1 : 1
	}
	if (valA > valB) {
		return sortOrder === 'ASC' ? 1 : -1
	}
	return 0
}

const sortAssigneeNames = (users: { id: string, name: string }[]): { id: string, name: string }[] => {
	return users.sort((a, b) => {
		// By passing undefined as the second argument to localeCompare, the comparison uses the default locale of the runtime environment.
		// sensitivity: 'base' ensures a case-insensitive and diacritic-insensitive comparison.
		return a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
	})
}

export { sortNumbers, sortStrings, sortDates, sortMapped, sortDateObjects, sortAssigneeNames }
